@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap);
.ui.placeholder.placeholderCover{
    max-width:100%;
    height:100%;
}

.placeholderImg{
    width:100%;
    height:100%; float:left;
    border-radius:100px;
}

.placeholderCircular,
.placeholderBox{
    width:100%;
    height:100%;
    float:left;
}
.placeholderBox.noSpace .placeholderCover .image:before{
    display:none!important;
}

.placeholderCircular .ui.placeholderCover{
    border-radius:500px;
}
.ui.placeholderCover{
    position:relative;
}
.ui.placeholderCover .children{
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:50%;
    transform:translate(0, -42%);
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    font:400 15px/24px 'Rubik',sans-serif;
    color:#333;
    text-shadow:1px 1px 1px rgba(255,255,255,.8);
}



/** remove below **/
.devideMargin{
    width:100%; float:left;
    margin:0 0 20px;
}
.defaultBox.hasMessage{
    display:flex;
    flex-direction:column;
}
.defaultBox.hasMessage .bodyDefaultBox{
    min-height:300px;
    flex:1 1;
    display:flex;
    align-items:center;
    justify-content:center;
}
.widgetMessage{
    display:inline-flex;
    align-items:center;
    padding:20px 0;
}
.widgetMessage .ui.image img{
    width:100%;
}
.widgetMessage:not(.hasError, .verticalMessage) .ui.image{
    width:102px;
}
.widgetMessage:not(.verticalMessage) .ui.image{
    margin:0 25px 0 0;
}
.widgetMessage p{
    flex:1 1;
    font:400 13px 'Rubik',sans-serif;
    color:#222;
}
.widgetMessage p strong{
    display:block;
    font:500 15px 'Rubik',sans-serif;
    margin:0 0 8px;
}

.widgetMessage.verticalMessage{
    flex-direction:column;
    justify-content:center;
}
.widgetMessage.verticalMessage p{
    text-align:center;
}

.widgetMessage.verticalMessage.hasError  .ui.image{
    width:140px;
    margin:0 0 20px;
}
.widgetMessage.verticalMessage:not(.hasError)  .ui.image img{
    margin-left:-20px;
    margin-right:-20px;
}

.widgetMessage.isFloat{
    padding:20px;
}
.widgetMessage.isFloat .ui.image{
    width:auto;
    background:#fff;
}

.pathAnalyticsSeperator .widgetMessage:not(.hasError, .verticalMessage) .ui.image{
    width:auto;
}
.pathAnalyticsSeperator .widgetMessage:not(.hasError, .verticalMessage) p{
    color:#667581;
}
.pathAnalyticsSeperator .widgetMessage:not(.hasError, .verticalMessage) p strong{
    color:#000;
}

.errorWidgetWrap{
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

.OccupancyChartBoxContainer .errorWidgetWrap .widgetMessage.hasError{
    transform:scale(.6);
    padding:0;
}
/* ToggleButton.css
.toggle-button {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button.active {
    background-color: #007bff; /* Blueish active color */
   
  
  /* input[type="radio"] {
    display: none;
  }
  
  .slider {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  input[type="radio"]:checked + .slider {
    transform: translateX(30px);
  }
  */ 
  .toggle-button {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
    background-color: #ccc;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 6px;
    transition: background-color 0.3s ease;
  }
  .toggle-button::before{
    position: absolute;
    height: 15px;
    height: 15px;
    content:"°C";
  font-size: 14px;
    left: 3px;
    bottom: 2px;
    color: white;
    
  }
  .toggle-button::after{
    position: absolute;
    height: 15px;
    height: 15px;
    content:"°F";
  font-size: 14px;
    right: 4px;
    bottom: 2px;
    
  }
  
  .toggle-button.active {
    background-color: #727AFF;
    
  }
  
  input[type="radio"] {
    display: none;
  }
  
  .slider {
    position: absolute;
   top:1px;
    left: 3px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
    z-index: 100;
  }
  
  input[type="radio"]:checked + .slider {
    transform: translateX(19px);
  }
  .toogleContentBox{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 420px;
  }
  .toogleContentBox span{
    font-size: 16px;
  }
.ui.basic.buttonIcon:not(.circular) {
    width: auto;
    border-radius: 0;
    padding: 2px;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
    color: #727AFF !important;
    font: 400 15px 'Rubik', sans-serif;
    display: inline-flex;
    align-items: center;
}

.ui.basic.buttonIcon:not(.circular):hover,
.ui.basic.buttonIcon:not(.circular):focus {
    background: none !important;
    color: #727AFF !important;
}

.ui.basic.buttonIcon img {
    margin-right: 5px;
}

.ui.basic.buttonIcon.circular {
    height: 26px;
    padding: 0 10px 0 5px;
    box-shadow: none;
    border: solid 1px #BFBDD3;
    display: flex;
    align-items: center;
    font: 500 13px 'Rubik', sans-serif;
    color: #727AFF !important;
}

.ui.basic.buttonIcon.circular img {
    margin: 0 8px 0 0;
}

.ui.basic.buttonIcon.circular.small {
    font: 500 13px 'Rubik', sans-serif;
}

.ui.basic.buttonIcon:hover {
    text-decoration: underline;
    background: none !important;
    box-shadow: none;
}
.deprecatingIcon {
    height: 15px;
    width: 15px;
    color: white;
    background: rgb(253, 120, 87);
    position: absolute;
    right: -5px;
    top: -5px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 15px; /* Ensure vertical centering */
    text-indent: 0;
}
/*** datepicker ***/

.fixedPosition {
    position: fixed !important;
}

.dateRangeCover {
    width: 206px;
    display: inline-block;
    position: -webkit-sticky;
    position: sticky;
}

.dropdownHeatMap {
    margin: 0 0 0 15px !important;
}

.dropdownHeatMap .ui.dropdown {
    margin-right: 0 !important;
}


.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle {
    width: 100%;
    height: 30px;
    padding: 0 7px;
    background: #fff !important;
    border: solid 1px #D3D2DE !important;
    border-radius: 3px !important;
    font: 400 13px 'Rubik', sans-serif !important;
    color: #000 !important;
    transition: all 0.3s ease;
    white-space:nowrap;
}

.dateRangeCover .ui.buttonCalendarToggle img {
    order: 2;
    margin-left: auto;
    margin-right: 0;
    transition: all 0.3s ease;
}

.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle:hover {
    text-decoration: none !important;
    cursor: pointer;
}

.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle.inShow {
    border: solid 1px #727AFF !important;
}

.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle.inShow img {
    filter: saturate(8) brightness(0.8) contrast(0.9);
}

.dateCalendarHide {
    display: none !important;
}

.dateRangeCover>div {
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 100;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
}

.dateCalendarShow {
    display: block !important;
}

.popup {
    position: fixed !important;
}

.ui.dateRangePopup {
    width: 207px;
    box-shadow: none;
    border: solid 1px #D3D2DE;
    border-radius: 3px;
    padding: 0 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 10 !important;
}

.ui.dateRangePopup:before {
    display: none !important;
}

.ui.dateRangePopup .ui.button {
    width: 100%;
    float: left;
    background: none;
    border-radius: 0;
    font: 400 15px 'Rubik', sans-serif !important;
    padding: 9px 10px 10px 13px !important;
    display: flex;
    align-items: center;
    position: relative;
    color: #000;
    overflow: hidden;
}

.ui.dateRangePopup .ui.button:before {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: solid 1px #D3D2DE;
    position: absolute;
    bottom: 0;
    right: -13px;
}

.ui.dateRangePopup .ui.button:last-child:before {
    display: none;
}

.ui.dateRangePopup .ui.button:last-child {
    border: none;
}

.ui.dateRangePopup>div>div .ui.button:last-child {
    white-space: nowrap;
    padding-right: 20px !important;
}

.ui.dateRangePopup .ui.button:last-child:after {
    content: '';
    background: url(/img/icon-chevron-down.svg) no-repeat right;
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
    filter: saturate(.2) brightness(1.3);
    position: absolute;
    right: 5px;
}

.ui.dateRangePopup .ui.button:hover {
    background: #f0f1fe;
}

.buttonsRange{
    display: inline-flex;
}
.buttonsRange .ui.button{
    height:30px;
    margin:0;
    padding:5px;
    border-radius:0;
    background:#fff!important;
    border:solid 1px #D3D2DE;
    border-radius:0;
    border-right:none;
    display: inline-flex;
    align-items: center;
    font:400 13px 'Rubik',sans-serif;
    color:#969DB8;
    transition:all .2s ease;
}
.buttonsRange .ui.button:hover{
}
.buttonsRange .ui.button:first-child{
    border-radius:2px 0 0 2px;
}
.buttonsRange .ui.button:last-child{
    margin-right:-1px;
    border:solid 1px #D3D2DE;
    border-radius:0 2px 2px 0;
}
.buttonsRange .ui.button img{
    height:15px;
    margin:0 6px 0 0;
    transition:all .2s ease;
}
.buttonsRange .ui.button.active,
.buttonsRange .ui.button.active:hover{
    background:#727AFF!important;
    border-color:#727AFF;
    color:#fff;
}
.buttonsRange .ui.button.active img{
    filter:saturate(0) brightness(10);
}

.dateRangePopup-container{
    display: flex;
}

.dateRangePopup-container >div> .buttonsRange .ui.button:last-child{
    border-right:none;
    border-radius:0
}



#react-time-range {
  padding: 5px;
}

#react-time-range .component {
  display: inline;
  overflow: auto;
  padding: 5px;
}

#react-time-range select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 6px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

#react-time-range .component .label {
  padding-right: 10px;
}

#react-time-range .error {
  color: red;
  padding: 5px;
}

.overviewDetail {
    width: 100%;
    float: left;
    margin: 25px 0 22px;
    display: flex;
    justify-content: space-between;
}

.overviewDetailBox {
    flex: 1 1;
    max-width: 300px;
    background: #fff;
    border-radius: 2px;
    position: relative;
    padding: 20px 10px 25px 25px;
    display: flex;
    align-items: flex-start;
    box-shadow:0 0 15px rgba(0,0,0,.1);
    position:relative;
}
.overviewDetailBox .triggerAppTooltip{
    width:10px!important;
    top:5px;
    right:30px;
}

.overviewDetailBox.disabled {
    opacity: .7;
    filter: saturate(0);
}

.overviewDetailBox .ui.infoButton {
    position: absolute!important;
    top: 9px;
    right: 9px;
    cursor: pointer;
}

.overviewDetailBox>img.ui.image {
    margin-right: 15px;
    margin-top: 5px;
}

.contentDetailBox {
    flex: 1 1;
}

.contentDetailBox b {
    font: 500 30px 'Rubik', sans-serif;
    color: #000;
    margin-right: 10px;
}

.contentDetailBox b span {
    font: 400 12px 'Rubik', sans-serif;
    color: #969DB8;
}

.contentDetailBox p {
    font: 400 15px 'Rubik', sans-serif;
    color: #969DB8;
}

.contentDetailBox p {
    width: 100%;
    display: inline-block;
}

.contentDetailBox b span {
    margin-left: 4px;
}

.percentageDetailBox {
    align-self: center;
    font: 400 15px 'Rubik', sans-serif;
    margin: 0 5px 0 0;
}

.percentageDetailBox .icon {
    font-size: 12px;
    position: relative;
    margin: 0 0 0 3px;
}

.percentageDetailBox.up, .percentageDetailBox.up .icon {
    color: #29AD73;
}

.percentageDetailBox.down, .percentageDetailBox.down .icon {
    color: #F15944;
}

.ui.popup.infoPopupOverview {
    left: auto;
    right: 0!important;
}

.ui.infoPopupOverview:before {
    display: none!important;
}
.ui.popup.infoPopupOverview.appOnlyTooltip{
    width:170px;
}

/*** web engagement ***/

.engagementWidgetRow .overviewDetailBox{
    /* max-width:240px; */
    padding:20px;
}
.engagementWidgetRow .overviewDetailBox .percentageDetailBox{
    margin:0;
    position: absolute;
    top: 30px;
    right: 20px;
}
.engagementWidgetRow .overviewDetailBox .contentDetailBox b{
    margin:0;
}
.engagementWidgetRow .overviewDetailBox .contentDetailBox b span{margin:0;}















.mapContainer {
    width: 100% !important;
    height: 100%;
}

.mapContainer .ol-viewport {
    z-index: 1;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: -40px;
    right: -130px;
    min-width: 264px;
    font-size: small;
    z-index: 100;
}

.ol-dwell-point-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: -40px;
    right: -130px;
    white-space: nowrap;
    width: auto;
    font-size: small;
}

.ol-point-popup{
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: -40px;
    right: -130px;
    white-space: nowrap;
    width: auto;
    font-size: small;
}

.heatMapInfo {
    height: calc(100vh - 340px);
    display: inline-flex;
    position: absolute;
    right: 80px;
    top: 130px;
    z-index: 10;
}

.hmileft {
    width: 16px;
    float: left;
}

.hmi {
    flex: 1 1;
    width: 100%;
    height: 20%;
    float: left;
    display: inline-flex;
    flex-direction: column;
    margin: 0 0 1px;
}

.hmi:last-child {
    margin: 0;
}

.hmi span {
    width: 100%;
    height: 100%;
}

.hmiright {
    padding: 0 0 0 5px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.hmiright span {
    font: 500 12px 'Rubik', sans-serif;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "✖";
}




.ui.button.buttonIcon.triggerTagInfo,
.ui.button.buttonIcon.triggerTagInfo:focus {
    background: #fff !important;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2) !important;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.button.buttonIcon.triggerTagInfo:hover {
    background: #727AFF !important;
}

.ui.button.buttonIcon.triggerTagInfo:hover img {
    filter: saturate(0) brightness(100);
}

.ui.popup.popupTagInfoLegend {
    top: auto !important;
    left: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 126px;
    overflow-x: scroll;
    border: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    padding: 10px 0 10px 10px;
}

.ui.popup.popupTagInfoLegend .ui.header {
    font: 600 15px 'Rubik', sans-serif;
    color: #333;
    border-bottom: solid 1px #D3D2DE;
    padding: 0 0 10px;
}

.ui.popup.popupTagInfoLegend p {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ui.popup.popupTagInfoLegend p span {
    width: 14px;
    min-width: 14px;
    height: 14px;
    border-radius: 100px;
    background: #4F5051;
    margin: 0 10px 0 0;
}
.ui.checkboxGroup {
    width: 100%;
    float: left;
    min-height: 18px;
    margin: 0 0 10px;
}

.ui.checkboxGroup label {
    font: 400 13px/19px 'Rubik', sans-serif !important;
    color: #000;
    padding-left: 27px !important;
}

.ui.checkboxGroup:not(.radio) label {
    line-height: 18px !important;
}

.ui.checkbox.checkboxGroup label:before {
    width: 18px;
    height: 18px;
}

.ui.checkbox.checkboxGroup input:focus {
    border: solid 1px #000 !important;
}

.ui.checkbox.checkboxGroup:not(.radio) input:checked~label:after {
    content: '';
    width: 18px;
    height: 18px;
    background: url(/img/icon-checkbox.svg) no-repeat center;
    background-size: 10px auto;
}

.ui.checkbox.checkboxGroup:not(.radio) input:checked~label:before {
    background: #727aff;
    border-color: #727aff;
}

.ui.radio.checkboxGroup label:before,
.ui.radio.checkboxGroup label:after {
    top: 0 !important;
    transition: all 0s ease;
}

.ui.radio.checkboxGroup label:before {
    width: 18px !important;
    height: 18px !important;
    border-radius: 20px !important;
}

.ui.checkbox.checkboxGroup.radio input:checked~label:after {
    width: 18px;
    height: 18px;
}

.ui.checkbox.checkboxGroup.radio input:checked~label:after,
.ui.checkbox.checkboxGroup.radio input:checked~label:focus {
    background: #6f7cff !important;
}

.ui.checkbox.checkboxGroup.radio input:checked~label:before {
    border: solid 1px #6f7cff;
}
.error-box{
    width:100%; float:left;
    min-height:54px;
    padding:8px;
    border:solid 1px #e62600;
    border-left-width:5px;
    background:#fcf4f2;
    border-radius:2px;
    font:400 12px 'Rubik',sans-serif;
    color:#a31b00;
}

.error-box .header {
    display: block;
    font-weight: 500;
    font-size:14px;
    margin:0 0 5px;
}
.loading-container{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.2);
    /* background:url(/img/m-colored.gif) no-repeat center; */
    z-index:9000;
}

.loading-container img{
    width:100px;
    height:100px;
    position:fixed;
    top:50%;
    left:50%;
    margin-top:-50px;
    margin-left:-50px;
}

.loading-screen,
.loading-comp,
.loading-footer{
    display:flex;
    align-items:center;
    justify-content:center;
}

.loading-screen{
    width:100%;
    height:100%;
    background:#faf6f7;
    position:fixed;
    top:0;
    left:0;
    flex-direction:column;
    z-index:9000;;
}

.loading-comp{
    flex:1 1;
    flex-direction:column;
}

.loading-comp span{
    display:block;
    margin:20px 0 0;
    font:400 italic 15px 'Rubik', sans-serif;
    letter-spacing:1.5px;
}

.loading-footer{
    margin:20px 0 30px;
    font:400 italic 12px 'Rubik', sans-serif;
    letter-spacing:1px;
}

.loading-footer img{
    margin:-5px 0 0 10px;
}


.mapsted-splash
{
    top:0;
    left:0;

    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.842);

    background-image: url(https://studio.mapsted.com/Content/images/loadbtn.gif);
    background-repeat: no-repeat ;
    background-size: 100% 3px;
    background-position: top;
}

.mapsted-splash > img
{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.mapsted-splash-small
{
    animation-name: fadeOut;
    animation-duration: 5s;
    animation-fill-mode: both;

    text-align: center;
    background: url(https://studio.mapsted.com/Content/images/loadbtn.gif) no-repeat ;
    background-size: 100% 3px;
    background-position: bottom;

}

 @keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0.2;}
 }


.ui.dropdown.languageDropdown {
    min-width: 0 !important;
    width: 51px;
    height: 32px !important;
    border-radius: 4px !important;
    background: #fff !important;
    border: none !important;
    padding: 0 0 0 9px !important;
    font: 500 14px/31px 'Rubik', sans-serif !important;
    color: #727AFF !important;
    text-align: center !important;
}

div.ui.dropdown.languageDropdown.button {
    position: relative;
    z-index: 10;
}

div.ui.dropdown.languageDropdown.button:active,
div.ui.dropdown.languageDropdown.button {
    box-shadow: none !important;
}

.ui.menu.header-bar .right.menu .ui.button.languageDropdown:hover {
    background: #fff !important;
}

.ui.languageDropdown i.icon {
    position: relative;
    margin: 0 !important;
    top: 1px;
    height: auto !important;
}

.ui.languageDropdown .menu {
    z-index: 8;
    width: 280px !important;
    left: auto !important;
    right: -34px !important;
    border-radius: 4px;
    border: solid 1px #D3D2DE;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2) !important;
    margin-top: 13px !important;
}

.ui.languageDropdown .menu:after {
    left: auto !important;
    right: 50px !important;
    width: 12px !important;
    height: 12px !important;
    top: -6px !important;
    box-shadow: -1px -1px 0 0 rgba(34, 36, 38, .15) !important;
}

.ui.menu.header-bar .ui.languageDropdown .menu>.item,
.ui.menu .ui.languageDropdown .menu>.item {
    padding: 12px 19px !important;
    margin: 0 !important;
    border-radius: 0;
    display: flex;
    align-items: center;
    font: 400 14px 'Rubik', sans-serif !important;
    color: #667581;
}

.ui.languageDropdown .menu>.item .text {
    padding: 0 !important;
    color: #667581 !important;
    font: 400 14px 'Rubik', sans-serif !important;
    position: relative !important;
    background: none !important;
}

.ui.menu .ui.languageDropdown .menu>.item:hover .text,
.ui.menu .ui.languageDropdown .menu>.item.selected .text {
    color: #000 !important;
}

.ui.menu .ui.languageDropdown .menu>.item:last-child:hover {
    border-radius: 0 0 4px 4px;
}

.ui.menu .ui.languageDropdown .menu>.item.active.selected {
    /* background: url(/img/icon-verify-green.svg) no-repeat 95% 15px!important; */
}

.ui.languageDropdown .menu>.item img {
    margin-right: 11px !important;
}

.ui.languageDropdown .menu>.item>.description {
    font-size: 13px !important;
}

.ui.languageDropdown .menu>.item>.ui.button {
    background: none;
    border: none;
    box-shadow: none;
    min-height: 0;
    min-width: 0;
    height: auto;
    padding: 0;
    margin: 0;
    font: 400 13px 'Rubik', sans-serif !important;
    color: #727AFF !important;
}

.ui.languageDropdown .menu>.item .icon,
.ui.menu.header-bar .ui.languageDropdown .menu>.item .icon:not(.dropdown) {
    color: #17C0AC;
    font-size: 12px !important;
    margin: 0 0 0 3px !important;
}

.ui.languageDropdown .menu>.header {
    font: 600 14px 'Rubik', sans-serif !important;
    margin: 20px 0 10px !important;
    padding: 0 0 0 19px !important;
}
.ui.menu.header-bar {
    height: 62px;
    background: #fff !important;
    box-shadow: 0 2px 5px #ced3e2 !important;
    margin: 0;
    z-index: 20;
    position: relative;
}

.ui.menu.header-bar .container>a:first-child {
    padding: 0;
    margin-left: 20px !important;
    margin-top: -14px !important;
}

.ui.menu.header-bar .item:first-child img {
    width: 155px;
}

.ui.menu.hb-campaign .ui.fluid.container {
    justify-content: center;
}

.ui.menu.header-bar .item,
.ui.menu.ui.menu.hb-campaign .item:first-child {
    margin: 0 15px !important;
}

.ui.menu.header-bar .item {
    background: none !important;
    padding: 12px 5px 7px !important;
    position: relative;
}

.ui.menu.header-bar .item a {
    font: 500 14px/9px 'Rubik', sans-serif;
    text-transform: uppercase;
    color: #000;
    float: left;
}

.ui.menu.header-bar .active.item a,
.ui.menu.header-bar .item a:hover {
    color: #f15944;
}

.ui.menu.header-bar .item span {
    position: absolute;
    right: -33px;
    top: 3px;
    background: #29ad73;
    color: #fff;
    padding: 3px 5px;
    border-radius: 2px;
    font: 500 11px 'Rubik', sans-serif;
}

.ui.menu.header-bar .item span.grey {
    background: grey !important;
}

.ui.menu.header-bar .ui.container>.item span {
    right: auto;
    left: 100%;
    white-space: nowrap;
}

.ui.menu.header-bar .right.menu {
    align-items: center;
}

.ui.menu.header-bar .right.menu .item {
    padding: 5px;
    background: none !important;
    margin: 0 10px !important;
}

.ui.menu.header-bar .right.menu .item span:not(.ui) {
    min-width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #F15944;
    font: 500 12px/16px 'Rubik', sans-serif;
    text-align: center;
    color: #fff;
    padding: 0 3px;
    position: absolute;
    top: 2px;
    right: 3px;
}

.ui.menu.header-bar .right.menu .item img {
    width: auto;
}

.ui.menu.header-bar .right.menu .item:nth-last-child(2) img {
    width: 20px;
}

.ui.menu.header-bar .right.menu .item>i.icon {
    font-size: 20px;
    color: #727aff;
    margin: 0;
}

.ui.menu.header-bar .right.menu a:hover {
    opacity: 0.8;
}

@media (max-width:768px) {

    .ui.menu.header-bar .item,
    .ui.menu.ui.menu.hb-campaign .item:first-child {
        margin: 0 !important;
    }

    .ui.menu.header-bar .right.menu .item {
        margin: 0 5px !important;
    }
}
.ui.dropdownGroup {
    width: 100%;
    float: left;
    min-height: 0;
    height: 25px;
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: solid 1px #D3D2DE !important;
    font: 400 13px 'Rubik', sans-serif !important;
    color: #000;
    background: none !important;
}

.ui.dropdownGroup>.text {
    color: #000;
}

.ui.dropdownGroup>.icon {
    float: right !important;
    color: #939CA3 !important;
    width: auto;
    margin: 5px 5px 0 0 !important;
}

.ui.dropdownGroup>.menu {
    width: 100%;
    border-radius: 0 0 4px 4px;
}

.ui.dropdownGroup .menu .active.item {
    font-weight: 500;
}
.insightsBody {
    width: 100%;
    float: left;
    height: calc(100dvh - 63px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.insightsZoneMapContainer {
    width: 100%;
    float: left;
    height: 100%;
}

.insightsLegendContainer {
    position: absolute;
    bottom: 50px;
    right: 15px;
    z-index: 10;
}

.insightsSessionUIDContainer {
    position: absolute;
    bottom: 0;
    right: 15px;
    z-index: 10;
    font: 400 13px 'Rubik', sans-serif;
}

.insightsLegend {
    width: 135px !important;
    position: relative;
    padding-top: 15px !important;
}

.insightsZoneBoxContainer {
    width: 100%;
    flex: 1 1;
    float: left;
    overflow: hidden;
    position: relative;
}

.insightsZoneBoxContainer .mapContainer .toggleSliderBox {
    top: 70px;
    right: 20px;
}

.insightsZoneBoxContainer .ol-control .map-attribution {
    margin: 0 0 -2px 10px;
    position: relative;
}

.insightsZoneBoxContainer .ol-control .map-attribution p {
    margin: 0;
}

.insightsChartWrap {
    width: 100%;
    height: 200px;
    float: left;
    display: flex;
    border-top: solid 1px #e1e1e1;
    background: #fff;
    padding: 10px 20px;
}

.insightsContainerWrap {
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    border-top: solid 1px #e1e1e1;
    background: #fff;
    padding: 10px 20px;
}

.insightsChartWrap .insightsLoadingMessage {
    width: 100%;
    max-width: 410px;
}

.insightsChartbox {
    width: 100%;
    float: left;
    border: solid 1px #e1e1e1;
    border-radius: 10px;
    display: flex;
    position: relative;
}

.insightsLegend .ui.image.infoButton {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
}

.insightsChartPopup {
    width: 300px;
    min-width: 300px;
    float: left;
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.insightsChartItem {
    width: 50%;
    float: left;
    padding: 10px;
    font: 400 13px 'Rubik', sans-serif;
    color: #555;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}


.insightsChartItem .ui.image.infoButton {
    margin: 0 0 0 5px !important;
}

.insightsChartItem strong {
    min-width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin: 5px 0 0;
}

.insightsChartItem span {
    font-weight: 500;
    font-size: 12px;
    color: #000;
}

.insightsChartWrapper {
    position: relative;
    height: 180px;
    width: calc(100% - 300px);
    padding: 10px;
    margin-left: 20px;
    font: 400 12px 'Rubik', sans-serif;
}

.insightsChartWrapper strong {
    font-size: 500;
}

.userSessionDropdown {
    max-width: 250px;
    float: left;
    margin: 0 0 0 10px;
    display: inline-flex;
    align-items: center;
    font: 500 13px 'Rubik', sans-serif;
}

.userSessionDropdown>p {
    font: 500 13px 'Rubik', sans-serif;
    padding-right: 10px;
    margin: 0;
}

.userSessionDropdown .ui.dropdown.dropdownGroup {
    min-width: 150px;
    max-width: 250px !important;
    height: 25px;
    display: inline-flex !important;
    align-items: center;
    padding: 0 20px 0 0 !important;
    background: #fff !important;
    position: relative;
    z-index: 1;
}

.userSessionDropdown .ui.dropdown.dropdownGroup>.text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userSessionDropdown .ui.dropdown.dropdownGroup>.icon {
    position: absolute;
    margin: 0;
    right: 0;

}

.userSessionDropdown .ui.dropdown.dropdownGroup .menu>.item {
    width: 100% !important;
    float: left !important;
    color: #000 !important;
}

.userSessionDropdown .ui.dropdown.dropdownGroup .menu>.item .text {
    max-width: 100%;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width:1024px) {

    .insightsChartWrap {
        height: 300px;
    }

    .insightsChartbox {
        display: flex;
        flex-direction: column;
    }

    .insightsChartPopup {
        width: 100%;
    }

    .insightsChartItem {
        flex: 1 1;
        padding: 5px 10px;
        flex-direction: column;
    }


    .insightsChartWrapper {
        position: relative;
        height: 180px;
        width: 100%;
        padding: 10px;
        margin-left: 20px;
        font: 400 12px 'Rubik', sans-serif;
    }
}

.insightsLoadingMessage {
    margin: auto;
    width: 10%;
}

/*** insights leftbar ***/

.insightsLeftbar {
    width: auto;
    max-width: 310px;
    position: fixed;
    left: 0;
    z-index: 2;
    top: 110px !important;
}

.insightsLeftbarWhenSessions {
    max-width: 800px;
    width: auto;
    position: fixed;
    left: 0;
    z-index: 2;
    top: 110px !important;
}

.ui.button.triggerToggleInsights {
    background: #fff !important;
    box-shadow: 7px 0px 7px rgba(0, 0, 0, .1) !important;
    border-radius: 0 100px 100px 0;
    position: absolute;
    right: -60px;
    top: 20px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.button.triggerToggleInsights i.icon {
    color: #727AFF;
    margin: 0 2px !important;
}

.insightsLeftbar:not(.minimizedSidebar) .ui.button.triggerToggle img {
    transform: rotate(180deg);
}

.sidebarInsights {
    float: left;
    min-width: 310px;
    width: 20%;
    height: 40vh;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #c0cbda;
    padding: 10px;
    margin: 10px 0 0 10px;
}


.sidebarInsights>.ui.image.infoButton {
    float: right;
    margin: 0 0 10px !important;
}

.sidebarInsights>.ui.popup.infoPopup {
    top: -10px !important;
    white-space: nowrap;
    width: auto !important;
}

.dataTableboxInsights {
    width: 100%;
    float: left;
    padding: 0;

}

.dataTableboxInsights .tableboxItemRow {
    border: none !important;
    margin: 0;
}

.dataTableboxInsights .tableboxItemRow.selected {
    border: solid 1px #727AFF !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn {
    border: none;
    font: 400 13px 'Rubik', sans-serif;
    padding: 5px 10px;
    height: auto;
    min-height: 0;
    min-width: 150px;
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn strong {
    font-weight: 500;
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn.ctableColumn {
    min-width: 100px;
}

.visitSessionStatusColumn {
    min-width: 76px !important;
    width: 76px !important;
    justify-content: center !important;
}

/* Insights Session Toggle CSS start */

.sessionInsightsCover {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
}

.sessionInsightsCover .rectangle-toggler {
    background: none;
    border: none !important;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item {
    background: #fff;
    font: 400 14px 'Rubik', sans-serif;
    cursor: pointer;
    border: solid 1px #e1e1e1;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item.active {
    background: #007bff;
    border: solid 1px #007bff;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item:first-child {
    border-right: none;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item:last-child {
    border-left: none;
}

.sidebarInsightsSessions {
    min-width: 500px;
    width: 45%;
    min-height: 50vh
}

.sidebarInsightsSessionsWhenSession {
    min-width: 800px;
    width: 45%;
    min-height: 50vh
}

.sessionList {
    width: 100%;
    float: left;
    border: solid 1px #ccc;
    margin: 0 0 10px;
}

.sessionListselected {
    background: #f2f2f2;
    box-shadow: inset 0px -1px 0 0px #ccc;
}

.sessionListselected .tableboxItemColumn:first-child {
    font-weight: 600;
    letter-spacing: -.4px;
    color: #727AFF;
}

.dataTableSession .tableboxItemRow .tableboxItemColumn {
    min-width: 155px;
}

.dataTableSession .tableboxItemRow .tableboxItemColumn:last-child {
    min-width: 60px;
    width: 60px;
    justify-content: center;
}

.sessionTable {
    padding-top: 8px;
}

.dataTableboxInsights.dataTableSession .tableboxItemRow .tableboxItemColumn:first-child {
    min-width: 300px;
    max-width: 300px;
    padding-right: 0;
}

.dataTableboxInsights.dataTableSession .tableboxItemRow .tableboxItemColumn:nth-child(2) {
    min-width: 90px;
}

/* Insights Session Toggle CSS end */

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn:first-child {
    min-width: 60%;
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn:first-child span {
    float: left;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui.popup.top.left.infoPopupleft {
    width: auto !important;
    white-space: nowrap;
    left: auto !important;
    top: -10px;
    right: 10px !important;
}

.ui.popup.top.right.infoPopupleft {
    width: auto !important;
    white-space: nowrap;
    right: auto !important;
    top: -10px;
    left: 10px !important;
}

.ui.popup.top.left.infoPopupLeftWrap {
    max-width: 500px !important;
    left: auto !important;
    top: -10px;
    right: 10px !important;
}

.rightCorner {
    right: 5px;
    position: absolute;
}

.rightCorner.extraPadding {
    right: 25px;
}



.toggleTooltipInsight .ui.fitted.toggle.checkbox {
    width: 34px !important;
    height: 20px;
    margin: 10px 0 0 !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox label {
    padding: 0;
    margin: 0;
    height: 16px;
    width: 34px;
    border-radius: 100px;
    background: none !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox input:checked~label:before {
    background: #737BFF !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox label:before {
    width: 34px;
    height: 16px;
    box-shadow: none !important;
    border: none;
    top: 0px;
    left: 0px;
    background: #737BFF !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox input:checked~label:before {
    background-color: none !important;
    /* filter:saturate(0) brightness(1); */
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox label:after {
    width: 19px;
    height: 19px;
    box-shadow: none !important;
    left: auto;
    right: 15px;
    top: -1px;
    background: url("/img/icon-toggle-insight.svg") no-repeat center #fff !important;
    background-size: 15px 15px !important;
    transition: all .3s ease;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .1) !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox input:checked~label:after {
    background: url("/img/icon-toggle-insight-checked.svg") no-repeat center #fff !important;
    background-size: 15px 15px !important;
    right: 0 !important;
    top: -.5px;
}

.insightsChartWrapError {
    align-items: center;
    justify-content: center;
}

.insightsChartWrapError .widgetMessage.verticalMessage {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 240px;
}

.insightsChartWrapError .widgetMessage.verticalMessage.hasError .ui.image {
    width: 70px;
    margin: 0 10px 10px 0 !important;
}

.insightsChartWrapError .widgetMessage.verticalMessage p {
    margin: 0 0 10px;
    text-align: left;
    flex: 1 1;
}

.rectangle-toggler {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;

}

.rectangle-toggler-item {
    flex: 1 1;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, color 0.3s ease, border-bottom 0.3s ease;
}

.rectangle-toggler-item.active {
    background-color: #007bff;
    color: #fff;
}

.insights-data-updatedAt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.ui.container.subHeader-con {
    width: calc(100% - 150px) !important;
    margin-left: 0 !important;
}

.lastUpdatedAtUI {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
}

.live {
    position: absolute;
    right: 130px;
    padding: 3px 5px;
    border-radius: 2px;
    font: 500 11px 'Rubik', sans-serif;
    cursor: pointer;
}

.sessionInsightsCover>.infoButton {
    margin: 0 !important;
}

.insightsBody>.insightsZoneBoxContainer>.mapContainer>.toggleSliderBox {
    right: 0;
}

.insightsBody>.placeholderBox>.placeholderCover>.children {
    height: 85%;
}

.insights-property-dwn {
    max-width: 400px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-disabled {
    opacity: .5 !important;
    cursor: not-allowed !important;
}

.info-button-padding-right {
    display: inline;
    padding-right: 10px !important;
}

.live-wrapper {
    margin-top: -17px !important;
}

.subheader {
    width: 100%;
    float: left;
    height: 47px !important;
    background: #fff;
    box-shadow: 0 2px 5px #ced3e2;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}

.subheader .ui.container {
    width: 100% !important;
    padding: 0 35px 0 12px;
    display: flex;
    align-items: center;
    height: 45px;
}

.ui.dropdown.propertyDropdown {
    float: left;
    width: auto;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    max-width: 300px;
}

.ui.dropdown.propertyDropdown>img {
    order: 1;
    margin-right: 11px;
}

.ui.dropdown.propertyDropdown .text {
    order: 2;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ui.dropdown.propertyDropdown .menu {
    width: 100%;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
    border: none;
}

.propertyComparison {
    width: 210px;
    margin: 0 35px 0 25px;
    display: flex;
    align-items: center;
}

.propertyComparison .ui.button.buttonIcon {
    width: 30px;
    height: 30px;
    border-radius: 3px 0 0 3px;
    border: solid 1px #D3D2DE !important;
    box-shadow: none !important;
    justify-content: center;
}

.propertyComparison .ui.toggle.checkbox {
    flex: 1 1;
    height: 30px;
    border-radius: 0 3px 3px 0;
    border: solid 1px #D3D2DE;
    border-left: none;
    display: flex;
    align-items: center;
    padding: 0 6px 0 6px;
    font: 400 13px 'Rubik', sans-serif;
}

.propertyComparison .ui.toggle.checkbox label {
    padding: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    color: #667581;
}

.propertyComparison .ui.toggle.checkbox label:before {
    min-width: 37px;
    width: 36px;
    height: 15px;
    top: 0;
    margin-right: 8px;
    background: #D3D2DE !important;
    transition: all 0.5s ease;
    position: relative;
}

.propertyComparison .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: #969DB8;
    box-shadow: none !important;
    top: 2px;
}

.propertyComparison .ui.toggle.checkbox input:checked~label:before,
.propertyComparison .ui.toggle.checkbox input:focus:checked~label:before {
    background: #727AFF !important;
}

.propertyComparison .ui.toggle.checkbox input:checked~label:after {
    left: 20px;
}

.subheader .ui.basic.buttonPropertyToggle {
    font: 400 13px 'Rubik', sans-serif;
    color: #667581 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 10px 0 0;
}

@media (min-width:1700px){
    .subheader .ui.basic.buttonPropertyToggle,
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        max-width:500px;
    }
}

@media (max-width:1650px){
    .subheader .ui.basic.buttonPropertyToggle > span{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }
}

@media (max-width:1300px){
    .subheader .ui.basic.buttonPropertyToggle,
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        max-width:200px;
    }
}

@media (max-width:1100px){
    .subheader .ui.basic.buttonPropertyToggle,
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        min-width:50px;
        max-width:120px;
    }
    .userSessionDropdown{margin-left:0;}
    .dateRangeCover{margin-right:10px;}
}

@media (max-width:770px){
    .userSessionDropdown{
        flex-direction: column;
        align-items: flex-start;
    }
    .insightsBody .subheader > .ui.basic.buttonPropertyToggle{
        align-self:flex-end;
    }
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        min-height:0;
    }
}

.ui.popup.subheaderPropertyPopup {
    width: 230px;
    border-radius: 0;
    border: none;
    padding: 0;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.ui.subheaderPropertyPopup:before {
    display: none !important;
}

.ui.subheaderPropertyPopup .ui.input {
    width: 100%;
    margin: 0;
    padding: 10px;
}

.ui.subheaderPropertyPopup .ui.input>input {
    height: 25px;
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: solid 1px #D3D2DE !important;
    padding: 0 20px 3px 0 !important;
    font: 400 13px 'Rubik', sans-serif !important;
}

.ui.subheaderPropertyPopup .ui.input .icon {
    width: 20px;
    font-size: 12px;
    right: 5px;
}

.searchDropdownCover {
    width: 100%;
    float: left;
    max-width: 290px;
    max-height: 240px;
    overflow-y: auto;
    border-top: solid 1px #D3D2DE;
}

.ui.subheaderPropertyPopup .listItem {
    width: 100%;
    float: left;
    position: relative;
}

.ui.subheaderPropertyPopup .listItem:after {
    content: '';
    border-bottom: solid 1px #D3D2DE;
    width: 95%;
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.listItem.selected {
    background-color: #e6fced;
}

.ui.subheaderPropertyPopup .listItem.selected:after {
    width: 100%;
}

.ui.subheaderPropertyPopup .listItem:last-child:after {
    display: none !important;
}

.ui.subheaderPropertyPopup .ui.basic.titleItem {
    width: 100%;
    float: left;
    padding: 7px 25px 7px 10px;
    font: 400 14px 'Rubik', sans-serif;
    color: #000 !important;
    text-decoration: none;
    text-align: left;
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.ui.subheaderPropertyPopup .ui.basic.listArrow {
    position: absolute;
    right: 0;
    text-decoration: none;
    padding: 9px 5px !important;
}

.ui.subheaderPropertyPopup .ui.basic.listArrow .icon {
    margin: 0 !important;
}

.subheaderBuildingPopup {
    width: 230px;
    background: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 5px;
}

.ui.popup.infoPopup.propertyPopupTooltip{
    width:-webkit-max-content;
    width:max-content;
    max-width:400px;
    min-width:150px;
    padding:8px 10px;
    border-radius:4px;
    border:none;
    background:#333;
    color:#fff;
    box-shadow:none;
    pointer-events: none;
    opacity:.8;
}
.ui.bottom.left.infoPopup.propertyPopupTooltip:before{
    border:none;
    box-shadow:none;
    background:#333;

}
.overviewHead {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 1rem;
}

.overviewHeadLeft {
   
    display: flex;
    align-items: center;
}

.overviewHeadLeft h2.ui.header {
    font: 500 18px 'Rubik', sans-serif;
    color: #000;
    margin: 0!important;
    max-width:650px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    
}

.overviewMenu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.overviewMenu .ui.buttonTab {
    float: left;
    background: none!important;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    height: 30px;
    padding: 2px 0 0;
    cursor: pointer;
    margin: 0 0 0 20px;
    border-radius: 0!important;
    transition: all .0 ease;
    border-bottom: solid 3px transparent;
    position:relative;
}

.overviewMenu .ui.buttonTab:first-child {
    margin-left: 0;
}

.overviewMenu .ui.buttonTab:hover {
    color: #727AFF;
}

.overviewMenu .ui.buttonTab.active {
    border-color: #727AFF!important;
}
.overviewMenu .ui.buttonTab > span{
    background:#727AFF;
    position: absolute;
    left:100%;
    top: 3px;
    color: #fff;
    padding: 1px 4px 2px;
    border-radius: 2px;
    font: 500 9px 'Rubik', sans-serif;
    margin-left: 5px;
    pointer-events:none;
}

.overviewMenu li {
    margin: 0 0 0 38px;
}

.overviewMenu li a {
    float: left;
    margin: 0 0 -1px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    height: 30px;
    padding: 5px 0 0;
    cursor: pointer;
}

.overviewMenu li a:hover {
    color: #727AFF;
}

.overviewMenu .active a {
    box-shadow: inset 0 -3px 0 0 #727AFF;
}

.overviewHeadRight {
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.exportDropdown {
    font: 400 15px 'Rubik', sans-serif!important;
    color: #727AFF !important;
    margin-left: 10px !important;
    background: none!important;
    border: none;
    padding: 0!important;
}

.button-anchor {
    color: #727AFF !important;
    background: none!important;
    border: none;
    padding: 0!important;
}
.topheadBoost {
    width: 100%;
    height: 47px;
    background: #fff;
    border-bottom: solid 1px #d3d2de;
    border-top: solid 1px #d3d2de;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.ui.header.boostHeader {
    flex: 1 1;
    font: 600 15px "Rubik", sans-serif;
    display: inline-flex;
    align-items: center;
    margin: 0;
}

.ui.header.boostHeader img {
    margin: 0 0 0 5px;
    width: 12px;
    cursor: pointer;
}

.topheadBoost .ui.header.boostHeader {
    flex: auto;
    display: inline-block;
}

.boostHeadTogglegroup {
    display: inline-flex;
    align-items: center;
    font: 400 14px "Rubik", sans-serif;
    margin: 0 20px 0 0;
}

.ui.checkbox.toggle.toggleBoostHead {
    width: 60px !important;
    height: 30px;
    margin: 0 10px !important;
}

.ui.checkbox.toggle.toggleBoostHead label {
    padding: 0;
    margin: 0;
    height: 29px;
    width: 59px;
    border-radius: 3px;
    border: solid 1px #d3d2de !important;

    background: rgb(114, 122, 255);
    background: linear-gradient(90deg, rgba(114, 122, 255, 1) 50%, rgba(255, 255, 255, 1) 50%);
}

.ui.toggle.checkbox input:checked~label {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(114, 122, 255, 1) 50%);
}

.ui.checkbox.toggle.toggleBoostHead label:before {
    /* background: url(/img/icon-chevron-down.svg) no-repeat center!important; */
    width: 30px;
    height: 30px;
    box-shadow: none !important;
    /* border-radius:3px 0 0 3px; */
    border: none;
    top: -1px;
    left: -1px;

    background: none !important;
}

.ui.checkbox.toggle.toggleBoostHead input:checked~label:before {
    background-color: none !important;
    /* filter:saturate(0) brightness(1); */
}

.ui.checkbox.toggle.toggleBoostHead label:after {
    background-color: none !important;
    width: 30px;
    height: 30px;
    /* border-radius:0 3px 3px 0; */
    box-shadow: none !important;
    left: auto;
    right: -1px;
    top: -1px;
}

.ui.checkbox.toggle.toggleBoostHead input:checked~label:after {
    right: -1px !important;
}

.ui.button.buttonIcon.boostHeadButton {
    width: 30px;
    height: 30px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    border-radius: 3px;
    margin: 0 0 0 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.toggleButtonGroup {
    display: inline-flex;
    margin: 0 10px;
}

.toggleButtonGroup .ui.button.buttonIcon {
    min-width: 30px;
    width: auto;
    height: 30px;
    padding: 0 5px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    border-right: none !important;
    font: 400 14px "Rubik", sans-serif;
    color: #333 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
}

.toggleButtonGroup .ui.button.buttonIcon:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.toggleButtonGroup .ui.button.buttonIcon:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: solid 1px #d3d2de !important;
}

.toggleButtonGroup .ui.button.buttonIcon.active {
    background: #727aff !important;
    border-color: #727aff !important;
    color: #fff !important;
}

.toggleButtonGroup .ui.button.buttonIcon.active img {
    filter: saturate(0) brightness(100);
}

.boostHeadActions {
    display: inline-flex;
    margin: 0 0 0 20px;
}

.boostHeadActions .ui.button.buttonIcon {
    width: 30px;
    height: 30px;
    margin: 0 0 0 10px;
    border-radius: 3px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    font: 400 14px "Rubik", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.playslidebox {
    min-width: 200px;
    display: inline-flex;
    align-items: center;
    border: solid 1px #d3d2de;
    border-radius: 3px;
    background: #fff;
}

.playslidebody {
    flex: 1 1;
}

.playslidetrigger {
    width: 130px;
    height: 2px;
    margin: 0 10px;
    border-radius: 4px;
    background: #d3d2de;
    position: relative;
}

.playslidetrigger span {
    min-width: 9px;
    width: 9px;
    height: 9px;
    border-radius: 10px;
    background: #727aff;
    position: absolute;
    left: 0;
    top: -4px;
}

.playbuttonsGroup {
    display: inline-flex;
}

.playbuttonsGroup .ui.button {
    width: 30px;
    height: 30px;
    background: #fff !important;
    border: none;
    border-left: solid 1px #d3d2de;
    border-radius: 0;
    padding: 0;
    margin: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.playbuttonsGroup .ui.button img {
    margin: 0;
}

.playbuttonsGroup .ui.button:first-child img {
    transform: rotate(180deg);
}

.playbuttonsGroup .ui.button:last-child {
    border-radius: 0 3px 3px 0;
}

.playbuttonsGroup .ui.button.disabled {
    background: #f1f1ff !important;
    opacity: 1 !important;
}

.playbuttonsGroup .ui.button.disabled img {
    opacity: 0.5;
}

.ui.button:not(.icon)>.icon:not(.button):not(.dropdown),
.ui.button:not(.icon)>.icons:not(.button):not(.dropdown) {
    margin-right: 0px !important;
}

.ui.button.heatmapButton {
    filter: saturate(1) !important;
}

.ui.popup.notificationPopover {
    border: none;
    border-radius: 3px;
    font: 400 14px "Rubik", sans-serif;
    white-space: nowrap;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.ui.popup.notificationPopover:before {
    box-shadow: 0 0 0 0 #bababc !important;
}

.notipopupDateTime {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    padding: 5px 10px;
    position: fixed;
    right: 100px;
    top: 235px;
    z-index: 10;
}

.notipopupDateTime p {
    float: left;
    font: 400 14px "Rubik", sans-serif;
    white-space: nowrap;
    margin: 0;
}

.ui.dropdown.dropdownDayGroup {
    width: auto;
    height: 30px;
    padding: 0 7px;
    margin: 0 15px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    border-radius: 3px !important;
    font: 400 13px "Rubik", sans-serif !important;
    color: #000 !important;
    display: inline-flex;
    align-items: center;
}

.ui.dropdown.dropdownDayGroup>.text {
    white-space: nowrap;
}

.ui.dropdown.dropdownDayGroup .dropdown.icon {
    color: #667581 !important;
    font-size: 13px !important;
    margin: 0 0 0 5px !important;
}

.overviewBoost {
    border-bottom: solid 1px #d3d2de;
}

.overviewBoost .reportsMenu {
    padding: 0 0 0 15px;
    justify-content: flex-start;
}

.overviewBoost .overviewHeadLeft {
    border: none;
}

.boostAnalyticsBody .mapContainer .toggleSliderBox {
    top: 70px;
    right: 20px;
}

.boostAnalyticsBody .mapContainer .toggleSliderBox input:checked~label {
    background: none !important;
}

.boostAnalyticsBody .mapContainer .floorButtonsCover {
    top: 110px;
    right: 20px;
}

.userflowGroupBoost a.active .ui.button {
    border-color: #f15944 !important;
}

.boostAnalyticsBody .mapContainer .ol-control {
    width: 100%;
    position: absolute;
    bottom: 50px;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 10px 0;
}

.boostAnalyticsBody .mapContainer .ol-control button {
    cursor: pointer;
    position: relative;
    top: 50px;
    left: 5px;
}

.boostAnalyticsBody .mapContainer .ol-control ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

.boostAnalyticsBody .mapContainer .ol-control ul li {
    display: inline-flex !important;
}

.boostAnalyticsBody .mapContainer .ol-control ul p {
    margin: 0;
    font: 400 13px "Rubik", sans-serif;
}

.boostAnalyticsBody .mapContainer .ol-control.ol-collapsed ul {
    display: none;
}

.userflowGroup.userflowGroupBoost {}

.userflowGroup.userflowGroupBoost>a:last-child .ui.button {
    margin: 0 !important;
}

/*** explainer video modal ***/

.explainerVideoCover {
    width: 100%;
    float: left;
    position: relative;
}

.ui.button.basic.buttonIcon.fullScreenToggle {
    position: absolute;
    right: 18px;
    top: 12px;
    z-index: 9;
    background: #fff !important;
    padding: 4px !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
}

.ui.button.fullScreenToggle img {
    width: 16px;
    transition: all 0.3s ease;
}

.fullScreenWide .ui.button.fullScreenToggle {
    top: 19px;
}

.fullScreenWide .ui.button.fullScreenToggle img {
    filter: invert(1);
}

/*** boost map ***/

.boostMapContainer {
    width: 100%;
    float: left;
    display: flex;
}

.boostMapWrap {
    flex: 1 1;
    background: #fff;
    border-right: solid 1px #d3d2de;
}

.boostMapCover {
    float: left;
}

.boostMapCover img {
    max-width: 100%;
}

.boostZoneTablebox {
    width: 100%;
    min-height: calc(50vh - 220px);
    padding: 0;
    border-left: solid 1px #d3d2de;
    background: #fff;
    position: relative;
}

.scrollAlignerboost {
    width: 100%;
    float: left;
    padding: 0 15px;
}

.tableSubRow {
    width: 100%;
    float: left;
    flex-direction: column;
}

.tableSubCover {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding: 7px 0;
}

.tableSubCover:not(:first-child) .peakHoursItem {
    font-size: 12px;
    color: #667581;
}

.tableSubCover:nth-child(even) {
    background: #fafafa;
}

.boostNetworkConnectionTablebox .peakHoursItem:first-child {
    min-width: 55% !important;
    flex: 1 1;
}

.boostNetworkConnectionTablebox .peakHoursRow .peakHoursItem {
    min-width: 35%;
    text-align: left !important;
}

.boostNetworkConnectionTablebox .peakHoursRow .peakHoursItem:last-child {
    min-width: 10%;
}

.boostInfoTablebox .peakHoursTable {
    padding: 0 20px;
    margin-top: 15px;
}

.boostInfoTablebox .peakHoursRow:nth-child(odd):not(:first-child) {
    background: #f3f6fb;
}

.boostInfoTablebox .peakHoursRow .peakHoursItem {
    text-align: left !important;
}

.boostInfoTablebox .peakHoursRow .peakHoursItem:first-child {
    min-width: 37%;
}

.boostInfoTablebox .peakHoursRow:not(:first-child) {
    padding: 0;
    cursor: pointer;
}

.boostInfoTablebox .peakHoursRow .peakHoursItem:nth-child(2) {
    min-width: 10%;
    max-width: 10%;
}

.boostTagTablebox .peakHoursRow .peakHoursItem:first-child {
    min-width: 28%;
}

.boostTagTablebox .peakHoursRow .peakHoursItem:nth-last-child(2) {
    min-width: 22%;
}

.boostInfoTablebox .peakHoursRow.active {
    color: #000 !important;
    border-color: #727aff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.boostInfoDevicesBox {
    margin-top: 10px;
    overflow: scroll;
    padding: 0 20px;
}

.addRemoveDeviceToggle label::before {
    border: solid 1px red !important
}

.addRemoveDeviceToggle label::after {
    background: red !important;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:first-child {
    flex: 1 1;
    min-width: 22%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(2) {
    min-width: 10%;
    max-width: 10%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(3) {
    min-width: 25%;
    max-width: 25%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(4) {
    min-width: 22%;
    max-width: 22%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:last-child {
    min-width: 18%;
    max-width: 18%;
}

.boostInfoZoneTablebox .peakHoursRow:first-child .peakHoursItem:last-child .peakHoursColumn {
    text-align: center;
}

.boostTabMenus .ui.secondary.pointing.menu .active.item {
    border-color: #f15944;
    color: #f15944;
}

.inputDevicesFilters {
    justify-content: space-evenly;
}

.inputDevicesFilters .ui.primary.button {
    min-width: 70px;
    min-height: 28px;
}


.trippleTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 33.3% !important;
    width: 33.3% !important;
}


.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(3) {
    min-width: 25%;
    max-width: 25%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(4) {
    min-width: 22%;
    max-width: 22%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:last-child {
    min-width: 18%;
    max-width: 18%;
}

.boostInfoZoneTablebox .peakHoursRow:first-child .peakHoursItem:last-child .peakHoursColumn {
    text-align: center;
}

.trippleTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 33.3% !important;
    width: 33.3% !important;
}

.quarterTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 25% !important;
    width: 25% !important;
}

.fiveTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 20% !important;
    width: 20% !important;
}

/* Boost Zone  */

.boostZoneBoxContainer {
    width: 100%;
    float: left;
    height: calc(100vh - 160px);
    overflow: hidden;
}

.boostZoneMapContainer {
    width: 100%;
    float: left;
    height: calc(100vh - 160px);
}

.boostZoneMapContainer .errorWidgetWrap {
    background: #fff;
}

.boostAnalyticsBody .errorWidgetWrap {
    background: #fff;
}

.bzmPlaceholderCover .ui.placeholderCover .children {
    transform: translate(0, 0);
    top: -35px;
}

.noDiaplay {
    display: none;
}

.boostZoneTablebox .errorWidgetWrap {
    width: 100%;
    height: calc(100vh - 240px);
    justify-content: center;
    align-items: center;
    padding-bottom: 9px;
}

.widgetCoverFlexible {
    float: left;
    width: 100%;
    height: calc(55vh) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widgetCoverFlexible.boostInfo {
    height: calc(25vh) !important
}

.placeholderCover .image {
    height: calc(46vh - 185px) !important;
}

.boost-bar>.OccupancyChartContainer {
    height: calc(100vh - 200px);
    padding: 40px 20px;
    position: relative;
}

.boost-bar>.OccupancyChartContainer>.legendsPopbox {
    height: auto;
    max-height: calc(100vh - 283px);
    width: 157px;
    cursor: pointer;
    overflow-x: scroll;
    bottom: 41px;
}

.boost-bar>.OccupancyChartContainer>div:first-child {
    border: solid 1px #d3d2de;
    padding: 0 0 10px;
}

.OccupancyChartContainer {
    width: 100%;
    float: left;
    height: calc(48vh - 150px);
    background: #fff;
}


.rowBoost {
    width: 100%;
    float: left;
    display: flex;
}

.columnBoost {
    flex: 1 1;
    width: 50%;
    position: relative;
}

.columnBoost.fullScreenWide {
    height: calc(100vh - 194px);
}

/*** top path ***/

.ui.header.boostWidgetHeading {
    width: 100%;
    float: left;
    font: 600 14px 'Rubik', sans-serif;
}

.ui.dropdown.dropdownTopPath {
    width: 200px;
    min-height: 0 !important;
    height: 25px;
    border-radius: 0;
    margin: 0;
}

.chartPathCover {
    width: 100%;
    float: left;
    padding: 20px;
}

.chartPathRow {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 20px;
}

.chartPathRow2 {
    min-height: 180px;
}

.chartPathDropdown i.icon {
    margin: 2px 5px 0 !important;
}

.chartPathDropdown>.menu {
    width: 200px;
}

.chartPathColumn {
    flex: 1 1;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 50px 0 0;
}

.chartPathColumn:last-child {
    padding: 0 0 0 50px;
}

.chartPathCenter {
    width: -webkit-max-content;
    width: max-content;
    min-height: 52px;
    background: #667581;
    padding: 15px 20px;
    margin: 10px 0 0;
    font: 400 13px 'Rubik', sans-serif;
    color: #fff;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    align-self: center;

    position: relative;
    z-index: 2;
    margin: 0 -10px;
}

.chartPathItem {
    width: 100%;
    height: 34px;
    background: #2B1A1A;
    font: 400 13px/10px 'Rubik', sans-serif;
    color: #fff;
    padding: 0 10px 0 25px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: all 0.3s ease;
}

.chartPathItem:hover {
    transform: scale(1.01)
}

.chartPathItem:nth-child(2) {
    margin: 41px 0 40px;
}

.chartPathColumn:nth-child(3) .chartPathItem {
    padding: 0 25px 0 10px;
}

.chartPathItem span {
    font-weight: 500;
    border-left: solid 1px #FFFFFF;
    margin: 0 0 0 8px;
    padding: 0 0 0 8px;
}

.chartPathColumn:last-child .chartPathItem {
    flex-direction: row-reverse;
}

.chartPathColumn:last-child .chartPathItem span {
    padding: 0 8px 0 0;
    margin: 0 8px 0 0;
    border-left: none;
    border-right: solid 1px #fff;
}

.chartPathItem:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 0 17px 14px;
    border-color: transparent transparent transparent #fff;

    position: absolute;
    left: 0;
    top: 0;
    transition: all 1s ease;
}

.chartPathItem:hover:before {
    left: -1px
}

.chartPathColumn:last-child .chartPathItem:before {
    transition: all .1s ease;
    transform: scale(1.2)
}

.chartPathColumn:last-child .chartPathItem:hover:before {
    right: -1px;
    top: -3px;
    border-width: 21px 16px 21px 0px;
}

.chartPathColumn:last-child .chartPathItem:before {
    left: auto;
    right: -1px;
    transform: rotate(180deg);
    border-width: 18px 15px 17px 0px;
    border-color: #fff transparent #fff transparent;
}

.chartPathColumn:first-child .chartPathItem .tail,
.chartPathColumn:last-child .chartPathItem .tail {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34px 0 0px 70px;
    border-color: transparent transparent transparent #2b1a1a;
    position: absolute;
    top: 0;
}

.chartPathColumn:first-child .chartPathItem .tail {
    right: -60px;
}

.chartPathColumn:first-child .chartPathItem:first-child .tail {
    transform: skewY(36deg);
    top: 25px;
    right: -70px;
}

.chartPathColumn:first-child .chartPathItem:nth-child(2) .tail {
    transform: skewY(-16.5deg);
    top: -8.5px;
    border-width: 33.5px 0 0px 60px;
}

.chartPathColumn:first-child .chartPathItem:nth-child(3) .tail {
    transform: skewY(-50.5deg);
    top: auto;
    bottom: 42.5px;
    right: -70px;
}

.chartPathColumn:last-child .chartPathItem .tail {
    left: -60px;
}

.chartPathColumn:last-child .chartPathItem:first-child .tail {
    transform: skewY(-36deg) scaleX(-1);
    top: 25px;
    left: -70px;
}

.chartPathColumn:last-child .chartPathItem:nth-child(2) .tail {
    transform: skewY(16.5deg) scaleX(-1);
    top: -8.5px;
    border-width: 33.5px 0 0px 60px;
}

.chartPathColumn:last-child .chartPathItem:nth-child(3) .tail {
    transform: skewY(50.5deg) scaleX(-1);
    top: -42.5px;
    left: -70px;
}

.dropdownPathGroup {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.dropdownPathGroup .textGroup {
    margin: 0;
}

.dropdownPathGroup .textGroup>b {
    margin: -2.5px 10px 0 0;
    align-self: flex-start;
}

.dropdownPathGroup .ui.dropdown.dropdownTopPath {
    width: 100%;
}

.ui.button.swapButton {
    margin: 0 25px !important;
    padding: 5px !important;
}

.boostTrafficFlowRow {
    background: #fff;
    height: calc(100vh - 220px);
}

.boostTrafficFlowRow .columnBoost:last-child {
    border-left: solid 1px #D3D2DE;
    min-width: 580px;
}

@media (max-width:1200px) {
    .chartPathItem {
        font-size: 11px;
        padding: 0 0 0 20px;
    }

    .chartPathColumn:last-child .chartPathItem {
        padding: 0 20px 0 0;
    }
}

.alignerBoostCover {
    height: 75vh;
    width: 100%;
    float: left;
}

/***/

.trafficFlowTablebox {
    max-height: calc(100vh - 256px);
    padding: 0 20px 10px 0;
    margin: 35px 0 0 0;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
}

.trafficFlowTablebox::-webkit-scrollbar {
    width: 10px;
    background-color: #F3F6FB;
}

.trafficFlowTablebox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #BABBCB;
}

.dataTablebox {
    width: 100%;
    float: left;
}

.tableboxItemRow {
    width: 100%;
    float: left;
    min-width: -webkit-max-content;
    min-width: max-content;
    display: flex;
    margin: 0 0 5px;
    background: #fff;
    border-right: solid 1px #D3D2DE;
    border-radius: 0 2px 2px 0;
    position: relative;
}

.tableboxItemColumn {
    flex: 1 1;
    width: 11%;
    min-width: 120px;
    max-width: 120px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    display: inline-flex;
}

.tableboxItemRow .tableboxItemColumn {
    border-top: solid 1px #D3D2DE;
    border-bottom: solid 1px #D3D2DE;
    align-items: center;
    min-height: 40px;
    padding: 0 5px;
    cursor: pointer;
}

.tableboxItemRowHead {
    width: 100%;
    float: left;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.tableboxItemRowHead .tableboxItemColumn {
    font-size: 12px;
    color: #667581;
    background: #fff;
    padding: 10px 0 5px 6px;
}

.trafficFlowTablebox .tableboxItemRow .tableboxItemColumn:first-child {
    font-size: 12px;
    color: #667581;
    background: #fff;
    border: none;
    padding: 0;
    padding-left: 10px;
}

.trafficFlowTablebox .tableboxItemColumn:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
}

.trafficFlowTablebox .tableboxItemRowHead .tableboxItemColumn:first-child {
    left: 0;
    top: 0;
    display: inline-flex;
    flex-direction: column;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
    padding: 0 10px;
    overflow: hidden;
}

.trafficFlowTablebox .tableboxItemRowHead .tableboxItemColumn:first-child:before {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: solid 1px rgba(0, 0, 0, .2);
    position: absolute;
    top: 22px;
    left: 0;
    transform: rotate(20deg);
}

.tagFrom {
    align-self: flex-end;
    margin: 8px 0 0;
}

.trafficFlowTablebox .tableboxItemRow .tableboxItemColumn:first-child:after {
    content: '';
    width: 2px;
    height: 100%;
    border: solid 1px #D3D2DE;
    border-right: none;
    border-radius: 2px 0 0 2px;
    margin: 0 0 0 auto;
}

.trafficFlowTablebox .tableboxItemColumn:first-child {
    max-width: 100px;
    padding-left: 10px;
}

.tableboxItemRow:nth-child(even) {
    background: #F3F6FB;
}

.tableboxItemRow:nth-child(even) .tableboxItemColumn:first-child:after {
    background: #F3F6FB;
}

.bs-active {
    color: #000 !important;
    box-shadow: 0 0 8px rgb(0 0 0 / 10%);
}

.trafficFlowTablebox .tableboxItemColumn {
    min-width: 110px;
    width: 110px;
    max-width: 110px;
    background: #fff;
}

.trafficFlowTablebox .tableboxItemRow:nth-child(even) .tableboxItemColumn {
    background: #F3F6FB;
}

.trafficFlowTablebox .tableboxItemRow {
    border-right: none !important;
}

.trafficFlowTablebox .bs-active .tableboxItemColumn {
    border-top: 1px solid #727AFF;
    border-bottom: 1px solid #727AFF;
}

.trafficFlowTablebox .tableboxItemRow.bs-active .tableboxItemColumn:first-child {
    border-top: 1px solid #727AFF !important;
    border-bottom: 1px solid #727AFF !important;
}

.trafficFlowTablebox .tableboxItemRow .tableboxItemColumn:last-child {
    border-right: solid 1px #D3D2DE !important;
    flex: 1 1;
    max-width: 100% !important;
}

.trafficFlowTablebox .tableboxItemRow.bs-active .tableboxItemColumn:last-child {
    border-right: 1px solid #727AFF !important;
}

.fullScreenWide .trafficFlowTablebox .tableboxItemColumn {
    min-width: 130px;
}

/** quick snapshot **/

.quickSnapshotRow {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    background: #f3f6fb;
}

.quickSnapshotColumn {
    width: 50%;
    background: #fff;
    padding: 15px 10px 10px 15px;
    margin: 0 0 10px;
    min-height: 300px;
}

.quickSnapshotRow:last-child .quickSnapshotColumn {
    margin: 0;
}

.quickSnapshotColumn:first-child {
    border-right: solid 10px #f3f6fb;
}

.quickSnapshotRow .widgetCoverFlexible {
    height: 300px !important;
}

.quickSnapshotLiveSpace {
    padding: 15px 2px 10px 15px;
}

.defaultQuickSnapshot {
    width: 100%;
    float: left;
    min-height: 300px;
}

.defaultQuickSnapshot svg>g {
    transform: translate(0, 0) !important
}

.defaultQuickSnapshot svg>g>g {
    display: flex;
    align-items: center;
}

.defaultQuickSnapshot svg>g>g text {
    transform: translate(0, 0) !important;
}


.defaultQuickSnapshot>div:last-child,
.pieChartFloorCover {
    width: 100%;
    float: left;
    height: 300px !important;
    position: relative;
}

.defaultQuickLiveSpace {
    width: 100%;
    float: left;
    padding: 0 10px 0 0;
}

.liveSpaceDefaultCover {
    width: 100%;
    float: left;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    margin: 0 0 5px;
}

.liveSpaceTitle {
    width: 100%;
    float: left;
    font: 400 15px 'Rubik', sans-serif;
    padding: 10px 15px;
    color: #000;
}

.quicksnapshotItem {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 2px;
    border: solid 1px transparent;
}

.quicksnapshotItem.active {
    background: #fafafa;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
    border-color: #6d7c87;
    position: relative;
    z-index: 2;
}

.quicksnapshotItem:nth-child(even) {
    background: #fafafa;
}

.ui.selection.dropdown.dropdownZoneHistory {
    display: flex;
    align-items: center;
    padding: 0 0 0 10px !important;
    z-index: 1;
}

.ui.selection.dropdown.dropdownZoneHistory>.text {
    flex: 1 1;
}

.ui.selection.dropdown.dropdownZoneHistory>.icon {
    margin: 2px 10px 0 0 !important;
}

.hourlyvisitZoneGeofence {
    width: 100%;
    float: left;
    margin: 0 0 -20px;
}

.hourlyvisitZoneGeofence .OccupancyChartContainer {
    height: 460px;
}

.deviceInfoIconPaddingL {
    padding-left: 18
}

.boost-zone-peek-wrapper {
    padding: 2% 8% 0% 8% !important;
}

/*** netowrk connection ***/

.networkConnectionCover {
    width: 100%;
    float: left;
    border-bottom: solid 11px #f3f6fb;
    padding: 0 10px 20px;
}

.networkConnectionRow {
    background: #fff;
}

.networkConnectionRow>.columnBoost:nth-child(2) {
    border-left: solid 1px #D3D2DE;
}

.networkConnectionRow .alignerBoostCover {
    padding: 10px 0;
}

.networkConnectionRow .tableboxItemRow {
    border-right: none !important;
}

.networkConnectionRow .tableboxItemRowHead .tableboxItemColumn {
    /* border:none!important;
    padding:0;
    align-items:center;
    min-width:16%;
    max-width:16%;
    width:16%;
    flex:1;
    text-align:center; */
}

.networkConnectionRow .tableboxItemColumn {
    border: none !important;
    min-width: 16%;
    max-width: 16%;
    text-align: center;
    justify-content: center;
}

.networkTableRowbox {
    width: 100%;
    float: left;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    margin: 0 0 5px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.networkTableRowbox .tableboxItemRow:first-child .tableboxItemColumn {
    font-size: 15px;
    color: #000;
    min-height: 40px;
}

.networkTableRowbox .tableboxItemRow .tableboxItemColumn {
    font: 400 12px 'Rubik', sans-serif;
    color: #667581;
    padding: 5px 15px;
    text-align: center;
    min-height: 20px;
    /* min-width:16%;
    max-width:16%;
    width:16%;
    flex:1; */
    justify-content: center;
}

.networkTableRowbox .tableboxItemRow:nth-child(even) {
    background: #FAFAFA;
}

.networkTableRowbox.bs-active {
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    border-color: #667581;
}

.comfortTablebox .tableboxItemRow.bs-active .tableboxItemColumn {
    border-color: #667581;
}

.networkTableRowbox .tableboxItemRow .tableboxItemColumn:first-child,
.networkConnectionRow .tableboxItemRowHead .tableboxItemColumn:first-child {
    /* flex:1;
    min-width:20%;
    max-width:20%;
    width:16%;
    flex:1;
    text-align:left;
    justify-content:flex-start; */
}

.networkConnectionRow .tableboxItemColumn:first-child {
    min-width: 20%;
    text-align: left;
    justify-content: flex-start;
}

.networkTablebox {
    padding: 0 10px 0 0;
    position: relative;
}



.networkTablebox .tableboxItemRow {
    min-width: 800px;
}

.networkTablebox .tableboxItemRow.tableboxItemRowHead {
    border: solid 1px #fff !important;
}


.networkTableRowbox .tableboxItemRowHead {
    border-right: none !important;
}

.networkConnectionRow .tableboxItemRowHead .tableboxItemColumn {
    padding: 5px 6px !important;
}

.networkConnectionCover {
    width: 100%;
    float: left;
    max-height: 500px;
    overflow: auto;
}

.networkConnectionCover::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
}

.networkConnectionCover::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #BABBCB;
}

.alignerNetworkCover {
    width: 100%;
    float: left;
    padding: 0 0 10px 10px;
}

.pieChartFloorCover {
    overflow-y: inherit;
}

.chartVendorCover .pieChartFloorCover {
    height: calc(50vh - 5px) !important;

}

/*** boost comfort ***/

.boostComfortRow {
    background: #fff;
}

.boostComfortRow .columnBoost:nth-child(2) {
    border-left: solid 1px #D3D2DE;
}

.comfortTablebox {
    padding: 10px;
    max-width: 100%;
    overflow: auto;
    margin-top: 40px;
    padding-right: 30px;
}

.comfortTablebox .tableboxItemRowHead {
    border: solid 1px #fff !important;
    margin: 0;
}

.comfortTablebox .tableboxItemColumn {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
    padding: 5px 10px;
    text-align: center;
    justify-content: center;
}

.comfortTablebox .tableboxItemColumn:first-child,
.comfortTablebox .tableboxItemColumn:nth-child(2),
.comfortTablebox .tableboxItemColumn:nth-child(3) {
    width: 22% !important;
    min-width: 22% !important;
    max-width: 22% !important;
}

.comfortTablebox .tableboxItemColumn:first-child {
    border-left: solid 1px #D3D2DE;
    border-radius: 2px 0 0 2px;
    text-align: left;
    justify-content: flex-start;
}

.comfortTablebox .tableboxItemColumn:last-child {
    border-right: solid 1px #D3D2DE;
    border-radius: 0 2px 2px 0;
}

.comfortTablebox .tableboxItemRowHead .tableboxItemColumn {
    border: none !important;
    padding: 0 0 5px !important;
}

.comfortTablebox .tableboxItemRowHead .tableboxItemColumn span {
    max-width: 87px;
}

.comfortTablebox .tableboxItemRow {
    min-width: 700px;
}

.columnBoost.fullScreenWide .comfortTablebox .tableboxItemColumn {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
}

.columnBoost.fullScreenWide .comfortTablebox .tableboxItemColumn:last-child {
    max-width: 1000px !important;
    justify-content: flex-start;
}

.columnBoost.fullScreenWide .comfortTablebox .tableboxItemColumn:last-child img {
    margin-left: 26px;
}


.boost-comfort-icon {
    height: 25px !important;
}

.traffic-flow-table-title {
    overflow: hidden;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.chartPathDropdown {
    width: 170px !important;
}

.chartPathDropdown .ui.selection.dropdown .menu>.item {
    padding: 8px 10px !important;
    min-height: 0 !important;
    font-size: 13px;
}

.no-display {
    display: none;
}

.controlBtn {
    cursor: pointer;
}

.listOfInputDevices {
    margin-top: 10px;
    height: 30vh;
    width: 100%;
    overflow: scroll;
}

/* Add these styles in your CSS file or component */
.custom-file-input-label {
    cursor: pointer;
    color: #737BFF;
    border-radius: 5px;
    display: inline-block;
}

.custom-file-input {
    display: none;
    /* Hide the default file input */
}

.selected-file-info {
    margin-top: 10px;
    color: #333;
}

.selected-file-info-error {
    margin-top: 10px;
    color: red;
}



.ui.input.inputClientDeviceCtl>input {
    border: none !important;
    border-bottom: 1px solid #D3D2DE !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

.csvText {
    color: black;
}

.ui.modal.permissionModalTag {
    max-width: 500px;
    text-align: center;
}

.ui.modal.permissionModalTag .ui.header {
    width: 100%;
    float: left;
    margin: 0 0 30px;
    text-align: center;
    font: 500 20px 'Rubik', sans-serif;
}

.ui.modal.permissionModalTag>.content {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.boost-wrapper {
    display: flex;
    flex: auto;
}

.boost-wrapper .tableViewBody .mapContainer {
    height: 95%;
}

.boost-body {
    width: 100vw;
    height: 80vh;
    border: solid 1px #d3d2de;
}

.tableViewBody {
    width: 100vw;
    max-height: 90vh;
    overflow-y: hidden;
}

.ui.dimmer{
    background:rgba(0,0,0,0.3)!important;
}

.ui.modal.modalLayout{
    width:96%;
    max-width:820px;
    border-radius:0;
    padding:35px 0 38px;
}

.ui.modalLayout .ui.closeBtn:before,
.ui.modalLayout .ui.closeBtn:after{
    background:#000;
}


.ui.modal.modalLayout > .aligner > .header{
    width:100%; float:left;
    font:400 18px 'Rubik',sans-serif;
    color:#000;
    border-bottom:solid 1px #D3D2DE;
    padding:0 0 5px;
    margin:0 0 20px;
}

.ui.modal.modalLayout > .aligner{
    padding:0 38px;
}

.ui.modal.modalLayout > .content{
    padding:0 38px;
    width:100%; float:left;
}
.ui.modal.modalLayout > .actions{
    padding:35px 38px 0;
    margin:0;
    width:100%; float:left;
    border:none;
    background:none!important;
}

.ui.modal.regularModal{
    max-width:576px!important;
    padding-bottom:30px;
}
.ui.modal.regularModal .actions{
    padding-top:15px;
}

.ui.modal.modalLayout > .actions .ui.button.floated.left{
    margin:0;
}
.paginationBar{
    width:100%; float:left;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
}

.displayText{
    position:absolute;
    right:75px;
    top:7px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.displayText strong{
    font-weight:600;
}

.ui.pagination.menu.paginationDashboard{
    background:none;
    box-shadow:none;
    border:none;
    min-height:0;
}

.ui.pagination.menu.paginationDashboard .item{
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    outline:0;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-right:none;
    min-width:0!important;
    width:33px;
    height:33px;
    padding:0;
    display:flex;
    align-items:center;
    justify-content:center;
}
.ui.menu.menu.paginationDashboard .item:before{
    display:none;
}
.ui.pagination.menu.paginationDashboard .item:first-child{
    border-radius:4px 0 0 4px;
    font-size:14px;
}
.ui.pagination.menu.paginationDashboard .item:last-child{
    border-radius:0 4px 4px 0;
    border-right:solid 1px #D3D2DE;
    font-size:14px;
}

.ui.menu.menu.paginationDashboard .item.disabled{
    background-color: transparent!important;
    color: rgba(40,40,40,.3)!important;
}
.ui.menu.menu.paginationDashboard .active.item{
    background:#727AFF;
    border-color:#727AFF;
    color:#fff;
    padding:2px 0 0!important;
}

.paginationBar .ui.dropdownGroup{
    min-width:0!important;
    min-height:0!important;
    max-width:65px;
    margin:0;
    position:absolute;
    right:0;
    border-radius:3px!important;
    border:solid 1px #D3D2DE!important;
    background:#fff!important;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0 10px;
}
.paginationBar .ui.dropdownGroup >.text{
    margin:4px 0 0 5px;
}
.paginationBar .ui.dropdownGroup > .icon{
    margin:5px 8px 0 0!important;
}
.fixedHeight {
    min-height: 500px;
}

.marginTop {
    margin-top: 2rem;
}

.scroll {
    min-height: 170px;
    max-height: 170px;
    width: 100%;
    padding: 0 0.5rem 0 0;
    /* ↓ was pushing the widgets out of window ↓ */
    overflow-y: auto;
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 220px;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 0 0 5%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .scroll::-webkit-scrollbar {
    display: none;
} */

.infoSeperators {
    display: flex;
}
.csvModalCheckbox{
    position: absolute !important;
    right: 3rem;
}
h3.ui.header {
    width: 100%;
    float: left;
    font: 500 15px 'Rubik', sans-serif;
    color: #000;
}

.containerFluid {
    width: 100%;
    max-width: 1366px;
    padding: 0 35px;
    margin: 0 auto;
}

.containerFluidMap {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding: 0px 35px;
}


.ui.button.buttonIcon.triggerUserFlow {
    width: 100%;
    float: left;
    height: 47px;
    padding: 0 10px 0 0;
    background: #F4F7FC;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ui.button.buttonIcon.triggerUserFlow img {
    transform: rotate(180deg);
}

.collapsedUserFlowSidebar .ui.button.buttonIcon.triggerUserFlow img {
    transform: rotate(0deg) !important;
}

.ui.button.buttonIcon.triggerUserFlow:hover,
.ui.button.buttonIcon.triggerUserFlow:focus {
    background: #F4F7FC !important;
}

.ui.dropdown .menu .active.item {
    font-weight: 500;
}

.userFlowContainer .overviewHead.overviewReport {
    border-bottom: solid 1px #D3D2DE;
}

.userFlowContainer .overviewHeadLeft {
    border-bottom: none;
    padding-left: 35px;
}

.userFlowTableSidebar {
    background: #fff;
    min-width: 232px;
    width: 232px;
    padding: 0;
    border:solid 1px #D3D2DE;
}

.ui.button.buttonIcon.triggerUserFlow {
    justify-content: flex-end;
}

.collapsedUserFlowSidebar {
    min-width: 80px !important;
    width: 80px !important;
}

.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup {
    padding: 10px 14px;
}
.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup > a{
    width:100%;
    float:left;
}

.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup .ui.button {
    display: flex;
    text-indent: -99999px;
    justify-content: center;
    height: 50px;
}

.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup .ui.button img {
    margin: 0;
    width: 20px;
}
.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup .ui.button.iconBoostInfo img{width:13px;}

.collapsedUserFlowSidebar .ui.button.buttonIcon.triggerUserFlow {
    justify-content: center;
    padding: 0;
}


.ui.popup {
    border: solid 1px #D3D2DE;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font: 400 13px/16px 'Rubik', sans-serif;
    color: #000;
}

.ui.popup a:hover {
    text-decoration: underline;
}

.ui.popup.infoPopup {
    width: 300px;
}

.ui.infoButton {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #D3D2DE !important;
    display: inline-block;
    cursor: pointer;
}

.ui.infoButton img {
    float: left;
    margin: 2px 0 0 4px;
}

.flexibleRow {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    margin: 0 0 15px;
}

.flexibleRow p {
    margin: 0;
}

.gapBetween p {
    max-width: 45%;
}

.gapBetween p:first-child {
    margin-right: auto;
}

.ui.button {
    min-width: 0;
    min-height: 0;
}

.ui.buttons .ui.button {
    padding: 0;
    min-width: 30px;
    min-height: 30px;
}

.ui.positive.button, .ui.negative.button, .ui.secondary.button, .ui.primary.button {
    width: auto;
    min-width: 100px;
    min-height: 36px;
    border-radius: 4px;
    font: 600 13px 'Rubik', sans-serif !important;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.positive.button {
    background: #29AD73 !important;
}

.ui.secondary.button {
    background: #969DB8 !important;
}

.ui.primary.button {
    background: #F15944 !important;
}

.ui.negative.button {
    background: #C73F3F!important;
}

.ui.basic.primary.button{
    background:none!important;
    border:solid 1px #F15944!important;
    box-shadow:none!important;
    color:#F15944!important;
}

.ui.button.buttonLink {
    margin: 0;
    padding: 0;
    background: none !important;
    color: #333 !important;
    font: 400 13px 'Rubik', sans-serif;
}

.ui.button.buttonLink:hover {
    text-decoration: underline;
}

.ui.closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    min-width: 0 !important;
    width: 17px;
    height: 17px !important;
    padding: 0 !important;
    background: none !important;
    opacity: 1;
    cursor: pointer;
    z-index: 10;
}

.ui.closeBtn:active {
    box-shadow: none !important;
}

.ui.closeBtn:before,
.ui.closeBtn:after {
    position: absolute;
    left: 7px;
    top: 0;
    content: '';
    height: 17px;
    width: 2px;
    background-color: #7a82ff;
}

.ui.closeBtn:before {
    transform: rotate(45deg);
}

.ui.closeBtn:after {
    transform: rotate(-45deg);
}

.ui.closeBtn:hover {
    opacity: 0.8;
}

.ui.buttonGroup {
    display: inline-flex;
    margin: 0 10px;
}

.ui.buttonGroup .ui.button {
    background: #fff;
    border: solid 1px #D3D2DE;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.buttonGroup .ui.button .icon {
    margin: 0 !important;
}

.ui.buttonGroup>.ui.button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: solid 1px #D3D2DE;
}

.ui.buttonGroup>.ui.button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.ui.buttonGroup>div .ui.button {
    border-radius: 0;
}

.ui.buttonGroup>div:first-child .ui.button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: solid 1px #D3D2DE;
}

.ui.buttonGroup>div:last-child .ui.button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.ui.vertical.buttonGroup {
    width: 100%;
}

.ui.vertical.buttonGroup .ui.button {
    border: solid 1px #D3D2DE;
    border-bottom: none;
    border-radius: 0;
    justify-content: flex-start;
}

.ui.vertical.buttonGroup .ui.button:first-child {
    border-radius: 4px 4px 0 0;
}

.ui.vertical.buttonGroup .ui.button:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: solid 1px #D3D2DE;
}

.flexible {
    display: flex;
    align-items: flex-start;
}

.p {
    font: 400 15px/24px 'Rubik', sans-serif;
    margin: 0 0 20px;
}

.ui.tooltipPopup {
    white-space: nowrap;
    padding: 8px 10px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, .2);
    font: 400 13px/18px 'Rubik', sans-serif;
}
.ui.popup.tooltipPopup.positionPopup{
    left:-15px!important;
}

.ui.tooltipPopup:before {
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, .1);
}

.ui.left.center.popup:before {
    box-shadow: 1px -1px 0 0 rgba(0, 0, 0, .1);
}

/*** default boxes ***/

.defaultBoxCover {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    margin-bottom: 30px;
}

.defaultBoxCoverIn,
.defaultBox {
    flex: 1 1;
    max-width: 632px;
    min-width:45%;
}

.defaultBoxCover:not(.defaultCoverAlternate) .defaultBox{
    margin:0 0 30px!important;
}

.defaultBoxCoverIn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.alignedStart {
    display: inline-block;
}

.alignedStart .defaultBox {
    float: left;
}

.defaultBox {
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 20px 25px;
    position: relative;
}

.defaultBoxCoverIn .defaultBox {
    max-width: 100%;
    margin-bottom: 30px;
}

.defaultBoxCoverIn .defaultBox:last-child {
    margin-bottom: 0;
}

.defaultBox>.ui.header {
    width: 100%;
    float: left;
    font: 500 15px 'Rubik', sans-serif;
    color: #000;
    border-bottom: solid 1px #D3D2DE;
    padding: 0 0 4px;
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    position: relative;
}

.heatmapWidget.defaultBox>.ui.header {
    padding: 0 20px 4px 0;
}

.defaultBox>.ui.header>span.subHeader {
    font-weight: 400;
    color: #667581;
    display: inline-flex;
    align-items: center;
}

.defaultBox>.ui.header>span.subHeader:first-child:before {
    content: "|";
    font-size: 12px;
    margin: 0 8px;
    color: #D3D2DE;
}

.defaultBox>.ui.header>span.subHeader:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 10px;
    background: url(/img/icon-arrow-right-grey.svg) no-repeat center;
    margin: 0 10px;
}

.defaultBox>.ui.header>span.subHeader:nth-last-child(2):after {
    display: none;
}

.tagLabel {
    background: #f3f6fb;
    font: 400 12px 'Rubik', sans-serif;
    border-radius: 2px;
    padding: 3px 5px;
    margin: 0 10px 0 0;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.tagLabel .ui.buttonIcon {
    padding: 0;
    margin: 0 0 0 10px !important;
}

.tagLabel .ui.buttonIcon img {
    width: 10px;
}

.defaultBox>.ui.header>span.subHeader .tagLabel:not(:last-child) {
    margin-right: 30px;
}

.defaultBox>.ui.header>span.subHeader .tagLabel:not(:last-child):after {
    content: '';
    background: url(/img/icon-arrow-right-grey.svg) no-repeat center;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -20px;
}
.defaultBoxInfoPopUp{
    margin-right: auto;
}
.HeatMapBoxContainer{
    height: 100vh;
    width: 100vw;
}

.ui.image.infoButton {
    width: 15px !important;
    height: 15px !important;
    margin: 0 0 0 1rem;
    margin-right: auto !important;
    min-width: 0;
}

.ui.image.infoButton img {
    margin: 2.5px 0 0 4.5px;
    width: 6px;
}

/* .ui.popup.headerInfoPopup {
    left: auto !important;
    right: 0 !important;
    top: 16px;
} */

.triggerAppTooltip {
    width: 12px !important;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    margin: 0 !important;
}
.triggerWebTooltip {
    width: 18px !important;
    position: absolute;
    right: 30px;
    top: 8px;
    cursor: pointer;
    margin: 0 !important;
}
.defaultBox .triggerWebTooltip{
    top: 5px;
    right: 5px;
}

.ui.popup.appOnlyTooltip {
    white-space: nowrap;
}
.ui.popup.webOnlyTooltip {
    white-space: initial;
}

.bodyDefaultBox {
    width: 100%;
    height: 100%;
    float: left;
}

.zoneBodyCover {
    width: 100%;
    float: left;
}

.para {
    width: 100%;
    float: left;
    margin: 0 0 15px;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
}

.para-under {
    width: 100%;
    float: left;
    margin: 20px 0 0;
    font: 400 13px 'Rubik', sans-serif;
    position: relative;
    color: #667581;
}

.flexible-under {
    display: flex;
    align-items: center;
}

.ui.button.resetButton {
    padding: 5px 10px;
    border-radius: 2px;
    min-width: 54px;
    margin: 0 10px 0 0;
    font: 500 12px 'Rubik', sans-serif;
    color: #727AFF;
    border: solid 1px #727AFF;
    background: none;
}

.para strong {
    font-weight: 500;
    color: #000;
}

.para.dark {
    color: #000;
}

.ui.chartDefault {
    width: 100%;
    float: left;
    text-align: center;
}

.ui.chartDefault img {
    display: inline-block;
}

/** Chart info color **/

.chartInfoCover {
    width: 100%;
    float: left;
}

.chartInfoColorItem {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
}

.chartInfoColorItem>small {
    width: 40px;
    height: 10px;
    background: #667581;
    margin: 0 12px 0 0;
}

.chartInfoColorItem>span {
    margin-left: auto;
    color: #969DB8;
}

/*** comparison ***/

.compareCover {
    width: 100%;
    float: left;
    display: flex;
}

.compareColumn {
    flex: 1 1;
}

.compareColumn:first-child {
    padding-right: 20px;
    border-right: solid 1px #D3D2DE;
}

.compareColumn:last-child {
    padding-left: 20px;
}

.compareColumn h3.ui.header {
    margin: 20px 0 0;
}

.compareColumn .defaultBox {
    float: left;
    width: 100%;
    margin: 0 0 30px;
}

.compareColumn .overviewDetail {
    flex-wrap: wrap;
    margin-bottom: 0;
}

.compareColumn .overviewDetailBox {
    min-width: 300px;
    margin: 0 0 30px;
}

.compareColumn .headingCover:before {
    content: '';
    width: 20px;
    height: 1px;
    background: #D3D2DE;
    position: absolute;
    bottom: -1px;
    right: -20px;
}

.compareColumn:last-child .headingCover:before {
    bottom: -1px;
    right: auto;
    left: -20px;
}

.boxScroll {
    width: 100%;
    float: left;
    overflow-x: auto;
}

.compareColumn .boxScroll,
.scrolledBox .boxScroll {
    max-width: 577px !important;
}

.compareColumn .boxScroll .ui.chartDefault img,
.scrolledBox .boxScroll .ui.image img {
    max-width: 10000px;
}

.compareColumn .defaultBox.independent,
.scrolledBox {
    padding-bottom: 5px;
}

/** set browser compatibility later **/

.boxScroll::-webkit-scrollbar-track {
    background: none;
}

.percentagePara {
    width: 100%;
    float: left;
    margin: 0 0 20px;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.percentagePara .icon {
    font-size: 12px;
    margin-left: 7px;
}

.percentagePara.arrowup {
    color: #29AD73;
}

.percentagePara.arrowdown {
    color: #F15944;
}

.bodyWrap {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
}

/*** direction ***/

.directionUpdate .bodyScroll {
    direction: ltr;
}

.directionUpdate .bodyScroll .containerFluid {
    direction: rtl;
}

.directionUpdate .userRetentionTable .retentionTableCover {
    direction: rtl;
}

.directionUpdate {
    direction: rtl;
}

.directionUpdate .onMoveActions {
    right: auto;
    left: 25px;
}

.directionUpdate .chartInfoCover.vanueCover {
    float: right;
}

@media (min-width: 1200px) {
    .directionUpdate .vanueCover {
        margin-right: 0 !important;
        margin-left: 40px !important;
    }
}

.directionUpdate .chartInfoColorItem>span {
    margin-left: 0;
    margin-right: auto;
}

.directionUpdate .peakHoursRow .peakHoursItem:first-child {
    padding-left: 0;
    padding-right: 15px;
}

.directionUpdate .boxProdiver>.ui.image {
    margin: 0 0 0 30px;
}

.directionUpdate .ui.dropdown.propertyDropdown {
    margin: 0 0 0 10px;
    padding: 0 10px 0 0;
}

.directionUpdate .ui.dropdown.propertyDropdown:first-child {
    padding: 0;
    margin: 0;
}

.directionUpdate .ui.dropdown.propertyDropdown>img {
    margin: 0 0 0 11px;
}

.directionUpdate .ui.dropdown .menu>.item {
    text-align: right;
}

.directionUpdate .propertyComparison .ui.button.buttonIcon {
    border-radius: 0 3px 3px 0;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox {
    border-radius: 3px 0 0 3px;
    border-right: none;
    border-left: solid 1px #D3D2DE;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox label:before {
    margin: 0 0 0 8px;
}

.directionUpdate .propertyComparison {
    margin: 0 20px;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox input~label:after,
.directionUpdate .propertyComparison .ui.toggle.checkbox input:checked~label:after {
    left: auto;
    transition: all 0.3s ease;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox input~label:after {
    right: -.05rem;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox input:checked~label:after {
    right: 20px;
}

.directionUpdate .overviewMenu li {
    margin: 0 38px 0 0;
}

.directionUpdate .reportsMenu li {
    margin: 0 0 0 18px;
}

.directionUpdate .overviewHeadRight {
    padding: 0 15px 0 0;
}

.directionUpdate .ui.dropdownGroup.exportDropdown {
    margin: 0 30px -10px 0;
}

.directionUpdate .ui.dropdownGroup.exportDropdown .menu {
    right: auto;
    left: 0;
}

.directionUpdate .filterDetailItem {
    margin-right: 20px;
}

.directionUpdate .filterDetail .textGroup:first-child {
    margin-right: 0;
}

.directionUpdate .ui.dropdown.filterDropdown .menu>.item,
.directionUpdate .ui.button.dropdown .menu,
.directionUpdate .ui.dropdown.filterDropdown .menu .input>input {
    text-align: right;
}

.directionUpdate .ui.dropdownGroup>.icon {
    float: left !important;
}

.directionUpdate .ui.dropdown>.text {
    float: right;
}

.directionUpdate .headingLine>.ui.infoButton {
    margin-left: 0;
    margin-right: 15px;
}

.directionUpdate .headingCover>.ui.basic.buttonIcon {
    margin-left: 0;
    margin-right: auto;
}

.directionUpdate .headingLine .ui.basic.buttonIcon {
    margin: 0 0 0 3px !important;
}

.directionUpdate .headingLine .ui.basic.buttonIcon img {
    margin: 0 0 0 5px;
}

.directionUpdate .overviewDetailBox .ui.infoButton {
    right: auto;
    left: 9px;
}

.directionUpdate .overviewDetailBox>img.ui.image {
    margin: 5px 0 0 15px;
}

.directionUpdate .contentDetailBox b {
    display: flex;
    align-items: flex-end;
}

.directionUpdate .contentDetailBox b span {
    margin: 0 5px 4px 0;
}

.directionUpdate .percentageDetailBox .icon {
    margin: 0 3px 0 0;
}

.directionUpdate .chartInfoColorItem>small {
    margin: 0 0 0 12px;
}

.directionUpdate .userRetentionColumn {
    padding: 5px 8px 5px 0;
}

.directionUpdate .userRetentionColumn {
    border-right: none;
    border-left: solid 1px #D3D2DE;
}

.directionUpdate .userRetentionColumn:first-child {
    left: auto;
    right: 0;
    padding-right: 12px;
}

/* direction compare */

.directionUpdate .compareColumn:first-child {
    padding: 0 0 0 20px;
    border-right: none;
    border-left: solid 1px #D3D2DE;
}

.directionUpdate .compareColumn:last-child {
    padding: 0 20px 0 0;
}

.directionUpdate .compareColumn:last-child .headingCover:before {
    left: auto;
    right: -20px;
}

.directionUpdate .compareColumn .headingCover:before {
    right: auto;
    left: -20px;
}

/*** spacings ***/

.gap0 {
    margin: 0 !important;
}

.smallMessage {
    display: inline-block;
    margin: 15px 0;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.smallMessage.error {
    color: #F15944;
}

.height100{
    height: 100% !important;
}

/***/

.ui.vertical.buttonGroup.userflowGroup .ui.button {
    min-height: 50px;
    padding: 5px 10px;
    margin: 0 0 20px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    position: relative;
    text-align: left;
    background: #F3F6FB;
    border: solid 1px #C0CBDA;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.ui.vertical.buttonGroup.userflowGroup .ui.button.active {
    font-weight: 500;
    border-color: #F15944;
}

.ui.vertical.buttonGroup.userflowGroup .ui.button img {
    margin: 0 10px 0 0;
}

.overviewHead.dashboardOverviewHead{
    border-bottom:solid 1px #D3D2DE;
    padding-left:0;
    padding-right:0;
}
.dashboardOverviewHead .overviewHeadLeft,
.dashboardOverviewHead .overviewHeadRight{
    border:none;
    padding:0;
}

/*  user retention table */

.userRetentionTableCover {
    width: 100%;
    float: left;
    margin: 20px 0 0;
    padding: 20px 0 0;
    position: relative;
}

.userRetentionTableCover:before,
.userRetentionTableCover:after {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 1px #D3D2DE;
    position: absolute;
    top: 0;
}

.userRetentionTableCover:before {
    left: -25px;
}
.html2canvas-container { width: 1920px !important; height: 2300px !important; }

.userRetentionTableCover:after {
    right: -25px;
}

.userRetentionTable {
    width: 100%;
    float: left;
    border-radius: 2px;
    border: solid 1px #D3D2DE;
    border-top: none;
    background: none;
    position: relative;
}

.userRetentionTable:before,
.userRetentionTable:after {
    content: '';
    width: 1px;
    height: 25px;
    background: #fff;
    position: absolute;
    left: -1px;
    top: 0;
    z-index: 2;
}

.userRetentionTable:after {
    left: auto;
    right: -1px;
}

.userRetentionRow {
    min-width: 100%;
    float: left;
    border-top: solid 1px #D3D2DE;
    display: flex;
}

.userRetentionRow:first-child {
    border-top: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}

.userRetentionRow:last-child {
    border-radius: 0 0 2px 2px;
    border-bottom: none;
}

.userRetentionColumn {
    flex: 1 1;
    min-width: 70px;
    max-width: 70px;
    min-height: 50px;
    padding: 5px 0 5px 8px;
    border-right: solid 1px #D3D2DE;
    display: flex;
    align-items: center;
}

.userRetentionRow:last-child .userRetentionColumn {
    padding-bottom: 10px;
    min-height: 53px;
}

.userRetentionColumn:last-child {
    border: none !important;
}

.userRetentionColumn:first-child {
    min-width: 185px;
    max-width: 185px;
    width: 185px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background: #fff;
}

.userRetentionRow:first-child .userRetentionColumn {
    font: 400 12px 'Rubik', sans-serif;
    color: #667581;
    min-height: 20px;
    border: none;
}

.userRetentionRow .userRetentionColumn:first-child b {
    font-weight: 500;
    letter-spacing: -.2px;
}

.userRetentionRow .userRetentionColumn.active {
    background: #EEEEEE;
}

.userRetentionColumn,
.userRetentionColumn p {
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.userRetentionColumn b span,
.userRetentionColumn p span {
    display: block;
    color: #667581;
    font-weight: 400;
    font-size: 13px;
}

.tableScroll>div:last-child {
    top: 47px !important;
}

.retentionTableCover {
    width: 100%;
    float: left;
    position: relative;
}


.alignedWidget {
    display: flex;
    flex-direction: column;
}

.alignedWidget .bodyDefaultBox {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.alignedWidget .percentagePara {
    flex: 1 1;
}

body {
    background:#F3F6FB !important;
}

.boost-bar{
    height:calc(100vh - 218px)!important;
    border-left:solid 1px #D3D2DE;
}
.bodyScroll > div:nth-last-child(2){ 
    display:none;
}

.tableViewBody .ui.placeholder .image:before{
    display:none!important;
}
.boost-bar > .placeholderBox{
    height:calc(100vh - 222px)!important;
}

.displayNone{
    display: none !important;
}
.durationBox{
    position: relative;
}
.durationBox .hhmmss_formated_text{
    /* position: absolute;
    left: 10rem; */
    font-size: 10px;
    font-weight: 600;
    
}
.ui.input.widgetSearch>input{
    height: 30px;
    padding: 0 10px!important;
    border: solid 1px #D3D2DE!important;
    border-radius: 3px!important;
    font: 400 13px 'Rubik',sans-serif!important;
    color: #000!important;
}
.ExportDropDown{
    width: auto;
    border-radius: 0;
    padding: 2px;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
    color: #727AFF !important;
    font: 400 15px 'Rubik', sans-serif;
    display: inline-flex;
    align-items: center;
}
@media (max-width:1350px){
    /* .defaultBoxCoverIn, .defaultBox{
        max-width:48%;
    } */
    .compareColumn .defaultBox{
        max-width:100%;
    }
    .peakHoursItem:last-child{
        min-width:0;
    }
    .compareColumn{
        max-width:50%;
    }
    .compareColumn .overviewDetailBox{
        min-width:48%;
        max-width:48%;
        padding:20px 10px 25px;
    }
}

@media (max-width:1300px){
    .overviewDetailBox{
        padding:20px 10px 25px;
        max-width:280px;
    }
}

@media (max-width:1200px){
    .overviewDetailBox{
        max-width:250px;
    }

    .overviewReport .overviewMenu li{
        margin:0 0 0 15px;
    }

    .reportsMenu li{
        margin:0 10px 0 0!important;
    }
    
    
}

@media (max-width:1100px){
    .overviewHead{
        display:inline-block;
    }
    .overviewHeadLeft,
    .overviewHeadRight{
        width:100%;
        float:left;
        padding:0;
        margin:0 0 10px;
    }
    .overviewHeadRight{
        text-align:right;
    }
    
    .overviewDetailBox{
        max-width:220px;
    }
    .propertyComparison{
        margin:0 6px;
    }

    .sliderTag{
        width:calc(26vw);
    }
    .filterDetail{
        flex-wrap:wrap;
        justify-content:space-between;
    }
    .filterDetail .textGroup{
        max-width:30%;
        margin:0 0 20px;
    }
    .filterDetail .ui.button.buttonLink{
        margin:0 10px 0 0;
        padding:10px 15px;
    }
    .filterDetail .ui.positive.button{
        padding:0 40px;
    }
}

@media (max-width:991px){
    .subheader .ui.container{
        margin:0!important;
    }
    .propertyComparison{
        width:155px;
    }
    .propertyComparison .ui.toggle.checkbox{
        font:500 12px/11px 'Rubik',sans-serif;
    }
    .ui.dropdown.propertyDropdown{
        max-width:20%;
    }


    .overviewMenu li{
        margin-left:15px;
    }

    .directionUpdate .overviewMenu li{
        margin:0 15px 0 0;
    }

    .overviewDetail{
        justify-content:space-evenly;
        flex-wrap:wrap;
    }
    .overviewDetailBox{
        min-width:45%;
        max-width:100%;
        margin:0 10px 20px;
    }
    
    .compareColumn .overviewDetailBox{
        min-width:100%;
        max-width:100%;
        margin:0 0 20px;
    }
}

@media (max-width:910px){
    .overviewReport .overviewHeadLeft{
        display:inline-block;
        border:none;
    }
    .overviewReport .overviewMenu{
        border-bottom:solid 1px #D3D2DE;
        flex-wrap:wrap;
        justify-content:center;
    }
    .overviewReport .overviewMenu li{
        margin:0 15px 0 0;
    }
    .overviewReport .overviewMenu li a{
        height:40px;
        padding:10px 0 0;
    }



    .defaultBoxCover{
        display:inline-block;
        margin:0;
    }
    .defaultBoxCoverIn, .defaultBox{
        max-width:100%;
        width:100%; float:left;
        margin:0 0 30px;
    }
    .scrolledBox .boxScroll{
        max-width:1000px!important;
    }
}

@media (max-width:768px){
    .overviewHead{
        display:inline-block;
    }
    .overviewHeadLeft,
    .overviewHeadRight{
        width:100%;
        float:left;
        padding:0;
        margin:0 0 10px;
    }
    .overviewHeadRight{
        text-align:right;
    }
    
    .directionUpdate .overviewHeadRight{
        padding-right:0;
    }

    .sliderTag{
        width:calc(80vw);
    }

    .filterDetail{
        justify-content:initial;
    }

    .filterDetail .textGroup{
        max-width:100%;
    }

    .filterDetailItem{
        justify-content:space-between;
        width:100%;
    }
    .filterDetailItem .ui.button{
        flex:1 1;
        max-width:46%;
        margin:0;
    }
    .filterDetail .ui.button.buttonLink{
        background:#ccc!important;
    }
    .filterDetail .ui.positive.button{
        padding:0;
    }
    .flexibleRow,
    .repeatCover{
        display:inline-block;
    }
    .gapBetween p,
    .repeatCover .chartInfoColorItem{
        max-width:100%;
        width:100%; float:left;
        margin:0 0 10px;
    }

    .headingLine .ui.basic.buttonIcon{
        text-align:left;
    }
    .directionUpdate .headingLine .ui.basic.buttonIcon{
        text-align:right;
    }
}

@media (max-width:568px){
    .overviewDetailBox{
        min-width:100%;
        margin:0 0 20px;
    }
    .sliderTag{
        width:calc(74vw);   
    }
}
.defaultBox.independent {
    max-width: 100%;
    width: 100%;
    float: left;
    margin: 0 0 25px;
}

.defaultBox.onMove {
    border: dashed 1px #727aff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    /* delete later*/
    top: 20px;
    left: 20px;
    z-index: 10;
    cursor: move;
}

.onMoveActions {
    width: auto;
    position: absolute;
    right: 25px;
    top: 15px;
    display: flex;
    align-items: center;
}

.onMoveActions .ui.basic.buttonIcon {
    margin-left: 5px;
}

.defaultBox.isEmpty {
    background: none;
    border: dashed 1px #969db8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.widgetCover {
    flex: 1 1;
    max-width: 632px;
    min-width: 45%;
    min-height: 300px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.widgetCover .defaultBox {
    width: 100%;
}

.widgetCover span {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
    margin: 10px 0 0;
}

.defaultBox.isEmpty .widgetCover {
    justify-content: center;
}

.defaultCoverAlternate {
    margin: 0;
}

.defaultCoverAlternate .defaultBox {
    margin: 0 0 30px;
    cursor: move;
}

.ui.basic.button.addButton,
.ui.basic.button.addButton:hover,
.ui.basic.button.addButton:focus {
    width: 50px;
    height: 50px;
    border-radius: 2px !important;
    background: #fff !important;
    position: relative;
}

.ui.basic.button.addButton:before,
.ui.basic.button.addButton:after {
    content: "";
    position: absolute;
    border-radius: 1px;
    background: #727aff;
}

.ui.basic.button.addButton:before {
    width: 16.5px;
    height: 2px;
    top: 24px;
    left: 17px;
}

.ui.basic.button.addButton:after {
    width: 2px;
    height: 16.5px;
    top: 17px;
    left: 24px;
}

/*** charts ***/

.vanueCover {
    width: 100%;
    float: left;
    margin: 0 0 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.vanueCover .chartInfoColorItem {
    flex-basis: 45%;
}

.vanueCover .chartInfoColorItem:first-child {
    order: 1;
}

.vanueCover .chartInfoColorItem:nth-child(2) {
    order: 3;
}

.vanueCover .chartInfoColorItem:nth-child(3) {
    order: 5;
}

.vanueCover .chartInfoColorItem:nth-child(4) {
    order: 7;
}

.vanueCover .chartInfoColorItem:nth-child(5) {
    order: 2;
}

.vanueCover .chartInfoColorItem:nth-child(6) {
    order: 4;
}

.vanueCover .chartInfoColorItem:nth-child(7) {
    order: 6;
}

.vanueCover .chartInfoColorItem:last-child {
    order: 8;
}

.heatmapWidget .bodyDefaultBox {
    position: relative;
}

.ui.buttonIcon.heatMapTrigger {
    position: absolute;
    right: 0;
    top: -33px;
}

.ui.buttonIcon.heatMapTrigger img {
    width: 14px;
}

.mapWrapper {
    height: 100%;
    width: 100%;
}

.mapWrapperUserExplorer {
    flex: 1 1;
    height: calc(100vh - 111px);
}

.mapWrapper .ui.placeholder.placeholderCover .image:before {
    display: none;
}

.heatmapWidgetMinimized {
    height: 100% !important;
}

.heatMapWidgetMaximized {
    background: #fff;
    width: 100%;
    height: calc(100vh - 110px);
    position: fixed;
    top: 109px;
    left: 0;
    z-index: 3;
}

.heatMapWidgetMaximized .defaultBox .floorButtonsCover {
    margin-right: 7px;
}

.heatMapWidgetMaximized .defaultBox {
    max-width: 100%;
}

.heatMapWidgetMaximized:before {
    content: "";
    width: 100%;
    height: 5px;
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 4;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
}

.heatMapWidgetMaximized .ui.button.buttonIcon {
    position: absolute;
    right: 15px;
    top: -30px;
}

.heatMapWidgetMaximized .toggleSliderBox {
    top: 80px;
}

.heatMapWidgetMaximized .floorButtonsCover {
    top: 125px;
    right: 257px;
    z-index: 10;
}

.floorButtonsCover.navActionsCover {
    padding: 26px 0;
}

.heatMapWidgetMaximized .toggleSliderBox {
    top: 80px;
    right: 257px;
}

.heatMapWidgetFit {
    height: 100%;
    width: 100%;
}

.heatMapWidgetFit .floorButtonsCover {
    right: 257px;
    z-index: 10;
}

.heatMapWidgetFit .floorButtonsCover.navActionsCover {
    padding: 26px 0;
}

.heatMapWidgetFit .toggleSliderBox {
    right: 257px;
}

.repeatCover {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
}

.repeatCover .chartInfoColorItem {
    width: auto;
    margin: 0 15px;
}

.peakHoursTable,
.peakHoursRow,
.peakHoursItem {
    width: 100%;
    float: left;
}

.peakHoursTable {
    margin: 5px 0 0;
}

.peakHoursRow {
    min-height: 38px;
    display: flex;
    align-items: center;
    border: solid 1px #d3d2de;
    border-radius: 1px;
    margin: 0 0 5px;
}

.peakHoursItem {
    flex: 1 1;
    min-width: 100px;
    font: 400 15px "Rubik", sans-serif;
    color: #000;
}

.peakHoursItem.tooltip {
    position: relative;
}

.peakHoursItem:last-child {
    text-align: center;
    flex: 2 1;
    min-width: 200px;
}

.tooltip .peekHoursColumnToolTipText {
    display: none;
    position: absolute;
    bottom: 100%;
    background: #fff;
    padding: 5px 10px;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: solid 1px #d3d2de;
    font: 400 12px "Rubik", sans-serif;
    white-space: nowrap;
}

.tooltip:hover .peekHoursColumnToolTipText {
    display: block;
}

.peakHoursRow:first-child {
    min-height: 0;
    border: none;
    padding: 1px;
}

.peakHoursRow:first-child .peakHoursItem {
    font: 400 12px "Rubik", sans-serif;
    color: #667581;
}

.peakHoursRow .peakHoursItem:first-child {
    padding-left: 15px;
}

.peakHoursRow .peakHoursItem:first-child .peakHoursColumn {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.peakHoursItem img {
    margin: 3px 0 0;
}

.peakHoursRow .peakHoursItem:nth-child(2),
.peakHoursRow .peakHoursItem:nth-child(3) {
    text-align: center;
}

.peakHoursRow .peakHoursItem:nth-child(2) {
    min-width: 94px;
    width: 100px;
    max-width: 100px;
}

.boxProdiver>.ui.image {
    margin-right: 30px;
}

.boxProdiver .chartInfoCover {
    max-width: 250px;
}

.boxProdiver .chartInfoCover .chartInfoColorItem {
    margin-bottom: 4px;
}

.widgetCoverTime {
    width: 100%;
    float: left;
    height: 300px;
}

.widgetCoverTime>div:last-child {
    margin-top: -60px;
}

.widgetCoverTime>div:last-child>div>div svg:first-child {
    height: 270px;
}

.widgetCoverTime .flexibleRow {
    margin-bottom: -15px;
}

.heatmapWidget .bodyDefaultBox {
    width: 100%;
    height: 450px !important;
    position: relative;
}

.ui.checkbox {
    padding-bottom: 5px;
}

.toggletooltipCover {
    width: 100%;
    float: left;
    position: relative;
}

.toggleSliderCover {
    width: 100%;
    float: left;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    right: 1rem;
}

.toggleSliderCover .names,
.toggleSliderCover .style {
    width: auto;
}

.toggleSliderCover .names {
    margin: 0 0 10px;
}

/* .toggleSliderCover .style {
    margin: 0 10px 0 0;
} */

.toggleSliderCover .overlays {
    margin: 0 0 10px;
}

.bodyDefaultBox .toggleSliderCover .names,
.traceTableViewBody .toggleSliderCover .names,
.alertsContainer .toggleSliderCover .names,
.bodyDefaultBox .toggleSliderCover .overlays,
.traceTableViewBody .toggleSliderCover .overlays,
.alertsContainer .toggleSliderCover .overlays {
    /* margin-right: 10px; */
}


.heatMapWidgetFit .toggleSliderCover .style,
.boostAnalyticsBody .toggleSliderCover .style,
.heatMapWidgetMaximized .toggleSliderCover .style,
.PathAnalyticsMapContainer .toggleSliderCover .names,
.OccupancyMapContainer .toggleSliderCover .names,
.PathAnalyticsMapContainer .toggleSliderCover .overlays,
.OccupancyMapContainer .toggleSliderCover .overlays {
    margin-right: 0 !important;
}

.mapWrapperUserExplorer .toggleSliderCover .names,
.mapWrapperUserExplorer .toggleSliderCover .overlays {
    margin-right: 290px !important;
}

.toggleSliderCover .ui.toggle.checkbox {
    width: 36px;
    float: right;
    padding: 2px 0;
    min-height: 18px;
}

.toggleSliderCover .ui.toggle.checkbox {
    width: 36px;
    height: 18px;
}

.toggleSliderCover .ui.toggle.checkbox label {
    padding: 0;
    width: 36px;
}

.toggleSliderCover .ui.toggle.checkbox label {
    padding: 0;
    width: 36px;
}

.toggleSliderBox {
    width: 36px;
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 2;
}

.toggleSliderBox .ui.toggle.checkbox label:before,
.toggleSliderBox .ui.toggle.checkbox label:active:before,
.toggleSliderBox .ui.toggle.checkbox label:focus:before,
.toggleSliderBox .ui.toggle.checkbox input:checked~label:before,
.toggleSliderBox .ui.toggle.checkbox input:checked~label:active:before,
.toggleSliderBox .ui.toggle.checkbox input:checked~label:focus:before {
    background: #fff !important;
    width: 38px;
    height: 16px;
    border: solid 1px #d3d2de !important;
}

.toggleSliderCover .ui.toggle.checkbox label:after {
    background: #727aff;
    box-shadow: none !important;
    top: -1px !important;
    left: 0;
    width: 18px;
    height: 18px;
}

.toggleSliderCover .ui.toggle.checkbox input:checked~label:after {
    left: 21px;
}

.names .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/toggle-names-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.names .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/toggle-names-active.svg) no-repeat center #727aff;
    background-size: 11px auto;
    box-shadow: none !important;
}

.overlays .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/toggle-overlay-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.overlays .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/toggle-overlay-active.svg) no-repeat center #727aff;
    background-size: 11px auto;
    box-shadow: none !important;
}

.style .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/toggle-style-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.style .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/toggle-style-active.svg) no-repeat center #727aff;
    background-size: 10px auto;
    box-shadow: none !important;
}

.zoneGeofence .overlays {
    margin: 0 0 10px;
}

.zoneGeofence .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/icon-toggle-zone-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.zoneGeofence .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/icon-toggle-zone.svg) no-repeat center #727aff;
    background-size: 11px auto;
    box-shadow: none !important;
}

/*
.style .toggleSliderBox .ui.toggle.checkbox label:after {
    width: 30px;
    height: 30px;
    background: #fff;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    top: 4px !important;
}

.style .ui.toggle.checkbox input:checked~label:after {
    background: #007aff;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .4) !important;
    top: 4px !important;
} */

.toggleSliderBox .ui.toggle.checkbox label {
    text-indent: -99999999px;
    background: transparent !important;
}

.floorButtonsGroup,
.floorButtonsCover .ui.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.floorButtonsCover {
    position: absolute;
    right: 10px;
    top: 150px !important;
    z-index: 2;
}

.bodyDefaultBox .mapContainer .floorButtonsCover {
    padding: 28px 0;
}

.floorButtonsGroup {
    border-radius: 100px;
    overflow: hidden;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    margin: 5px 0;
}

.floorButtonsGroup .ui.active.button {
    color: #007aff;
}

.floorButtonsGroup .ui.button {
    width: 38px;
    height: 38px;
    font: 500 14px "Rubik", sans-serif;
    background: #fff !important;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    border-radius: 0 !important;
}

.floorButtonsGroup .ui.button.active {
    background: #ebf8fe !important;
    color: #727aff !important;
}

.ui.button.buttonIcon.floorsToggle {
    width: 26px;
    height: 26px;
    border-radius: 100px;
    background: #fff !important;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2) !important;
    position: absolute;
    top: 0;
    left: 6px;
}

.ui.button.buttonIcon.floorsToggle:hover,
.ui.button.buttonIcon.floorsToggle:focus {
    background: #fff !important;
}

.ui.button.buttonIcon.floorsToggle img {
    width: 12px;
}

.ui.button.buttonIcon.floorsToggle.next {
    top: auto;
    bottom: 0;
}

.ui.button.buttonIcon.floorsToggle.next img {
    transform: rotate(180deg);
    margin: 2px 0 0;
}

.floors-group {
    width: 34px;
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.floors-group .ui.basic.button {
    box-shadow: none;
    background: #fff;
    border: none !important;
    width: 34px;
    height: 34px;
    padding: 0;
    border-radius: 0;
    text-align: center;
    font: 500 13px/34px "Rubik", sans-serif;
    color: #333;
}

.floors-group .ui.basic.button.active {
    background: #ebf8fe;
    color: #727aff;
}

.floors-group .ui.basic.button:first-child {
    border-radius: 50px 50px 0 0;
}

.floors-group .ui.basic.button:last-child {
    border-radius: 0 0 50px 50px;
}

.ol-control.ol-collapsed {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding: 0 10px 5px;
}

.ol-control.ol-collapsed ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1 1;
    visibility: hidden;
}

.ol-control.ol-collapsed ul p {
    margin: 0 0 5px;
    font: 400 11px "Rubik", sans-serif;
    color: #000;
}

.ol-control {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding: 0 10px 5px;
}

.ol-control ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1 1;
}

.ol-control ul p {
    margin: 0 0 5px;
    font: 400 11px "Rubik", sans-serif;
    color: #000;
}

.UsedDevicesWidget {
    width: 100%;
    float: left;
    height: 250px;
}

.ChartWrapper {
    width: 100%;
    float: left;
    height: 250px;
}

.FullHeigthChartWrapper {
    width: 100%;
    float: left;
    height: 100%;
}

.tooltipWidgetRowCover p {
    font: 400 13px "Rubik", sans-serif;
}

.widgetsTooltipBox {
    width: 200px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    font: 400 14px "Rubik", sans-serif;
}

.widgetsTooltipBox>strong {
    display: block;
    margin: 0 0 10px;
}

.widgetsTooltipBox strong {
    font-weight: 500;
}

.WeatherWidgetsTooltipBox {
    width: 300px;
    max-width: 300px;
    background: #fff;
    z-index: 1000;
    padding: 0;
}

.WeatherWidgetsTooltipBox>div {
    background: #fff;
    float: left;
    width: 100%;
    min-height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #d3d2de;
    color: #667581;
    white-space: nowrap;
}

.WeatherWidgetsTooltipBox>div:first-child {
    margin: 10px 0 0;
}

.WeatherWidgetsTooltipBox>div:last-child {
    border: none;
    margin: 0 0 10px;
}

.WeatherWidgetsTooltipBox>div>span {
    margin-left: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
}

.WeatherWidgetsTooltipBox>div:first-child>img {
    margin: 0 5px 0 0;
}

.tooltipItemRow {
    display: inline-flex;
    align-items: center;
    margin: 0 0 5px;
    font: 400 13px "Rubik", sans-serif;
    color: #000;
    background-color: white;
    padding: 5px;
}

.tooltipItemRow:last-child {
    margin: 0;
}

.tooltipItemRow>span:first-child {
    min-width: 14px;
    width: 14px;
    height: 14px;
    margin: 0 5px 0 0;
}

.tooltipItemRow strong {
    margin-left: auto;
    font-weight: 500;
}

.tooltipItemRowDevice {
    white-space: nowrap;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.tooltipWidgetRowCover strong {
    margin-left: 5px;
}

.tooltipItemRowDevice strong {
    margin: 0 5px 0 10px;
}

.web-eng-search-tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0 5px 25px;
}

.web-eng-search-tooltip>div {
    width: 100%;
}

.web-eng-search-tooltip>div strong {
    margin-left: 0;
}

.web-eng-search-tooltip>span:first-child {
    position: absolute;
    left: 5px;
    top: 5px;
}

.tooltipItemProvider {
    white-space: pre;
    padding: 5px 10px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.poiWidget {
    background: #fff;
    padding: 6px 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

/* curated dashboard */
.temporarycover {
    background: #fff;
    width: 100%;
    float: left;
    height: calc(100vh - 64px);

    display: flex;
    align-items: center;
    justify-content: center;
}

.createDashboardBox {
    width: 100%;
    max-width: 820px;
    padding: 30px 40px 30px;
    border-radius: 2px;
    background: #fff;
    border: solid 1px #d3d2de;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.headingDashboardBox {
    width: 100%;
    float: left;
    font: 400 18px "Rubik", sans-serif;
    color: #000;
    padding: 0 0 10px;
    margin: 0 0 15px;
    border-bottom: solid 1px #d3d2de;
}

.tgCustomName {
    display: flex;
    align-items: center;
}

.tgCustomName>p {
    font: 400 15px "Rubik", sans-serif;
    width: auto;
    min-width: 200px;
}

.tgCustomName .ui.input input {
    height: 25px;
    border-radius: 0;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
}

.tgCustomName .ui.input input::placeholder {
    color: #666666;
}

.tgChooseTheme {
    margin: 0 0 25px;
}

.tgChooseTheme .textGroupContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
}

.ui.button.buttonThemeChoose {
    flex: 1 1;
    max-width: 360px;
    height: 80px;
    background: #fff !important;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000;
    font: 400 15px "Rubik", sans-serif;
    padding: 10px;
}

.ui.button.buttonThemeChoose:hover {
    border-color: #727aff;
}

.ui.button.buttonThemeChoose img {
    min-width: 60px;
    margin: 0 10px 0 0;
}

.themeChooseContent {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.themeChooseContent span {
    border-radius: 3px;
    background: #d3d2de;
    color: #37383c;
    font: 400 15px "Rubik", sans-serif;
    padding: 4px 10px;
    margin: 5px 0 0;
}

.ui.button.buttonThemeChoose.disabled {
    opacity: 1 !important;
    border-color: #e8e8ee;
}

.ui.button.buttonThemeChoose.disabled {
    color: #7e7e7e;
}

.subheaderDashboardThemeLeft {
    flex: 1 1;
}

.ui.button.editSubheaderPencil {
    margin: 0 10px !important;
}

.ui.button.editSubheaderPencil img {
    width: 16px;
    filter: saturate(10);
}

.editModeStatus {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
    margin: 0 10px;
}

.ui.button.primary.widgetSaveButton {
    min-width: 70px;
    margin: 0 15px 0 0;
}

.ui.dropdown.dropdownCustomDashboad {
    width: auto;
    border: none !important;
    margin: 0;
    display: flex;
    align-items: center;
}

.ui.dropdown.dropdownCustomDashboad>img:first-child {
    margin: 0 5px 0 0;
}

.ui.dropdown.dropdownCustomDashboad i.icon {
    margin: 0 0 0 10px !important;
    font-size: 17px;
}

.ui.button.buttonIcon.widgetDashboardButton {
    margin: 4px 0 0 15px;
    font: 400 14px "Rubik", sans-serif !important;
    color: #667581 !important;
    border-left: solid 1px #c0cbda !important;
    padding: 0 0 0 20px;
}

.ui.button.gobackButton {
    min-width: 60px;
}

.curatedSubheader {
    width: 100%;
    float: left;
    margin: 0 0 20px !important;
    display: flex;
    align-items: flex-end;
}

.curatedSubheaderLeft {
    flex: 1 1;
}

.curatedDashboardName {
    display: inline-flex;
    align-items: center;
    font: 400 14px "Rubik", sans-serif;
}

.curatedDashboardName .ui.input {}

.curatedDashboardName .ui.input>input {
    border-radius: 0;
    height: 25px;
    font: 400 15px "Rubik", sans-serif;
    border: none;
    background: none;
    padding: 0;
}

.curatedDashboardActions {
    display: inline-flex;
    align-items: center;
}

.curatedDashboardActions .ui.buttonIcon {
    margin: 0 0 0 10px !important;
}

.curatedSubheader>.ui.basic.button.primary {
    margin: 0 0 0 15px;
}

.curatedOverviewHead {
    width: 100%;
    padding: 10px 0 25px;
    margin: 0 0 25px;
    border-bottom: solid 1px #d3d2de;
    display: flex;
    justify-content: space-between;
}

.selectWidgetBox {
    flex: 1 1;
    max-width: 300px;
    height: 100px;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 15px;

    font: 400 15px "Rubik", sans-serif;
    color: #667581;
}

.selectWidgetBox img {
    margin: 0 15px 0 0;
    background: #fff;
}

.curatedWidgetCover {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.selectWidgetBoxBig {
    height: 395px;
    width: 100%;
    max-width: 47%;
    margin: 0 0 40px;

    border: solid 1px #d3d2de;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;

    font: 400 15px "Rubik", sans-serif;
    color: #667581;
}

.selectWidgetBoxBig img {
    margin: 0 0 15px;
}

.ui.header.headerWidgetModal {
    width: 100%;
    float: left;
    font: 600 15px "Rubik", sans-serif;
    display: flex;
    align-items: center;
    min-height: 28px;
}

.tabWidgetsCover {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.widgetsItemBox {
    flex: 1 1;
    min-width: 364px;
    max-width: 364px;
    height: 80px;
    background: #fff !important;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000;
    font: 400 15px "Rubik", sans-serif;
    padding: 10px;
    margin: 0 0 15px;
    position: relative;
}

.widgetsItemBox .checkboxGroup {
    width: auto;
    margin: 0 10px 0 0;
}

.widgetsItemBox:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.widgetsItemBox:not(.isSelected) {
    cursor: pointer;
}

.widgetsItemBox.isSelected {
    border-color: #727aff;
    box-shadow: inset 0 0 5px 0 #727aff, 0 0 12px rgba(0, 0, 0, 0.3);
}

.widgetsItemBox>.ui.image {
    min-width: 60px;
    margin: 0 10px 0 0;
    position: relative;
}

.widgetsItemBox>.ui.image .ui.green.right.corner.label {
    position: absolute;
    top: 0;
    right: 0;
}

.widgetsItemBox>.ui.image .ui.corner.label:after {
    border-right-width: 3em;
    border-bottom-width: 3em;
}

.widgetsItemBox>.ui.image .ui.green.right.corner.label i.icon {
    top: 4px;
    right: 4px;
    font-size: 13px;
}

.widgetsItemBoxEmpty {
    cursor: initial !important;
    box-shadow: none !important;
}

.nullWidgetsItemBoxEmpty {
    max-width: 100%;
}

.widgetsItemBoxEmpty .themeChooseContent {
    flex: 1 1;
    padding: 0 0 0 15px;
}

.widgetsItemBoxEmpty .themeChooseContent small {
    font-size: 13px;
}

.widgetTabs {
    padding: 0 !important;
    margin: -15px 0 0;
}

.widgetTabs .ui.secondary.pointing.menu {
    border-width: 1px !important;
}

.ui.buttonIcon.widgetItemDeleteButton {
    position: absolute;
    top: 5px;
    right: 5px;
}

.ui.buttonIcon.widgetItemDeleteButton img {
    transition: all 0.3s ease;
}

.ui.buttonIcon.widgetItemDeleteButton:hover img {
    filter: brightness(0.5);
}

.widgetTabs .ui.cards {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.widgetTabs .ui.cards:after {
    display: none;
}

.widgetTabs .ui.cards>.card {
    flex: 1 1;
    min-width: 45%;
    max-width: 360px;
    border-radius: 2px;
    border: solid 1px #d3d2de !important;
    box-shadow: none !important;
    background: none;
    padding: 0;
    margin: 0 0 15px;
}

.widgetTabs .ui.cards>.card:active {
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.3) !important;
}

.widgetTabs .ui.cards>.card .content {
    display: flex;
    align-items: center;
}

.widgetTabs .ui.cards>.card .content img {
    min-width: 60px;
    width: 60px;
    margin: 0 15px 0 10px;
}

.widgetTabs .ui.cards>.card .content .description {
    flex: 1 1;
    font: 400 15px "Rubik", sans-serif;
    color: #000 !important;
}

.paginationCover {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationCover i.icon {
    font-size: 18px;
}

.widgetTabs>.ui.attached.segment {
    margin: 0 !important;
}

.ui.modal.addWidgetModal,
.ui.modal.addWidgetModal>.actions {
    background: #fff;
}

.ui.input.widgetSearch {
    width: 186px;
    margin: 0 0 20px;
}

.ui.input.widgetSearch>input {
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #d3d2de;
    height: 26px;
    padding: 0;
    font: 400 15px "Rubik", sans-serif;
}

.ui.input.widgetSearch>input::placeholder {
    color: #666666;
}

.ui.input.widgetSearch>i.icon {
    width: 20px !important;
}

.dashboardWidgetOverviewCover {
    width: 100%;
    float: left;
    padding: 0 10px 0 0;
}

.dashboardWidgetOverviewBox {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    margin: 0 0 6px;
    border-radius: 3px;
    border: solid 1px #d3d2de;
    display: flex;
    align-items: center;
}

.dashboardWidgetOverviewBox>img {
    margin: 0 10px 0 0;
}

.dashboardWidgetOverviewBox p {
    font: 400 15px "Rubik", sans-serif;
    margin: 0;
}

.dashboardWidgetOverviewBox:not(.isSelected) {
    cursor: pointer;
}

.dashboardWidgetOverviewBox.isSelected {
    border-color: #727aff;
    box-shadow: inset 0 0 5px 0 #727aff, 0 0 12px rgba(0, 0, 0, 0.3);
}

.ui.modal.widgetDashboardModal {
    padding: 35px 0;
}

.ui.modal.widgetDashboardModal>.content {
    max-height: calc(80vh - 100px);
    overflow-y: auto;
}

.ui.modal.widgetDashboardModal .aligner {
    padding: 0 38px;
}

.ui.button.buttonIcon.widgetPreviewLink {
    padding-left: 10px;
    margin-left: 10px;
    border-left: solid 1px #d3d2de !important;
}

.widgetOverviewBoxContent {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
}

.widgetOverviewActions {
    margin: 0 0 0 30px;
    display: inline-flex;
    align-items: center;
}

.widgetOverviewActions .ui.buttonIcon {
    margin: 0 0 0 10px !important;
}

.statusPrimary {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
}

.ui.popup.confirmPopup {
    width: 355px;
    max-width: 355px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 3px;
    padding: 0 15px 15px;
}

.ui.popup.confirmPopup:before {
    display: none !important;
}

.ui.closeBtnDark:before,
.ui.closeBtnDark:after {
    background-color: #000 !important;
}

.ui.header.headerPopup {
    font: 400 15px "Rubik", sans-serif !important;
    padding: 0 0 10px !important;
    border-bottom: solid 1px #c6cbd0;
}

.ui.popup.confirmPopup p {
    font: 400 15px/22px "Rubik", sans-serif;
    word-break: break-word;
    max-height: 60px;
    overflow-y: auto;
}

.ui.dropdown.dropdownWidgetBox {
    width: auto;
    background: none !important;
    border-radius: 0;
    padding: 8px;
    margin: 0 0 0 auto;
}

.ui.dropdown.dropdownWidgetBox .ui.buttonIcon {
    width: 100%;
    font: 400 15px "Rubik", sans-serif;
    color: #000 !important;
    padding: 5px 10px;
    text-align: left;
}

.ui.dropdown.dropdownWidgetBox .ui.buttonIcon img {
    margin: 0 10px 0 0;
}

.ui.dropdown.dropdownWidgetBox .menu {
    width: 190px;
    max-width: 190px;
    padding: 10px 0;
    border-radius: 3px;
    border: none;
    padding: 10px 0;
}

.ui.dropdown.dropdownWidgetBox .menu>.item {
    padding: 0 !important;
    background: none !important;
}

.ui.modal.small.actionModal {
    width: 100%;
    max-width: 335px;
    padding: 25px 20px 15px;
    border: none;
    border-radius: 4px;
}

.ui.modal.small.actionModal .aligner,
.ui.modal.small.actionModal .content,
.ui.modal.small.actionModal .actions {
    padding: 0 !important;
}

.ui.modal.small.actionModal .actions {
    margin: 10px 0;
}

.paragraph,
.paragraph:first-child,
.paragraph:last-child {
    width: 100%;
    float: left;
    font: 400 15px/22px "Rubik", sans-serif;
    color: #000;
    margin: 0 0 20px;
}

.tgWidgetDashboard {
    display: flex;
    align-items: center;
}

.tgWidgetDashboard>p {
    width: auto;
    padding: 0 15px 0 0;
    font: 400 15px "Rubik", sans-serif;
}

.tgWidgetDashboard .ui.dropdown {
    height: 22px;
    font: 400 15px "Rubik", sans-serif !important;
}

.ui.modalLayout.scheduleEmailDeliveryModal {}

.ui.modal.scheduleEmailDeliveryModal .ui.dropdownGroup {
    height: 24px;
}

.ui.modal.scheduleEmailDeliveryModal .ui.dropdownGroup .text {
    color: #666666;
    font: 400 15px "Rubik", sans-serif;
}

.ui.button.buttonIcon.buttonActivityTrigger,
.ui.button.buttonIcon.buttonActivityTrigger:focus {
    margin: 0 0 0 auto;
    float: right;
    font: 400 13px "Rubik", sans-serif;
    color: #667581 !important;
}

.scheduleEmailWrap {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
}

.scheduleEmailColumn {
    flex: 1 1;
}

.scheduleEmailColumn:last-child {
    flex: 2 1;
    padding: 0 0 0 40px;
}

.tgAlignTop {
    align-items: flex-start;
}

.scheduleEmailColumn .textGroup>b {
    width: 80px;
    min-width: 80px;
    margin: 0;
}

.scheduleEmailColumn .textGroup .ui.dropdown {
    height: 24px;
}

.scheduleEmailColumn .textGroup .ui.dropdown .text {
    color: #666666;
    font: 400 15px "Rubik", sans-serif;
}

.scheduleEmailColumn .ui.input input {
    border-radius: 0;
    height: 24px;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
}

.scheduleEmailColumn .ui.input input::placeholder,
.inputScheduleDate input::placeholder,
.scheduleEmailColumn textarea::placeholder {
    color: #666 !important;
}

.scheduleEmailColumn textarea {
    height: 50px;
    border-radius: 0;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
    resize: none;
}

.scheduleEmailColumn2 .textGroup>b {
    width: auto;
    flex: 1 1;
}

.scheduleEmailColumn2 .textGroup .textGroupContent {
    width: 215px;
    padding: 0 0 0 10px;
}

.ui.input.inputScheduleDate {
    position: relative;
}

.ui.input.inputScheduleDate>img {
    position: absolute;
    right: 0;
    bottom: 6px;
}

.ui.input.inputScheduleDate>input {
    border-radius: 0;
    height: 24px;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
    width: 100%;
    padding: 0 30px 0 0;
}

.messageBox {
    width: 100%;
    float: left;
    min-height: 36px;
    font: 400 15px "Rubik", sans-serif;
    background: #f3f6fb;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    padding: 8px 10px;
    margin: 0 0 20px;
}

.scheduleEmailRow {
    width: 100%;
    float: left;
    margin: 0 0 15px;
    display: inline-flex;
    align-items: center;
}

.scheduleEmailRow .textGroupContent {
    width: auto;
}

.scheduleEmailRow .textGroup {
    width: auto;
    margin: 0;
}

.scheduleEmailRow .textGroup>b {
    width: auto;
    margin: 0 10px 0 0;
    white-space: nowrap;
}

.tgOccurance .ui.input {
    width: 130px;
    margin: 0 40px 0 0;
}

.tgOccurance .ui.input input {
    padding: 0 25px 0 0 !important;
}

.tgOccurance .ui.dropdownGroup {
    width: 85px;
}

.timezonePara {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
    margin: 0 0 0 20px;
}

.scheduleEmailRow2 .textGroup {
    width: 260px;
    margin: 0 80px 0 0;
}

.scheduleEmailRow2 .ui.dropdownGroup {
    width: 180px;
}

.ui.buttonIcon.buttonActivityBack {
    margin: -3px 6px 0 0 !important;
}

.activityTablebox {
    width: 100%;
    float: left;
}

.activityTableRow {
    width: 100%;
    float: left;
    min-height: 40px;
    margin: 0 0 6px;
    display: flex;
    align-items: center;
    border: solid 1px #d3d2de;
    border-radius: 3px;
}

.tableColumn {
    min-width: 25%;
    flex: 1 1;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 10px;

    font: 400 15px "Rubik", sans-serif;
}

.tableColumn:first-child,
.tableColumn:nth-child(3) {
    min-width: 19%;
}

.tableColumn:nth-child(2) {
    min-width: 34%;
}

.tableColumn:last-child {
    min-width: 28%;
}

.tableRowHead {
    border: none;
    border-radius: 0;
    min-height: 0;
}

.tableRowHead .tableColumn {
    padding: 4px 10px;
    font-size: 12px;
}

.activityStatus {
    flex: 1 1;
}

.activityActions {
    min-width: 60px;
}

.activityActions .ui.buttonIcon {
    margin: 0 0 0 10px !important;
}

.ui.modal.customDashboardCreatedModal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.confirmationCheck {
    width: 143px;
    height: 143px;
    border-radius: 200px;
    background: #2ecc71;
    border: solid 20px #eaf9f0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.modal.customDashboardCreatedModal .ui.header {
    font: 400 24px "Rubik", sans-serif;
}

.ui.modal.customDashboardCreatedModal .paragraph {
    max-width: 400px;
    margin: 30px 0 80px;
}

.ui.modal.customDashboardCreatedModal .ui.button {
    padding: 5px 40px;
}

.ui.button.deleteWidgetButton {
    position: absolute;
    right: 16px;
    top: 18px;
    z-index: 10;
    background: #fff;
    padding: 2px !important;
}

.widgetboxEmpty {
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
}

.ui.button.addWidgetButton {
    background: none !important;
    width: 100%;
    border: solid 1px #d3d2de !important;
    border-radius: 2px;
    margin: 30px 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: 400 15px "Rubik", sans-serif;
    color: #667581 !important;
}

.ui.button.addWidgetButton img {
    margin: 0 0 10px;
}

.defaultWrap {
    width: 100%;
    float: left;
}

.ui.grid.defaultboxCoverGrid .defaultBoxCover {
    margin: 0 !important;
}

.ui.grid.defaultboxCoverGrid .defaultBox,
.ui.grid.defaultboxCoverGridRegular .defaultBox {
    width: 100%;
    float: left;
    height: 100%;
}

.ui.grid.defaultboxCoverGrid>.column,
.ui.grid.defaultboxCoverGridRegular>.column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: inline-flex;
}

.ui.grid.defaultboxCoverGridRegular>.column {
    margin: 0 0 30px !important;
}

.ui.grid.defaultboxCoverGrid>.column.moveableBox {
    cursor: move;
}

.ui.grid.sortableOverview .column.wide {
    position: relative;
}

.ui.grid.sortableOverview .column.wide .ui.button.deleteWidgetButton {
    top: 19px;
    right: 19px;
}

.ui.grid.defaultboxCoverGrid>.ui.grid {
    flex: 1 1;
}

.noPadding {
    padding: 0 !important;
}

.circleChartHeighWrapper {
    height: 500px;
    position: relative;
    top: -120px;
}

.overflowHide {
    overflow: hidden !important;
}

.height460 {
    height: 460px;
}

.textCenter {
    text-align: center;
}

#info {
    position: absolute;
    height: 1px;
    width: 1px;
    z-index: 100;
}

.tooltip.in {
    opacity: 1;
}

.tooltip.top .tooltip-arrow {
    border-top-color: white;
}

.tooltip-inner {
    border: 2px solid white;
}

/*** welcome modal ***/

.ui.modal.modalWelcome {
    width: 100%;
    max-width: 600px;
    border-radius: 0;
}

.ui.modal.modalWelcome>.header {
    border: none;
    min-height: 200px;
    background: url(/img/modal-pattern.png);
    background-size: 160px auto;
}

.ui.modal.modalWelcome .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 25px 45px 0;
}

.ui.modal.modalWelcome .ui.image {
    width: 100%;
    float: left;
    margin: 10px 0 20px;
}

.ui.modal.modalWelcome .ui.image img {
    display: inline-block;
    width: 50px;
}

.ui.modal.modalWelcome p {
    font: 400 15px/22px 'Rubik', sans-serif;
    color: #000;
}

.ui.modal.modalWelcome>.actions {
    border: none;
    display: flex;
    justify-content: center;
    margin: 10px 0 30px;
    background: none;
}

.ui.modal.modalWelcome>.actions .ui.button {
    padding: 10px 50px;
}

.filterSubheader {
    width: 100%;
    background: #fff;
    border-top: solid 1px #bfcbd9;
    box-shadow: 0 2px 6px rgba(215, 215, 215, .8);
    position: fixed;
    top: 109px;
    left: 0;
    z-index: 4;
}

.filterSubheader .ui.container.fluid {
    padding: 0 20px;
    height: 53px;
    display: flex;
    align-items: center;
}

.filterSubheader .textGroup {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0;
}

.filterSubheader .textGroup>p {
    width: auto;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
}

.filterSubheader .ui.inputForm {
    width: 164px;
    margin: 0 20px 0 5px;
}

.filterSubheader .ui.inputForm>img {
    right: 8px;
    top: 8px;
    width: 14px;
}

.filterSubheader .ui.inputForm>input::placeholder {
    color: #000 !important;
}

.filterSubheader .ui.dropdownGroup.button {
    width: 208px;
    height: 30px;
    padding: 0 18px 0 10px !important;
    margin: 0 20px 0 5px;
    border: solid 1px #D3D2DE !important;
    border-radius: 3px !important;
    display: flex;
    align-items: center;
}

.filterSubheader .ui.dropdownGroup.button>.icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 11px;
    color: #8f8f8f;
}

.ui.button.buttonIcon.filtersTrigger {
    font: 500 12px 'Rubik', sans-serif !important;
    text-decoration: underline;
    color: #7279fe !important;
    margin-left: 10px;
}

/*** filter sidebar ***/

.ui.button.right.heatmapFilterSaveButton {
    margin: 10px 0;
    padding: 8px 10px;
    min-width: 70px;
    min-height: 30px;
    background: white;
    z-index: 90000;
    float:left;
    margin:9px 0 0 0;
}

.filterSidebar {
    width: 242px;
    height: calc(100vh - 109px);
    background: #f4f7fc;
    box-shadow: -3px 20px 15px rgba(0, 0, 0, .1);
    position: fixed;
    top: 110px;
    right: 0;
    z-index: 100;
}

.filterSidebarFit {
    width: 242px;
    height: calc(100vh - 165px);
    background: #fff;
    border-left: solid 1px #D3D2DE;
    box-shadow: -3px 20px 15px rgba(0, 0, 0, .1);
    position: fixed;
    top: 165px;
    right: 0;
    z-index: 5;
}

.filterSidebarHeatmap {
    height: calc(100vh - 109px);
    top: 109px;
}

.ui.closeBtn.closeBtnHeatmap {
    width: 15px;
    height: 15px !important;
    margin: 0 !important;
    top: 20px;
}

.ui.closeBtn.closeBtnHeatmap:before,
.ui.closeBtn.closeBtnHeatmap:after {
    background: #000;
    width: 1.5px;
    height: 15px;
}

.filterSidebar .ui.header {
    width: 100%;
    height: 56px;
    float: left;
    margin: 0;
    padding: 3px 0 0 22px;
    background: #fff;
    border-bottom: solid 3px #f5f7f6;
    font: 500 14px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
}

.ui.header.headerFilter {
    width: 100%;
    height: 50px;
    float: left;
    margin: 0;
    border-bottom: solid 11px #F4F7FC;
    font: 500 14px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
}

.accordionFilter {
    width: 100%;
    float: left;
}

.accordionFilter .title {
    width: 100%;
    float: left;
    min-height: 56px;
    padding: 8px 0 8px 22px !important;
    font: 400 14px 'Rubik', sans-serif !important;
    color: #607797 !important;
    display: flex;
    align-items: center;
    position: relative;
}

.accordionFilter .title:before,
.accordionFilter .content.active:before {
    content: '';
    width: 95%;
    height: 1px;
    border-bottom: solid 1px #c6d2e2;
    position: absolute;
    right: 0;
    bottom: 0;
}

.accordionFilter .title.active {
    background: #fff;
}

.accordionFilter .title.active:before {
    display: none;
}

.accordionFilter .title:after {
    content: '';
    width: 11px;
    height: 7px;
    background: url(/img/icon-chevron-down.svg) no-repeat center;
    background-size: 11px 6px;
    filter: saturate(0.3);
    position: absolute;
    right: 20px;
    transition: all .2s ease;
}

.accordionFilter .title.active:after {
    transform: rotate(180deg);
}

.accordionFilter .content {
    width: 100%;
    float: left;
    padding: 10px 0 !important;
    margin: 0 !important;
    background: #fff;
    position: relative;
}

.extraPaddingBottom {
    padding-bottom: 50px !important;
}

.filterAligner {
    width: 100%;
    float: left;
    padding: 0 20px 0 22px;
}

.filterAlignerBottom {
    width: 100%;
    float: left;
    padding: 0 20px 0 22px;
    bottom: 0;
    position: fixed;
    background-color: white;
    z-index: 9000;
    border-top:solid 1px #D3D2DE;
    box-shadow:0 -2px 8px rgba(0,0,0,.1);
}

.radioFilterCheck {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    font: 400 13px 'Rubik', sans-serif;
}

.radioFilterCheck .ui.checkboxGroup {
    width: auto;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin: 0 10px 0 0 !important;
}

.radioFilterCheck .ui.input {
    width: auto;
    padding: 0 !important;
}

.radioFilterCheck .ui.checkboxGroup label {
    max-width: 105px;
    padding-left: 22px !important;
}

.radioFilterCheck .ui.labeled.input>input {
    min-width: 40px;
    max-width: 44px;
    height: 14px;
    border-radius: 0;
    padding: 0 !important;
    border: none;
    border-bottom: dashed 1px #000;
    margin: -6px ​4px 0 !important;
    text-align: center;
}

.radioFilterCheck .ui.input .label {
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    padding: 0 !important;
    margin: 0 0 0 10px !important;
    background: none;
    height: auto;
}

.ui.button.primary.filterSave {
    min-width: 62px;
    min-height: 22px;
    float: right;
    border-radius: 2px;
    font: 500 12px 'Rubik', sans-serif !important;
    margin: 10px 20px 5px 0;
}

.filterSection {
    width: 100%;
    float: left;
    border-bottom: solid 11px #F4F7FC;
    padding: 20px 0 10px;
}

.filterSection:last-child {
    border: none;
}

.h6 {
    width: 100%;
    float: left;
    margin: 0 0 15px;
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
    text-transform: uppercase;
}

.filterSection .ui.button.primary {
    min-width: 60px;
    min-height: 28px;
    font-size: 12px !important;
}
