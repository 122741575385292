.ui.dropdown.languageDropdown {
    min-width: 0 !important;
    width: 51px;
    height: 32px !important;
    border-radius: 4px !important;
    background: #fff !important;
    border: none !important;
    padding: 0 0 0 9px !important;
    font: 500 14px/31px 'Rubik', sans-serif !important;
    color: #727AFF !important;
    text-align: center !important;
}

div.ui.dropdown.languageDropdown.button {
    position: relative;
    z-index: 10;
}

div.ui.dropdown.languageDropdown.button:active,
div.ui.dropdown.languageDropdown.button {
    box-shadow: none !important;
}

.ui.menu.header-bar .right.menu .ui.button.languageDropdown:hover {
    background: #fff !important;
}

.ui.languageDropdown i.icon {
    position: relative;
    margin: 0 !important;
    top: 1px;
    height: auto !important;
}

.ui.languageDropdown .menu {
    z-index: 8;
    width: 280px !important;
    left: auto !important;
    right: -34px !important;
    border-radius: 4px;
    border: solid 1px #D3D2DE;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2) !important;
    margin-top: 13px !important;
}

.ui.languageDropdown .menu:after {
    left: auto !important;
    right: 50px !important;
    width: 12px !important;
    height: 12px !important;
    top: -6px !important;
    box-shadow: -1px -1px 0 0 rgba(34, 36, 38, .15) !important;
}

.ui.menu.header-bar .ui.languageDropdown .menu>.item,
.ui.menu .ui.languageDropdown .menu>.item {
    padding: 12px 19px !important;
    margin: 0 !important;
    border-radius: 0;
    display: flex;
    align-items: center;
    font: 400 14px 'Rubik', sans-serif !important;
    color: #667581;
}

.ui.languageDropdown .menu>.item .text {
    padding: 0 !important;
    color: #667581 !important;
    font: 400 14px 'Rubik', sans-serif !important;
    position: relative !important;
    background: none !important;
}

.ui.menu .ui.languageDropdown .menu>.item:hover .text,
.ui.menu .ui.languageDropdown .menu>.item.selected .text {
    color: #000 !important;
}

.ui.menu .ui.languageDropdown .menu>.item:last-child:hover {
    border-radius: 0 0 4px 4px;
}

.ui.menu .ui.languageDropdown .menu>.item.active.selected {
    /* background: url(/img/icon-verify-green.svg) no-repeat 95% 15px!important; */
}

.ui.languageDropdown .menu>.item img {
    margin-right: 11px !important;
}

.ui.languageDropdown .menu>.item>.description {
    font-size: 13px !important;
}

.ui.languageDropdown .menu>.item>.ui.button {
    background: none;
    border: none;
    box-shadow: none;
    min-height: 0;
    min-width: 0;
    height: auto;
    padding: 0;
    margin: 0;
    font: 400 13px 'Rubik', sans-serif !important;
    color: #727AFF !important;
}

.ui.languageDropdown .menu>.item .icon,
.ui.menu.header-bar .ui.languageDropdown .menu>.item .icon:not(.dropdown) {
    color: #17C0AC;
    font-size: 12px !important;
    margin: 0 0 0 3px !important;
}

.ui.languageDropdown .menu>.header {
    font: 600 14px 'Rubik', sans-serif !important;
    margin: 20px 0 10px !important;
    padding: 0 0 0 19px !important;
}