.defaultBox.independent {
    max-width: 100%;
    width: 100%;
    float: left;
    margin: 0 0 25px;
}

.defaultBox.onMove {
    border: dashed 1px #727aff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    /* delete later*/
    top: 20px;
    left: 20px;
    z-index: 10;
    cursor: move;
}

.onMoveActions {
    width: auto;
    position: absolute;
    right: 25px;
    top: 15px;
    display: flex;
    align-items: center;
}

.onMoveActions .ui.basic.buttonIcon {
    margin-left: 5px;
}

.defaultBox.isEmpty {
    background: none;
    border: dashed 1px #969db8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.widgetCover {
    flex: 1;
    max-width: 632px;
    min-width: 45%;
    min-height: 300px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.widgetCover .defaultBox {
    width: 100%;
}

.widgetCover span {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
    margin: 10px 0 0;
}

.defaultBox.isEmpty .widgetCover {
    justify-content: center;
}

.defaultCoverAlternate {
    margin: 0;
}

.defaultCoverAlternate .defaultBox {
    margin: 0 0 30px;
    cursor: move;
}

.ui.basic.button.addButton,
.ui.basic.button.addButton:hover,
.ui.basic.button.addButton:focus {
    width: 50px;
    height: 50px;
    border-radius: 2px !important;
    background: #fff !important;
    position: relative;
}

.ui.basic.button.addButton:before,
.ui.basic.button.addButton:after {
    content: "";
    position: absolute;
    border-radius: 1px;
    background: #727aff;
}

.ui.basic.button.addButton:before {
    width: 16.5px;
    height: 2px;
    top: 24px;
    left: 17px;
}

.ui.basic.button.addButton:after {
    width: 2px;
    height: 16.5px;
    top: 17px;
    left: 24px;
}

/*** charts ***/

.vanueCover {
    width: 100%;
    float: left;
    margin: 0 0 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.vanueCover .chartInfoColorItem {
    flex-basis: 45%;
}

.vanueCover .chartInfoColorItem:first-child {
    order: 1;
}

.vanueCover .chartInfoColorItem:nth-child(2) {
    order: 3;
}

.vanueCover .chartInfoColorItem:nth-child(3) {
    order: 5;
}

.vanueCover .chartInfoColorItem:nth-child(4) {
    order: 7;
}

.vanueCover .chartInfoColorItem:nth-child(5) {
    order: 2;
}

.vanueCover .chartInfoColorItem:nth-child(6) {
    order: 4;
}

.vanueCover .chartInfoColorItem:nth-child(7) {
    order: 6;
}

.vanueCover .chartInfoColorItem:last-child {
    order: 8;
}

.heatmapWidget .bodyDefaultBox {
    position: relative;
}

.ui.buttonIcon.heatMapTrigger {
    position: absolute;
    right: 0;
    top: -33px;
}

.ui.buttonIcon.heatMapTrigger img {
    width: 14px;
}

.mapWrapper {
    height: 100%;
    width: 100%;
}

.mapWrapperUserExplorer {
    flex: 1;
    height: calc(100vh - 111px);
}

.mapWrapper .ui.placeholder.placeholderCover .image:before {
    display: none;
}

.heatmapWidgetMinimized {
    height: 100% !important;
}

.heatMapWidgetMaximized {
    background: #fff;
    width: 100%;
    height: calc(100vh - 110px);
    position: fixed;
    top: 109px;
    left: 0;
    z-index: 3;
}

.heatMapWidgetMaximized .defaultBox .floorButtonsCover {
    margin-right: 7px;
}

.heatMapWidgetMaximized .defaultBox {
    max-width: 100%;
}

.heatMapWidgetMaximized:before {
    content: "";
    width: 100%;
    height: 5px;
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 4;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
}

.heatMapWidgetMaximized .ui.button.buttonIcon {
    position: absolute;
    right: 15px;
    top: -30px;
}

.heatMapWidgetMaximized .toggleSliderBox {
    top: 80px;
}

.heatMapWidgetMaximized .floorButtonsCover {
    top: 125px;
    right: 257px;
    z-index: 10;
}

.floorButtonsCover.navActionsCover {
    padding: 26px 0;
}

.heatMapWidgetMaximized .toggleSliderBox {
    top: 80px;
    right: 257px;
}

.heatMapWidgetFit {
    height: 100%;
    width: 100%;
}

.heatMapWidgetFit .floorButtonsCover {
    right: 257px;
    z-index: 10;
}

.heatMapWidgetFit .floorButtonsCover.navActionsCover {
    padding: 26px 0;
}

.heatMapWidgetFit .toggleSliderBox {
    right: 257px;
}

.repeatCover {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
}

.repeatCover .chartInfoColorItem {
    width: auto;
    margin: 0 15px;
}

.peakHoursTable,
.peakHoursRow,
.peakHoursItem {
    width: 100%;
    float: left;
}

.peakHoursTable {
    margin: 5px 0 0;
}

.peakHoursRow {
    min-height: 38px;
    display: flex;
    align-items: center;
    border: solid 1px #d3d2de;
    border-radius: 1px;
    margin: 0 0 5px;
}

.peakHoursItem {
    flex: 1;
    min-width: 100px;
    font: 400 15px "Rubik", sans-serif;
    color: #000;
}

.peakHoursItem.tooltip {
    position: relative;
}

.peakHoursItem:last-child {
    text-align: center;
    flex: 2;
    min-width: 200px;
}

.tooltip .peekHoursColumnToolTipText {
    display: none;
    position: absolute;
    bottom: 100%;
    background: #fff;
    padding: 5px 10px;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: solid 1px #d3d2de;
    font: 400 12px "Rubik", sans-serif;
    white-space: nowrap;
}

.tooltip:hover .peekHoursColumnToolTipText {
    display: block;
}

.peakHoursRow:first-child {
    min-height: 0;
    border: none;
    padding: 1px;
}

.peakHoursRow:first-child .peakHoursItem {
    font: 400 12px "Rubik", sans-serif;
    color: #667581;
}

.peakHoursRow .peakHoursItem:first-child {
    padding-left: 15px;
}

.peakHoursRow .peakHoursItem:first-child .peakHoursColumn {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.peakHoursItem img {
    margin: 3px 0 0;
}

.peakHoursRow .peakHoursItem:nth-child(2),
.peakHoursRow .peakHoursItem:nth-child(3) {
    text-align: center;
}

.peakHoursRow .peakHoursItem:nth-child(2) {
    min-width: 94px;
    width: 100px;
    max-width: 100px;
}

.boxProdiver>.ui.image {
    margin-right: 30px;
}

.boxProdiver .chartInfoCover {
    max-width: 250px;
}

.boxProdiver .chartInfoCover .chartInfoColorItem {
    margin-bottom: 4px;
}

.widgetCoverTime {
    width: 100%;
    float: left;
    height: 300px;
}

.widgetCoverTime>div:last-child {
    margin-top: -60px;
}

.widgetCoverTime>div:last-child>div>div svg:first-child {
    height: 270px;
}

.widgetCoverTime .flexibleRow {
    margin-bottom: -15px;
}

.heatmapWidget .bodyDefaultBox {
    width: 100%;
    height: 450px !important;
    position: relative;
}

.ui.checkbox {
    padding-bottom: 5px;
}

.toggletooltipCover {
    width: 100%;
    float: left;
    position: relative;
}

.toggleSliderCover {
    width: 100%;
    float: left;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    right: 1rem;
}

.toggleSliderCover .names,
.toggleSliderCover .style {
    width: auto;
}

.toggleSliderCover .names {
    margin: 0 0 10px;
}

/* .toggleSliderCover .style {
    margin: 0 10px 0 0;
} */

.toggleSliderCover .overlays {
    margin: 0 0 10px;
}

.bodyDefaultBox .toggleSliderCover .names,
.traceTableViewBody .toggleSliderCover .names,
.alertsContainer .toggleSliderCover .names,
.bodyDefaultBox .toggleSliderCover .overlays,
.traceTableViewBody .toggleSliderCover .overlays,
.alertsContainer .toggleSliderCover .overlays {
    /* margin-right: 10px; */
}


.heatMapWidgetFit .toggleSliderCover .style,
.boostAnalyticsBody .toggleSliderCover .style,
.heatMapWidgetMaximized .toggleSliderCover .style,
.PathAnalyticsMapContainer .toggleSliderCover .names,
.OccupancyMapContainer .toggleSliderCover .names,
.PathAnalyticsMapContainer .toggleSliderCover .overlays,
.OccupancyMapContainer .toggleSliderCover .overlays {
    margin-right: 0 !important;
}

.mapWrapperUserExplorer .toggleSliderCover .names,
.mapWrapperUserExplorer .toggleSliderCover .overlays {
    margin-right: 290px !important;
}

.toggleSliderCover .ui.toggle.checkbox {
    width: 36px;
    float: right;
    padding: 2px 0;
    min-height: 18px;
}

.toggleSliderCover .ui.toggle.checkbox {
    width: 36px;
    height: 18px;
}

.toggleSliderCover .ui.toggle.checkbox label {
    padding: 0;
    width: 36px;
}

.toggleSliderCover .ui.toggle.checkbox label {
    padding: 0;
    width: 36px;
}

.toggleSliderBox {
    width: 36px;
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 2;
}

.toggleSliderBox .ui.toggle.checkbox label:before,
.toggleSliderBox .ui.toggle.checkbox label:active:before,
.toggleSliderBox .ui.toggle.checkbox label:focus:before,
.toggleSliderBox .ui.toggle.checkbox input:checked~label:before,
.toggleSliderBox .ui.toggle.checkbox input:checked~label:active:before,
.toggleSliderBox .ui.toggle.checkbox input:checked~label:focus:before {
    background: #fff !important;
    width: 38px;
    height: 16px;
    border: solid 1px #d3d2de !important;
}

.toggleSliderCover .ui.toggle.checkbox label:after {
    background: #727aff;
    box-shadow: none !important;
    top: -1px !important;
    left: 0;
    width: 18px;
    height: 18px;
}

.toggleSliderCover .ui.toggle.checkbox input:checked~label:after {
    left: 21px;
}

.names .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/toggle-names-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.names .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/toggle-names-active.svg) no-repeat center #727aff;
    background-size: 11px auto;
    box-shadow: none !important;
}

.overlays .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/toggle-overlay-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.overlays .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/toggle-overlay-active.svg) no-repeat center #727aff;
    background-size: 11px auto;
    box-shadow: none !important;
}

.style .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/toggle-style-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.style .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/toggle-style-active.svg) no-repeat center #727aff;
    background-size: 10px auto;
    box-shadow: none !important;
}

.zoneGeofence .overlays {
    margin: 0 0 10px;
}

.zoneGeofence .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: url(/img/icon-toggle-zone-inactive.svg) no-repeat center #fff;
    background-size: 12px auto;
    box-shadow: inset 0 0 0 1px #d3d2de !important;
    border: none;
}

.zoneGeofence .ui.toggle.checkbox input:checked~label:after {
    background: url(/img/icon-toggle-zone.svg) no-repeat center #727aff;
    background-size: 11px auto;
    box-shadow: none !important;
}

/*
.style .toggleSliderBox .ui.toggle.checkbox label:after {
    width: 30px;
    height: 30px;
    background: #fff;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    top: 4px !important;
}

.style .ui.toggle.checkbox input:checked~label:after {
    background: #007aff;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .4) !important;
    top: 4px !important;
} */

.toggleSliderBox .ui.toggle.checkbox label {
    text-indent: -99999999px;
    background: transparent !important;
}

.floorButtonsGroup,
.floorButtonsCover .ui.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.floorButtonsCover {
    position: absolute;
    right: 10px;
    top: 150px !important;
    z-index: 2;
}

.bodyDefaultBox .mapContainer .floorButtonsCover {
    padding: 28px 0;
}

.floorButtonsGroup {
    border-radius: 100px;
    overflow: hidden;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    margin: 5px 0;
}

.floorButtonsGroup .ui.active.button {
    color: #007aff;
}

.floorButtonsGroup .ui.button {
    width: 38px;
    height: 38px;
    font: 500 14px "Rubik", sans-serif;
    background: #fff !important;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    border-radius: 0 !important;
}

.floorButtonsGroup .ui.button.active {
    background: #ebf8fe !important;
    color: #727aff !important;
}

.ui.button.buttonIcon.floorsToggle {
    width: 26px;
    height: 26px;
    border-radius: 100px;
    background: #fff !important;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2) !important;
    position: absolute;
    top: 0;
    left: 6px;
}

.ui.button.buttonIcon.floorsToggle:hover,
.ui.button.buttonIcon.floorsToggle:focus {
    background: #fff !important;
}

.ui.button.buttonIcon.floorsToggle img {
    width: 12px;
}

.ui.button.buttonIcon.floorsToggle.next {
    top: auto;
    bottom: 0;
}

.ui.button.buttonIcon.floorsToggle.next img {
    transform: rotate(180deg);
    margin: 2px 0 0;
}

.floors-group {
    width: 34px;
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.floors-group .ui.basic.button {
    box-shadow: none;
    background: #fff;
    border: none !important;
    width: 34px;
    height: 34px;
    padding: 0;
    border-radius: 0;
    text-align: center;
    font: 500 13px/34px "Rubik", sans-serif;
    color: #333;
}

.floors-group .ui.basic.button.active {
    background: #ebf8fe;
    color: #727aff;
}

.floors-group .ui.basic.button:first-child {
    border-radius: 50px 50px 0 0;
}

.floors-group .ui.basic.button:last-child {
    border-radius: 0 0 50px 50px;
}

.ol-control.ol-collapsed {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding: 0 10px 5px;
}

.ol-control.ol-collapsed ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
    visibility: hidden;
}

.ol-control.ol-collapsed ul p {
    margin: 0 0 5px;
    font: 400 11px "Rubik", sans-serif;
    color: #000;
}

.ol-control {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding: 0 10px 5px;
}

.ol-control ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
}

.ol-control ul p {
    margin: 0 0 5px;
    font: 400 11px "Rubik", sans-serif;
    color: #000;
}

.UsedDevicesWidget {
    width: 100%;
    float: left;
    height: 250px;
}

.ChartWrapper {
    width: 100%;
    float: left;
    height: 250px;
}

.FullHeigthChartWrapper {
    width: 100%;
    float: left;
    height: 100%;
}

.tooltipWidgetRowCover p {
    font: 400 13px "Rubik", sans-serif;
}

.widgetsTooltipBox {
    width: 200px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    font: 400 14px "Rubik", sans-serif;
}

.widgetsTooltipBox>strong {
    display: block;
    margin: 0 0 10px;
}

.widgetsTooltipBox strong {
    font-weight: 500;
}

.WeatherWidgetsTooltipBox {
    width: 300px;
    max-width: 300px;
    background: #fff;
    z-index: 1000;
    padding: 0;
}

.WeatherWidgetsTooltipBox>div {
    background: #fff;
    float: left;
    width: 100%;
    min-height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #d3d2de;
    color: #667581;
    white-space: nowrap;
}

.WeatherWidgetsTooltipBox>div:first-child {
    margin: 10px 0 0;
}

.WeatherWidgetsTooltipBox>div:last-child {
    border: none;
    margin: 0 0 10px;
}

.WeatherWidgetsTooltipBox>div>span {
    margin-left: auto;
    color: #000;
    display: inline-flex;
    align-items: center;
}

.WeatherWidgetsTooltipBox>div:first-child>img {
    margin: 0 5px 0 0;
}

.tooltipItemRow {
    display: inline-flex;
    align-items: center;
    margin: 0 0 5px;
    font: 400 13px "Rubik", sans-serif;
    color: #000;
    background-color: white;
    padding: 5px;
}

.tooltipItemRow:last-child {
    margin: 0;
}

.tooltipItemRow>span:first-child {
    min-width: 14px;
    width: 14px;
    height: 14px;
    margin: 0 5px 0 0;
}

.tooltipItemRow strong {
    margin-left: auto;
    font-weight: 500;
}

.tooltipItemRowDevice {
    white-space: nowrap;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.tooltipWidgetRowCover strong {
    margin-left: 5px;
}

.tooltipItemRowDevice strong {
    margin: 0 5px 0 10px;
}

.web-eng-search-tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0 5px 25px;
}

.web-eng-search-tooltip>div {
    width: 100%;
}

.web-eng-search-tooltip>div strong {
    margin-left: 0;
}

.web-eng-search-tooltip>span:first-child {
    position: absolute;
    left: 5px;
    top: 5px;
}

.tooltipItemProvider {
    white-space: pre;
    padding: 5px 10px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.poiWidget {
    background: #fff;
    padding: 6px 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

/* curated dashboard */
.temporarycover {
    background: #fff;
    width: 100%;
    float: left;
    height: calc(100vh - 64px);

    display: flex;
    align-items: center;
    justify-content: center;
}

.createDashboardBox {
    width: 100%;
    max-width: 820px;
    padding: 30px 40px 30px;
    border-radius: 2px;
    background: #fff;
    border: solid 1px #d3d2de;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.headingDashboardBox {
    width: 100%;
    float: left;
    font: 400 18px "Rubik", sans-serif;
    color: #000;
    padding: 0 0 10px;
    margin: 0 0 15px;
    border-bottom: solid 1px #d3d2de;
}

.tgCustomName {
    display: flex;
    align-items: center;
}

.tgCustomName>p {
    font: 400 15px "Rubik", sans-serif;
    width: auto;
    min-width: 200px;
}

.tgCustomName .ui.input input {
    height: 25px;
    border-radius: 0;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
}

.tgCustomName .ui.input input::placeholder {
    color: #666666;
}

.tgChooseTheme {
    margin: 0 0 25px;
}

.tgChooseTheme .textGroupContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
}

.ui.button.buttonThemeChoose {
    flex: 1;
    max-width: 360px;
    height: 80px;
    background: #fff !important;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000;
    font: 400 15px "Rubik", sans-serif;
    padding: 10px;
}

.ui.button.buttonThemeChoose:hover {
    border-color: #727aff;
}

.ui.button.buttonThemeChoose img {
    min-width: 60px;
    margin: 0 10px 0 0;
}

.themeChooseContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.themeChooseContent span {
    border-radius: 3px;
    background: #d3d2de;
    color: #37383c;
    font: 400 15px "Rubik", sans-serif;
    padding: 4px 10px;
    margin: 5px 0 0;
}

.ui.button.buttonThemeChoose.disabled {
    opacity: 1 !important;
    border-color: #e8e8ee;
}

.ui.button.buttonThemeChoose.disabled {
    color: #7e7e7e;
}

.subheaderDashboardThemeLeft {
    flex: 1;
}

.ui.button.editSubheaderPencil {
    margin: 0 10px !important;
}

.ui.button.editSubheaderPencil img {
    width: 16px;
    filter: saturate(10);
}

.editModeStatus {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
    margin: 0 10px;
}

.ui.button.primary.widgetSaveButton {
    min-width: 70px;
    margin: 0 15px 0 0;
}

.ui.dropdown.dropdownCustomDashboad {
    width: auto;
    border: none !important;
    margin: 0;
    display: flex;
    align-items: center;
}

.ui.dropdown.dropdownCustomDashboad>img:first-child {
    margin: 0 5px 0 0;
}

.ui.dropdown.dropdownCustomDashboad i.icon {
    margin: 0 0 0 10px !important;
    font-size: 17px;
}

.ui.button.buttonIcon.widgetDashboardButton {
    margin: 4px 0 0 15px;
    font: 400 14px "Rubik", sans-serif !important;
    color: #667581 !important;
    border-left: solid 1px #c0cbda !important;
    padding: 0 0 0 20px;
}

.ui.button.gobackButton {
    min-width: 60px;
}

.curatedSubheader {
    width: 100%;
    float: left;
    margin: 0 0 20px !important;
    display: flex;
    align-items: flex-end;
}

.curatedSubheaderLeft {
    flex: 1;
}

.curatedDashboardName {
    display: inline-flex;
    align-items: center;
    font: 400 14px "Rubik", sans-serif;
}

.curatedDashboardName .ui.input {}

.curatedDashboardName .ui.input>input {
    border-radius: 0;
    height: 25px;
    font: 400 15px "Rubik", sans-serif;
    border: none;
    background: none;
    padding: 0;
}

.curatedDashboardActions {
    display: inline-flex;
    align-items: center;
}

.curatedDashboardActions .ui.buttonIcon {
    margin: 0 0 0 10px !important;
}

.curatedSubheader>.ui.basic.button.primary {
    margin: 0 0 0 15px;
}

.curatedOverviewHead {
    width: 100%;
    padding: 10px 0 25px;
    margin: 0 0 25px;
    border-bottom: solid 1px #d3d2de;
    display: flex;
    justify-content: space-between;
}

.selectWidgetBox {
    flex: 1;
    max-width: 300px;
    height: 100px;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 15px;

    font: 400 15px "Rubik", sans-serif;
    color: #667581;
}

.selectWidgetBox img {
    margin: 0 15px 0 0;
    background: #fff;
}

.curatedWidgetCover {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.selectWidgetBoxBig {
    height: 395px;
    width: 100%;
    max-width: 47%;
    margin: 0 0 40px;

    border: solid 1px #d3d2de;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;

    font: 400 15px "Rubik", sans-serif;
    color: #667581;
}

.selectWidgetBoxBig img {
    margin: 0 0 15px;
}

.ui.header.headerWidgetModal {
    width: 100%;
    float: left;
    font: 600 15px "Rubik", sans-serif;
    display: flex;
    align-items: center;
    min-height: 28px;
}

.tabWidgetsCover {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.widgetsItemBox {
    flex: 1;
    min-width: 364px;
    max-width: 364px;
    height: 80px;
    background: #fff !important;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000;
    font: 400 15px "Rubik", sans-serif;
    padding: 10px;
    margin: 0 0 15px;
    position: relative;
}

.widgetsItemBox .checkboxGroup {
    width: auto;
    margin: 0 10px 0 0;
}

.widgetsItemBox:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.widgetsItemBox:not(.isSelected) {
    cursor: pointer;
}

.widgetsItemBox.isSelected {
    border-color: #727aff;
    box-shadow: inset 0 0 5px 0 #727aff, 0 0 12px rgba(0, 0, 0, 0.3);
}

.widgetsItemBox>.ui.image {
    min-width: 60px;
    margin: 0 10px 0 0;
    position: relative;
}

.widgetsItemBox>.ui.image .ui.green.right.corner.label {
    position: absolute;
    top: 0;
    right: 0;
}

.widgetsItemBox>.ui.image .ui.corner.label:after {
    border-right-width: 3em;
    border-bottom-width: 3em;
}

.widgetsItemBox>.ui.image .ui.green.right.corner.label i.icon {
    top: 4px;
    right: 4px;
    font-size: 13px;
}

.widgetsItemBoxEmpty {
    cursor: initial !important;
    box-shadow: none !important;
}

.nullWidgetsItemBoxEmpty {
    max-width: 100%;
}

.widgetsItemBoxEmpty .themeChooseContent {
    flex: 1;
    padding: 0 0 0 15px;
}

.widgetsItemBoxEmpty .themeChooseContent small {
    font-size: 13px;
}

.widgetTabs {
    padding: 0 !important;
    margin: -15px 0 0;
}

.widgetTabs .ui.secondary.pointing.menu {
    border-width: 1px !important;
}

.ui.buttonIcon.widgetItemDeleteButton {
    position: absolute;
    top: 5px;
    right: 5px;
}

.ui.buttonIcon.widgetItemDeleteButton img {
    transition: all 0.3s ease;
}

.ui.buttonIcon.widgetItemDeleteButton:hover img {
    filter: brightness(0.5);
}

.widgetTabs .ui.cards {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.widgetTabs .ui.cards:after {
    display: none;
}

.widgetTabs .ui.cards>.card {
    flex: 1;
    min-width: 45%;
    max-width: 360px;
    border-radius: 2px;
    border: solid 1px #d3d2de !important;
    box-shadow: none !important;
    background: none;
    padding: 0;
    margin: 0 0 15px;
}

.widgetTabs .ui.cards>.card:active {
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.3) !important;
}

.widgetTabs .ui.cards>.card .content {
    display: flex;
    align-items: center;
}

.widgetTabs .ui.cards>.card .content img {
    min-width: 60px;
    width: 60px;
    margin: 0 15px 0 10px;
}

.widgetTabs .ui.cards>.card .content .description {
    flex: 1;
    font: 400 15px "Rubik", sans-serif;
    color: #000 !important;
}

.paginationCover {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationCover i.icon {
    font-size: 18px;
}

.widgetTabs>.ui.attached.segment {
    margin: 0 !important;
}

.ui.modal.addWidgetModal,
.ui.modal.addWidgetModal>.actions {
    background: #fff;
}

.ui.input.widgetSearch {
    width: 186px;
    margin: 0 0 20px;
}

.ui.input.widgetSearch>input {
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #d3d2de;
    height: 26px;
    padding: 0;
    font: 400 15px "Rubik", sans-serif;
}

.ui.input.widgetSearch>input::placeholder {
    color: #666666;
}

.ui.input.widgetSearch>i.icon {
    width: 20px !important;
}

.dashboardWidgetOverviewCover {
    width: 100%;
    float: left;
    padding: 0 10px 0 0;
}

.dashboardWidgetOverviewBox {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    margin: 0 0 6px;
    border-radius: 3px;
    border: solid 1px #d3d2de;
    display: flex;
    align-items: center;
}

.dashboardWidgetOverviewBox>img {
    margin: 0 10px 0 0;
}

.dashboardWidgetOverviewBox p {
    font: 400 15px "Rubik", sans-serif;
    margin: 0;
}

.dashboardWidgetOverviewBox:not(.isSelected) {
    cursor: pointer;
}

.dashboardWidgetOverviewBox.isSelected {
    border-color: #727aff;
    box-shadow: inset 0 0 5px 0 #727aff, 0 0 12px rgba(0, 0, 0, 0.3);
}

.ui.modal.widgetDashboardModal {
    padding: 35px 0;
}

.ui.modal.widgetDashboardModal>.content {
    max-height: calc(80vh - 100px);
    overflow-y: auto;
}

.ui.modal.widgetDashboardModal .aligner {
    padding: 0 38px;
}

.ui.button.buttonIcon.widgetPreviewLink {
    padding-left: 10px;
    margin-left: 10px;
    border-left: solid 1px #d3d2de !important;
}

.widgetOverviewBoxContent {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
}

.widgetOverviewActions {
    margin: 0 0 0 30px;
    display: inline-flex;
    align-items: center;
}

.widgetOverviewActions .ui.buttonIcon {
    margin: 0 0 0 10px !important;
}

.statusPrimary {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
}

.ui.popup.confirmPopup {
    width: 355px;
    max-width: 355px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 3px;
    padding: 0 15px 15px;
}

.ui.popup.confirmPopup:before {
    display: none !important;
}

.ui.closeBtnDark:before,
.ui.closeBtnDark:after {
    background-color: #000 !important;
}

.ui.header.headerPopup {
    font: 400 15px "Rubik", sans-serif !important;
    padding: 0 0 10px !important;
    border-bottom: solid 1px #c6cbd0;
}

.ui.popup.confirmPopup p {
    font: 400 15px/22px "Rubik", sans-serif;
    word-break: break-word;
    max-height: 60px;
    overflow-y: auto;
}

.ui.dropdown.dropdownWidgetBox {
    width: auto;
    background: none !important;
    border-radius: 0;
    padding: 8px;
    margin: 0 0 0 auto;
}

.ui.dropdown.dropdownWidgetBox .ui.buttonIcon {
    width: 100%;
    font: 400 15px "Rubik", sans-serif;
    color: #000 !important;
    padding: 5px 10px;
    text-align: left;
}

.ui.dropdown.dropdownWidgetBox .ui.buttonIcon img {
    margin: 0 10px 0 0;
}

.ui.dropdown.dropdownWidgetBox .menu {
    width: 190px;
    max-width: 190px;
    padding: 10px 0;
    border-radius: 3px;
    border: none;
    padding: 10px 0;
}

.ui.dropdown.dropdownWidgetBox .menu>.item {
    padding: 0 !important;
    background: none !important;
}

.ui.modal.small.actionModal {
    width: 100%;
    max-width: 335px;
    padding: 25px 20px 15px;
    border: none;
    border-radius: 4px;
}

.ui.modal.small.actionModal .aligner,
.ui.modal.small.actionModal .content,
.ui.modal.small.actionModal .actions {
    padding: 0 !important;
}

.ui.modal.small.actionModal .actions {
    margin: 10px 0;
}

.paragraph,
.paragraph:first-child,
.paragraph:last-child {
    width: 100%;
    float: left;
    font: 400 15px/22px "Rubik", sans-serif;
    color: #000;
    margin: 0 0 20px;
}

.tgWidgetDashboard {
    display: flex;
    align-items: center;
}

.tgWidgetDashboard>p {
    width: auto;
    padding: 0 15px 0 0;
    font: 400 15px "Rubik", sans-serif;
}

.tgWidgetDashboard .ui.dropdown {
    height: 22px;
    font: 400 15px "Rubik", sans-serif !important;
}

.ui.modalLayout.scheduleEmailDeliveryModal {}

.ui.modal.scheduleEmailDeliveryModal .ui.dropdownGroup {
    height: 24px;
}

.ui.modal.scheduleEmailDeliveryModal .ui.dropdownGroup .text {
    color: #666666;
    font: 400 15px "Rubik", sans-serif;
}

.ui.button.buttonIcon.buttonActivityTrigger,
.ui.button.buttonIcon.buttonActivityTrigger:focus {
    margin: 0 0 0 auto;
    float: right;
    font: 400 13px "Rubik", sans-serif;
    color: #667581 !important;
}

.scheduleEmailWrap {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
}

.scheduleEmailColumn {
    flex: 1;
}

.scheduleEmailColumn:last-child {
    flex: 2;
    padding: 0 0 0 40px;
}

.tgAlignTop {
    align-items: flex-start;
}

.scheduleEmailColumn .textGroup>b {
    width: 80px;
    min-width: 80px;
    margin: 0;
}

.scheduleEmailColumn .textGroup .ui.dropdown {
    height: 24px;
}

.scheduleEmailColumn .textGroup .ui.dropdown .text {
    color: #666666;
    font: 400 15px "Rubik", sans-serif;
}

.scheduleEmailColumn .ui.input input {
    border-radius: 0;
    height: 24px;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
}

.scheduleEmailColumn .ui.input input::placeholder,
.inputScheduleDate input::placeholder,
.scheduleEmailColumn textarea::placeholder {
    color: #666 !important;
}

.scheduleEmailColumn textarea {
    height: 50px;
    border-radius: 0;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
    resize: none;
}

.scheduleEmailColumn2 .textGroup>b {
    width: auto;
    flex: 1;
}

.scheduleEmailColumn2 .textGroup .textGroupContent {
    width: 215px;
    padding: 0 0 0 10px;
}

.ui.input.inputScheduleDate {
    position: relative;
}

.ui.input.inputScheduleDate>img {
    position: absolute;
    right: 0;
    bottom: 6px;
}

.ui.input.inputScheduleDate>input {
    border-radius: 0;
    height: 24px;
    border: none;
    border-bottom: solid 1px #d3d2de;
    font: 400 15px "Rubik", sans-serif;
    padding: 0;
    width: 100%;
    padding: 0 30px 0 0;
}

.messageBox {
    width: 100%;
    float: left;
    min-height: 36px;
    font: 400 15px "Rubik", sans-serif;
    background: #f3f6fb;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    padding: 8px 10px;
    margin: 0 0 20px;
}

.scheduleEmailRow {
    width: 100%;
    float: left;
    margin: 0 0 15px;
    display: inline-flex;
    align-items: center;
}

.scheduleEmailRow .textGroupContent {
    width: auto;
}

.scheduleEmailRow .textGroup {
    width: auto;
    margin: 0;
}

.scheduleEmailRow .textGroup>b {
    width: auto;
    margin: 0 10px 0 0;
    white-space: nowrap;
}

.tgOccurance .ui.input {
    width: 130px;
    margin: 0 40px 0 0;
}

.tgOccurance .ui.input input {
    padding: 0 25px 0 0 !important;
}

.tgOccurance .ui.dropdownGroup {
    width: 85px;
}

.timezonePara {
    font: 400 15px "Rubik", sans-serif;
    color: #969db8;
    margin: 0 0 0 20px;
}

.scheduleEmailRow2 .textGroup {
    width: 260px;
    margin: 0 80px 0 0;
}

.scheduleEmailRow2 .ui.dropdownGroup {
    width: 180px;
}

.ui.buttonIcon.buttonActivityBack {
    margin: -3px 6px 0 0 !important;
}

.activityTablebox {
    width: 100%;
    float: left;
}

.activityTableRow {
    width: 100%;
    float: left;
    min-height: 40px;
    margin: 0 0 6px;
    display: flex;
    align-items: center;
    border: solid 1px #d3d2de;
    border-radius: 3px;
}

.tableColumn {
    min-width: 25%;
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 10px;

    font: 400 15px "Rubik", sans-serif;
}

.tableColumn:first-child,
.tableColumn:nth-child(3) {
    min-width: 19%;
}

.tableColumn:nth-child(2) {
    min-width: 34%;
}

.tableColumn:last-child {
    min-width: 28%;
}

.tableRowHead {
    border: none;
    border-radius: 0;
    min-height: 0;
}

.tableRowHead .tableColumn {
    padding: 4px 10px;
    font-size: 12px;
}

.activityStatus {
    flex: 1;
}

.activityActions {
    min-width: 60px;
}

.activityActions .ui.buttonIcon {
    margin: 0 0 0 10px !important;
}

.ui.modal.customDashboardCreatedModal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.confirmationCheck {
    width: 143px;
    height: 143px;
    border-radius: 200px;
    background: #2ecc71;
    border: solid 20px #eaf9f0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.modal.customDashboardCreatedModal .ui.header {
    font: 400 24px "Rubik", sans-serif;
}

.ui.modal.customDashboardCreatedModal .paragraph {
    max-width: 400px;
    margin: 30px 0 80px;
}

.ui.modal.customDashboardCreatedModal .ui.button {
    padding: 5px 40px;
}

.ui.button.deleteWidgetButton {
    position: absolute;
    right: 16px;
    top: 18px;
    z-index: 10;
    background: #fff;
    padding: 2px !important;
}

.widgetboxEmpty {
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
}

.ui.button.addWidgetButton {
    background: none !important;
    width: 100%;
    border: solid 1px #d3d2de !important;
    border-radius: 2px;
    margin: 30px 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: 400 15px "Rubik", sans-serif;
    color: #667581 !important;
}

.ui.button.addWidgetButton img {
    margin: 0 0 10px;
}

.defaultWrap {
    width: 100%;
    float: left;
}

.ui.grid.defaultboxCoverGrid .defaultBoxCover {
    margin: 0 !important;
}

.ui.grid.defaultboxCoverGrid .defaultBox,
.ui.grid.defaultboxCoverGridRegular .defaultBox {
    width: 100%;
    float: left;
    height: 100%;
}

.ui.grid.defaultboxCoverGrid>.column,
.ui.grid.defaultboxCoverGridRegular>.column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: inline-flex;
}

.ui.grid.defaultboxCoverGridRegular>.column {
    margin: 0 0 30px !important;
}

.ui.grid.defaultboxCoverGrid>.column.moveableBox {
    cursor: move;
}

.ui.grid.sortableOverview .column.wide {
    position: relative;
}

.ui.grid.sortableOverview .column.wide .ui.button.deleteWidgetButton {
    top: 19px;
    right: 19px;
}

.ui.grid.defaultboxCoverGrid>.ui.grid {
    flex: 1;
}

.noPadding {
    padding: 0 !important;
}

.circleChartHeighWrapper {
    height: 500px;
    position: relative;
    top: -120px;
}

.overflowHide {
    overflow: hidden !important;
}

.height460 {
    height: 460px;
}

.textCenter {
    text-align: center;
}

#info {
    position: absolute;
    height: 1px;
    width: 1px;
    z-index: 100;
}

.tooltip.in {
    opacity: 1;
}

.tooltip.top .tooltip-arrow {
    border-top-color: white;
}

.tooltip-inner {
    border: 2px solid white;
}

/*** welcome modal ***/

.ui.modal.modalWelcome {
    width: 100%;
    max-width: 600px;
    border-radius: 0;
}

.ui.modal.modalWelcome>.header {
    border: none;
    min-height: 200px;
    background: url(/img/modal-pattern.png);
    background-size: 160px auto;
}

.ui.modal.modalWelcome .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 25px 45px 0;
}

.ui.modal.modalWelcome .ui.image {
    width: 100%;
    float: left;
    margin: 10px 0 20px;
}

.ui.modal.modalWelcome .ui.image img {
    display: inline-block;
    width: 50px;
}

.ui.modal.modalWelcome p {
    font: 400 15px/22px 'Rubik', sans-serif;
    color: #000;
}

.ui.modal.modalWelcome>.actions {
    border: none;
    display: flex;
    justify-content: center;
    margin: 10px 0 30px;
    background: none;
}

.ui.modal.modalWelcome>.actions .ui.button {
    padding: 10px 50px;
}
