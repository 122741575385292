.overviewHead {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 1rem;
}

.overviewHeadLeft {
   
    display: flex;
    align-items: center;
}

.overviewHeadLeft h2.ui.header {
    font: 500 18px 'Rubik', sans-serif;
    color: #000;
    margin: 0!important;
    max-width:650px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    
}

.overviewMenu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.overviewMenu .ui.buttonTab {
    float: left;
    background: none!important;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    height: 30px;
    padding: 2px 0 0;
    cursor: pointer;
    margin: 0 0 0 20px;
    border-radius: 0!important;
    transition: all .0 ease;
    border-bottom: solid 3px transparent;
    position:relative;
}

.overviewMenu .ui.buttonTab:first-child {
    margin-left: 0;
}

.overviewMenu .ui.buttonTab:hover {
    color: #727AFF;
}

.overviewMenu .ui.buttonTab.active {
    border-color: #727AFF!important;
}
.overviewMenu .ui.buttonTab > span{
    background:#727AFF;
    position: absolute;
    left:100%;
    top: 3px;
    color: #fff;
    padding: 1px 4px 2px;
    border-radius: 2px;
    font: 500 9px 'Rubik', sans-serif;
    margin-left: 5px;
    pointer-events:none;
}

.overviewMenu li {
    margin: 0 0 0 38px;
}

.overviewMenu li a {
    float: left;
    margin: 0 0 -1px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    height: 30px;
    padding: 5px 0 0;
    cursor: pointer;
}

.overviewMenu li a:hover {
    color: #727AFF;
}

.overviewMenu .active a {
    box-shadow: inset 0 -3px 0 0 #727AFF;
}

.overviewHeadRight {
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.exportDropdown {
    font: 400 15px 'Rubik', sans-serif!important;
    color: #727AFF !important;
    margin-left: 10px !important;
    background: none!important;
    border: none;
    padding: 0!important;
}

.button-anchor {
    color: #727AFF !important;
    background: none!important;
    border: none;
    padding: 0!important;
}