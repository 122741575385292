.fixedHeight {
    min-height: 500px;
}

.marginTop {
    margin-top: 2rem;
}

.scroll {
    min-height: 170px;
    max-height: 170px;
    width: 100%;
    padding: 0 0.5rem 0 0;
    /* ↓ was pushing the widgets out of window ↓ */
    overflow-y: auto;
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 220px;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 0 0 5%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .scroll::-webkit-scrollbar {
    display: none;
} */

.infoSeperators {
    display: flex;
}
.csvModalCheckbox{
    position: absolute !important;
    right: 3rem;
}