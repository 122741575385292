.ui.checkboxGroup {
    width: 100%;
    float: left;
    min-height: 18px;
    margin: 0 0 10px;
}

.ui.checkboxGroup label {
    font: 400 13px/19px 'Rubik', sans-serif !important;
    color: #000;
    padding-left: 27px !important;
}

.ui.checkboxGroup:not(.radio) label {
    line-height: 18px !important;
}

.ui.checkbox.checkboxGroup label:before {
    width: 18px;
    height: 18px;
}

.ui.checkbox.checkboxGroup input:focus {
    border: solid 1px #000 !important;
}

.ui.checkbox.checkboxGroup:not(.radio) input:checked~label:after {
    content: '';
    width: 18px;
    height: 18px;
    background: url(/img/icon-checkbox.svg) no-repeat center;
    background-size: 10px auto;
}

.ui.checkbox.checkboxGroup:not(.radio) input:checked~label:before {
    background: #727aff;
    border-color: #727aff;
}

.ui.radio.checkboxGroup label:before,
.ui.radio.checkboxGroup label:after {
    top: 0 !important;
    transition: all 0s ease;
}

.ui.radio.checkboxGroup label:before {
    width: 18px !important;
    height: 18px !important;
    border-radius: 20px !important;
}

.ui.checkbox.checkboxGroup.radio input:checked~label:after {
    width: 18px;
    height: 18px;
}

.ui.checkbox.checkboxGroup.radio input:checked~label:after,
.ui.checkbox.checkboxGroup.radio input:checked~label:focus {
    background: #6f7cff !important;
}

.ui.checkbox.checkboxGroup.radio input:checked~label:before {
    border: solid 1px #6f7cff;
}