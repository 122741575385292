/* ToggleButton.css
.toggle-button {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button.active {
    background-color: #007bff; /* Blueish active color */
   
  
  /* input[type="radio"] {
    display: none;
  }
  
  .slider {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  input[type="radio"]:checked + .slider {
    transform: translateX(30px);
  }
  */ 
  .toggle-button {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
    background-color: #ccc;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 6px;
    transition: background-color 0.3s ease;
  }
  .toggle-button::before{
    position: absolute;
    height: 15px;
    height: 15px;
    content:"°C";
  font-size: 14px;
    left: 3px;
    bottom: 2px;
    color: white;
    
  }
  .toggle-button::after{
    position: absolute;
    height: 15px;
    height: 15px;
    content:"°F";
  font-size: 14px;
    right: 4px;
    bottom: 2px;
    
  }
  
  .toggle-button.active {
    background-color: #727AFF;
    
  }
  
  input[type="radio"] {
    display: none;
  }
  
  .slider {
    position: absolute;
   top:1px;
    left: 3px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
    z-index: 100;
  }
  
  input[type="radio"]:checked + .slider {
    transform: translateX(19px);
  }
  .toogleContentBox{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 420px;
  }
  .toogleContentBox span{
    font-size: 16px;
  }