.error-box{
    width:100%; float:left;
    min-height:54px;
    padding:8px;
    border:solid 1px #e62600;
    border-left-width:5px;
    background:#fcf4f2;
    border-radius:2px;
    font:400 12px 'Rubik',sans-serif;
    color:#a31b00;
}

.error-box .header {
    display: block;
    font-weight: 500;
    font-size:14px;
    margin:0 0 5px;
}