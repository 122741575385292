.defaultBox.hasMessage{
    display:flex;
    flex-direction:column;
}
.defaultBox.hasMessage .bodyDefaultBox{
    min-height:300px;
    flex:1;
    display:flex;
    align-items:center;
    justify-content:center;
}
.widgetMessage{
    display:inline-flex;
    align-items:center;
    padding:20px 0;
}
.widgetMessage .ui.image img{
    width:100%;
}
.widgetMessage:not(.hasError, .verticalMessage) .ui.image{
    width:102px;
}
.widgetMessage:not(.verticalMessage) .ui.image{
    margin:0 25px 0 0;
}
.widgetMessage p{
    flex:1;
    font:400 13px 'Rubik',sans-serif;
    color:#222;
}
.widgetMessage p strong{
    display:block;
    font:500 15px 'Rubik',sans-serif;
    margin:0 0 8px;
}

.widgetMessage.verticalMessage{
    flex-direction:column;
    justify-content:center;
}
.widgetMessage.verticalMessage p{
    text-align:center;
}

.widgetMessage.verticalMessage.hasError  .ui.image{
    width:140px;
    margin:0 0 20px;
}
.widgetMessage.verticalMessage:not(.hasError)  .ui.image img{
    margin-left:-20px;
    margin-right:-20px;
}

.widgetMessage.isFloat{
    padding:20px;
}
.widgetMessage.isFloat .ui.image{
    width:auto;
    background:#fff;
}

.pathAnalyticsSeperator .widgetMessage:not(.hasError, .verticalMessage) .ui.image{
    width:auto;
}
.pathAnalyticsSeperator .widgetMessage:not(.hasError, .verticalMessage) p{
    color:#667581;
}
.pathAnalyticsSeperator .widgetMessage:not(.hasError, .verticalMessage) p strong{
    color:#000;
}

.errorWidgetWrap{
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

.OccupancyChartBoxContainer .errorWidgetWrap .widgetMessage.hasError{
    transform:scale(.6);
    padding:0;
}