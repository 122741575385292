.mapContainer {
    width: 100% !important;
    height: 100%;
}

.mapContainer .ol-viewport {
    z-index: 1;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: -40px;
    right: -130px;
    min-width: 264px;
    font-size: small;
    z-index: 100;
}

.ol-dwell-point-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: -40px;
    right: -130px;
    white-space: nowrap;
    width: auto;
    font-size: small;
}

.ol-point-popup{
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: -40px;
    right: -130px;
    white-space: nowrap;
    width: auto;
    font-size: small;
}

.heatMapInfo {
    height: calc(100vh - 340px);
    display: inline-flex;
    position: absolute;
    right: 80px;
    top: 130px;
    z-index: 10;
}

.hmileft {
    width: 16px;
    float: left;
}

.hmi {
    flex: 1;
    width: 100%;
    height: 20%;
    float: left;
    display: inline-flex;
    flex-direction: column;
    margin: 0 0 1px;
}

.hmi:last-child {
    margin: 0;
}

.hmi span {
    width: 100%;
    height: 100%;
}

.hmiright {
    padding: 0 0 0 5px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.hmiright span {
    font: 500 12px 'Rubik', sans-serif;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "✖";
}




.ui.button.buttonIcon.triggerTagInfo,
.ui.button.buttonIcon.triggerTagInfo:focus {
    background: #fff !important;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2) !important;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.button.buttonIcon.triggerTagInfo:hover {
    background: #727AFF !important;
}

.ui.button.buttonIcon.triggerTagInfo:hover img {
    filter: saturate(0) brightness(100);
}

.ui.popup.popupTagInfoLegend {
    top: auto !important;
    left: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 126px;
    overflow-x: scroll;
    border: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    padding: 10px 0 10px 10px;
}

.ui.popup.popupTagInfoLegend .ui.header {
    font: 600 15px 'Rubik', sans-serif;
    color: #333;
    border-bottom: solid 1px #D3D2DE;
    padding: 0 0 10px;
}

.ui.popup.popupTagInfoLegend p {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ui.popup.popupTagInfoLegend p span {
    width: 14px;
    min-width: 14px;
    height: 14px;
    border-radius: 100px;
    background: #4F5051;
    margin: 0 10px 0 0;
}