.ui.menu.header-bar {
    height: 62px;
    background: #fff !important;
    box-shadow: 0 2px 5px #ced3e2 !important;
    margin: 0;
    z-index: 20;
    position: relative;
}

.ui.menu.header-bar .container>a:first-child {
    padding: 0;
    margin-left: 20px !important;
    margin-top: -14px !important;
}

.ui.menu.header-bar .item:first-child img {
    width: 155px;
}

.ui.menu.hb-campaign .ui.fluid.container {
    justify-content: center;
}

.ui.menu.header-bar .item,
.ui.menu.ui.menu.hb-campaign .item:first-child {
    margin: 0 15px !important;
}

.ui.menu.header-bar .item {
    background: none !important;
    padding: 12px 5px 7px !important;
    position: relative;
}

.ui.menu.header-bar .item a {
    font: 500 14px/9px 'Rubik', sans-serif;
    text-transform: uppercase;
    color: #000;
    float: left;
}

.ui.menu.header-bar .active.item a,
.ui.menu.header-bar .item a:hover {
    color: #f15944;
}

.ui.menu.header-bar .item span {
    position: absolute;
    right: -33px;
    top: 3px;
    background: #29ad73;
    color: #fff;
    padding: 3px 5px;
    border-radius: 2px;
    font: 500 11px 'Rubik', sans-serif;
}

.ui.menu.header-bar .item span.grey {
    background: grey !important;
}

.ui.menu.header-bar .ui.container>.item span {
    right: auto;
    left: 100%;
    white-space: nowrap;
}

.ui.menu.header-bar .right.menu {
    align-items: center;
}

.ui.menu.header-bar .right.menu .item {
    padding: 5px;
    background: none !important;
    margin: 0 10px !important;
}

.ui.menu.header-bar .right.menu .item span:not(.ui) {
    min-width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #F15944;
    font: 500 12px/16px 'Rubik', sans-serif;
    text-align: center;
    color: #fff;
    padding: 0 3px;
    position: absolute;
    top: 2px;
    right: 3px;
}

.ui.menu.header-bar .right.menu .item img {
    width: auto;
}

.ui.menu.header-bar .right.menu .item:nth-last-child(2) img {
    width: 20px;
}

.ui.menu.header-bar .right.menu .item>i.icon {
    font-size: 20px;
    color: #727aff;
    margin: 0;
}

.ui.menu.header-bar .right.menu a:hover {
    opacity: 0.8;
}

@media (max-width:768px) {

    .ui.menu.header-bar .item,
    .ui.menu.ui.menu.hb-campaign .item:first-child {
        margin: 0 !important;
    }

    .ui.menu.header-bar .right.menu .item {
        margin: 0 5px !important;
    }
}