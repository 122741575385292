.paginationBar{
    width:100%; float:left;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
}

.displayText{
    position:absolute;
    right:75px;
    top:7px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.displayText strong{
    font-weight:600;
}

.ui.pagination.menu.paginationDashboard{
    background:none;
    box-shadow:none;
    border:none;
    min-height:0;
}

.ui.pagination.menu.paginationDashboard .item{
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    outline:0;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-right:none;
    min-width:0!important;
    width:33px;
    height:33px;
    padding:0;
    display:flex;
    align-items:center;
    justify-content:center;
}
.ui.menu.menu.paginationDashboard .item:before{
    display:none;
}
.ui.pagination.menu.paginationDashboard .item:first-child{
    border-radius:4px 0 0 4px;
    font-size:14px;
}
.ui.pagination.menu.paginationDashboard .item:last-child{
    border-radius:0 4px 4px 0;
    border-right:solid 1px #D3D2DE;
    font-size:14px;
}

.ui.menu.menu.paginationDashboard .item.disabled{
    background-color: transparent!important;
    color: rgba(40,40,40,.3)!important;
}
.ui.menu.menu.paginationDashboard .active.item{
    background:#727AFF;
    border-color:#727AFF;
    color:#fff;
    padding:2px 0 0!important;
}

.paginationBar .ui.dropdownGroup{
    min-width:0!important;
    min-height:0!important;
    max-width:65px;
    margin:0;
    position:absolute;
    right:0;
    border-radius:3px!important;
    border:solid 1px #D3D2DE!important;
    background:#fff!important;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0 10px;
}
.paginationBar .ui.dropdownGroup >.text{
    margin:4px 0 0 5px;
}
.paginationBar .ui.dropdownGroup > .icon{
    margin:5px 8px 0 0!important;
}