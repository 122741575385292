.insightsBody {
    width: 100%;
    float: left;
    height: calc(100dvh - 63px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.insightsZoneMapContainer {
    width: 100%;
    float: left;
    height: 100%;
}

.insightsLegendContainer {
    position: absolute;
    bottom: 50px;
    right: 15px;
    z-index: 10;
}

.insightsSessionUIDContainer {
    position: absolute;
    bottom: 0;
    right: 15px;
    z-index: 10;
    font: 400 13px 'Rubik', sans-serif;
}

.insightsLegend {
    width: 135px !important;
    position: relative;
    padding-top: 15px !important;
}

.insightsZoneBoxContainer {
    width: 100%;
    flex: 1;
    float: left;
    overflow: hidden;
    position: relative;
}

.insightsZoneBoxContainer .mapContainer .toggleSliderBox {
    top: 70px;
    right: 20px;
}

.insightsZoneBoxContainer .ol-control .map-attribution {
    margin: 0 0 -2px 10px;
    position: relative;
}

.insightsZoneBoxContainer .ol-control .map-attribution p {
    margin: 0;
}

.insightsChartWrap {
    width: 100%;
    height: 200px;
    float: left;
    display: flex;
    border-top: solid 1px #e1e1e1;
    background: #fff;
    padding: 10px 20px;
}

.insightsContainerWrap {
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    border-top: solid 1px #e1e1e1;
    background: #fff;
    padding: 10px 20px;
}

.insightsChartWrap .insightsLoadingMessage {
    width: 100%;
    max-width: 410px;
}

.insightsChartbox {
    width: 100%;
    float: left;
    border: solid 1px #e1e1e1;
    border-radius: 10px;
    display: flex;
    position: relative;
}

.insightsLegend .ui.image.infoButton {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
}

.insightsChartPopup {
    width: 300px;
    min-width: 300px;
    float: left;
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.insightsChartItem {
    width: 50%;
    float: left;
    padding: 10px;
    font: 400 13px 'Rubik', sans-serif;
    color: #555;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}


.insightsChartItem .ui.image.infoButton {
    margin: 0 0 0 5px !important;
}

.insightsChartItem strong {
    min-width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin: 5px 0 0;
}

.insightsChartItem span {
    font-weight: 500;
    font-size: 12px;
    color: #000;
}

.insightsChartWrapper {
    position: relative;
    height: 180px;
    width: calc(100% - 300px);
    padding: 10px;
    margin-left: 20px;
    font: 400 12px 'Rubik', sans-serif;
}

.insightsChartWrapper strong {
    font-size: 500;
}

.userSessionDropdown {
    max-width: 250px;
    float: left;
    margin: 0 0 0 10px;
    display: inline-flex;
    align-items: center;
    font: 500 13px 'Rubik', sans-serif;
}

.userSessionDropdown>p {
    font: 500 13px 'Rubik', sans-serif;
    padding-right: 10px;
    margin: 0;
}

.userSessionDropdown .ui.dropdown.dropdownGroup {
    min-width: 150px;
    max-width: 250px !important;
    height: 25px;
    display: inline-flex !important;
    align-items: center;
    padding: 0 20px 0 0 !important;
    background: #fff !important;
    position: relative;
    z-index: 1;
}

.userSessionDropdown .ui.dropdown.dropdownGroup>.text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userSessionDropdown .ui.dropdown.dropdownGroup>.icon {
    position: absolute;
    margin: 0;
    right: 0;

}

.userSessionDropdown .ui.dropdown.dropdownGroup .menu>.item {
    width: 100% !important;
    float: left !important;
    color: #000 !important;
}

.userSessionDropdown .ui.dropdown.dropdownGroup .menu>.item .text {
    max-width: 100%;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width:1024px) {

    .insightsChartWrap {
        height: 300px;
    }

    .insightsChartbox {
        display: flex;
        flex-direction: column;
    }

    .insightsChartPopup {
        width: 100%;
    }

    .insightsChartItem {
        flex: 1;
        padding: 5px 10px;
        flex-direction: column;
    }


    .insightsChartWrapper {
        position: relative;
        height: 180px;
        width: 100%;
        padding: 10px;
        margin-left: 20px;
        font: 400 12px 'Rubik', sans-serif;
    }
}

.insightsLoadingMessage {
    margin: auto;
    width: 10%;
}

/*** insights leftbar ***/

.insightsLeftbar {
    width: auto;
    max-width: 310px;
    position: fixed;
    left: 0;
    z-index: 2;
    top: 110px !important;
}

.insightsLeftbarWhenSessions {
    max-width: 800px;
    width: auto;
    position: fixed;
    left: 0;
    z-index: 2;
    top: 110px !important;
}

.ui.button.triggerToggleInsights {
    background: #fff !important;
    box-shadow: 7px 0px 7px rgba(0, 0, 0, .1) !important;
    border-radius: 0 100px 100px 0;
    position: absolute;
    right: -60px;
    top: 20px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.button.triggerToggleInsights i.icon {
    color: #727AFF;
    margin: 0 2px !important;
}

.insightsLeftbar:not(.minimizedSidebar) .ui.button.triggerToggle img {
    transform: rotate(180deg);
}

.sidebarInsights {
    float: left;
    min-width: 310px;
    width: 20%;
    height: 40vh;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #c0cbda;
    padding: 10px;
    margin: 10px 0 0 10px;
}


.sidebarInsights>.ui.image.infoButton {
    float: right;
    margin: 0 0 10px !important;
}

.sidebarInsights>.ui.popup.infoPopup {
    top: -10px !important;
    white-space: nowrap;
    width: auto !important;
}

.dataTableboxInsights {
    width: 100%;
    float: left;
    padding: 0;

}

.dataTableboxInsights .tableboxItemRow {
    border: none !important;
    margin: 0;
}

.dataTableboxInsights .tableboxItemRow.selected {
    border: solid 1px #727AFF !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn {
    border: none;
    font: 400 13px 'Rubik', sans-serif;
    padding: 5px 10px;
    height: auto;
    min-height: 0;
    min-width: 150px;
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn strong {
    font-weight: 500;
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn.ctableColumn {
    min-width: 100px;
}

.visitSessionStatusColumn {
    min-width: 76px !important;
    width: 76px !important;
    justify-content: center !important;
}

/* Insights Session Toggle CSS start */

.sessionInsightsCover {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
}

.sessionInsightsCover .rectangle-toggler {
    background: none;
    border: none !important;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item {
    background: #fff;
    font: 400 14px 'Rubik', sans-serif;
    cursor: pointer;
    border: solid 1px #e1e1e1;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item.active {
    background: #007bff;
    border: solid 1px #007bff;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item:first-child {
    border-right: none;
}

.sessionInsightsCover .rectangle-toggler .rectangle-toggler-item:last-child {
    border-left: none;
}

.sidebarInsightsSessions {
    min-width: 500px;
    width: 45%;
    min-height: 50vh
}

.sidebarInsightsSessionsWhenSession {
    min-width: 800px;
    width: 45%;
    min-height: 50vh
}

.sessionList {
    width: 100%;
    float: left;
    border: solid 1px #ccc;
    margin: 0 0 10px;
}

.sessionListselected {
    background: #f2f2f2;
    box-shadow: inset 0px -1px 0 0px #ccc;
}

.sessionListselected .tableboxItemColumn:first-child {
    font-weight: 600;
    letter-spacing: -.4px;
    color: #727AFF;
}

.dataTableSession .tableboxItemRow .tableboxItemColumn {
    min-width: 155px;
}

.dataTableSession .tableboxItemRow .tableboxItemColumn:last-child {
    min-width: 60px;
    width: 60px;
    justify-content: center;
}

.sessionTable {
    padding-top: 8px;
}

.dataTableboxInsights.dataTableSession .tableboxItemRow .tableboxItemColumn:first-child {
    min-width: 300px;
    max-width: 300px;
    padding-right: 0;
}

.dataTableboxInsights.dataTableSession .tableboxItemRow .tableboxItemColumn:nth-child(2) {
    min-width: 90px;
}

/* Insights Session Toggle CSS end */

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn:first-child {
    min-width: 60%;
}

.dataTableboxInsights .tableboxItemRow .tableboxItemColumn:first-child span {
    float: left;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui.popup.top.left.infoPopupleft {
    width: auto !important;
    white-space: nowrap;
    left: auto !important;
    top: -10px;
    right: 10px !important;
}

.ui.popup.top.right.infoPopupleft {
    width: auto !important;
    white-space: nowrap;
    right: auto !important;
    top: -10px;
    left: 10px !important;
}

.ui.popup.top.left.infoPopupLeftWrap {
    max-width: 500px !important;
    left: auto !important;
    top: -10px;
    right: 10px !important;
}

.rightCorner {
    right: 5px;
    position: absolute;
}

.rightCorner.extraPadding {
    right: 25px;
}



.toggleTooltipInsight .ui.fitted.toggle.checkbox {
    width: 34px !important;
    height: 20px;
    margin: 10px 0 0 !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox label {
    padding: 0;
    margin: 0;
    height: 16px;
    width: 34px;
    border-radius: 100px;
    background: none !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox input:checked~label:before {
    background: #737BFF !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox label:before {
    width: 34px;
    height: 16px;
    box-shadow: none !important;
    border: none;
    top: 0px;
    left: 0px;
    background: #737BFF !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox input:checked~label:before {
    background-color: none !important;
    /* filter:saturate(0) brightness(1); */
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox label:after {
    width: 19px;
    height: 19px;
    box-shadow: none !important;
    left: auto;
    right: 15px;
    top: -1px;
    background: url("/img/icon-toggle-insight.svg") no-repeat center #fff !important;
    background-size: 15px 15px !important;
    transition: all .3s ease;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .1) !important;
}

.toggleTooltipInsight .ui.fitted.toggle.checkbox input:checked~label:after {
    background: url("/img/icon-toggle-insight-checked.svg") no-repeat center #fff !important;
    background-size: 15px 15px !important;
    right: 0 !important;
    top: -.5px;
}

.insightsChartWrapError {
    align-items: center;
    justify-content: center;
}

.insightsChartWrapError .widgetMessage.verticalMessage {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 240px;
}

.insightsChartWrapError .widgetMessage.verticalMessage.hasError .ui.image {
    width: 70px;
    margin: 0 10px 10px 0 !important;
}

.insightsChartWrapError .widgetMessage.verticalMessage p {
    margin: 0 0 10px;
    text-align: left;
    flex: 1;
}

.rectangle-toggler {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;

}

.rectangle-toggler-item {
    flex: 1;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, color 0.3s ease, border-bottom 0.3s ease;
}

.rectangle-toggler-item.active {
    background-color: #007bff;
    color: #fff;
}

.insights-data-updatedAt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.ui.container.subHeader-con {
    width: calc(100% - 150px) !important;
    margin-left: 0 !important;
}

.lastUpdatedAtUI {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
}

.live {
    position: absolute;
    right: 130px;
    padding: 3px 5px;
    border-radius: 2px;
    font: 500 11px 'Rubik', sans-serif;
    cursor: pointer;
}

.sessionInsightsCover>.infoButton {
    margin: 0 !important;
}

.insightsBody>.insightsZoneBoxContainer>.mapContainer>.toggleSliderBox {
    right: 0;
}

.insightsBody>.placeholderBox>.placeholderCover>.children {
    height: 85%;
}

.insights-property-dwn {
    max-width: 400px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-disabled {
    opacity: .5 !important;
    cursor: not-allowed !important;
}

.info-button-padding-right {
    display: inline;
    padding-right: 10px !important;
}

.live-wrapper {
    margin-top: -17px !important;
}
