h3.ui.header {
    width: 100%;
    float: left;
    font: 500 15px 'Rubik', sans-serif;
    color: #000;
}

.containerFluid {
    width: 100%;
    max-width: 1366px;
    padding: 0 35px;
    margin: 0 auto;
}

.containerFluidMap {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding: 0px 35px;
}


.ui.button.buttonIcon.triggerUserFlow {
    width: 100%;
    float: left;
    height: 47px;
    padding: 0 10px 0 0;
    background: #F4F7FC;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ui.button.buttonIcon.triggerUserFlow img {
    transform: rotate(180deg);
}

.collapsedUserFlowSidebar .ui.button.buttonIcon.triggerUserFlow img {
    transform: rotate(0deg) !important;
}

.ui.button.buttonIcon.triggerUserFlow:hover,
.ui.button.buttonIcon.triggerUserFlow:focus {
    background: #F4F7FC !important;
}

.ui.dropdown .menu .active.item {
    font-weight: 500;
}

.userFlowContainer .overviewHead.overviewReport {
    border-bottom: solid 1px #D3D2DE;
}

.userFlowContainer .overviewHeadLeft {
    border-bottom: none;
    padding-left: 35px;
}

.userFlowTableSidebar {
    background: #fff;
    min-width: 232px;
    width: 232px;
    padding: 0;
    border:solid 1px #D3D2DE;
}

.ui.button.buttonIcon.triggerUserFlow {
    justify-content: flex-end;
}

.collapsedUserFlowSidebar {
    min-width: 80px !important;
    width: 80px !important;
}

.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup {
    padding: 10px 14px;
}
.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup > a{
    width:100%;
    float:left;
}

.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup .ui.button {
    display: flex;
    text-indent: -99999px;
    justify-content: center;
    height: 50px;
}

.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup .ui.button img {
    margin: 0;
    width: 20px;
}
.collapsedUserFlowSidebar .ui.vertical.buttonGroup.userflowGroup .ui.button.iconBoostInfo img{width:13px;}

.collapsedUserFlowSidebar .ui.button.buttonIcon.triggerUserFlow {
    justify-content: center;
    padding: 0;
}


.ui.popup {
    border: solid 1px #D3D2DE;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font: 400 13px/16px 'Rubik', sans-serif;
    color: #000;
}

.ui.popup a:hover {
    text-decoration: underline;
}

.ui.popup.infoPopup {
    width: 300px;
}

.ui.infoButton {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #D3D2DE !important;
    display: inline-block;
    cursor: pointer;
}

.ui.infoButton img {
    float: left;
    margin: 2px 0 0 4px;
}

.flexibleRow {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    margin: 0 0 15px;
}

.flexibleRow p {
    margin: 0;
}

.gapBetween p {
    max-width: 45%;
}

.gapBetween p:first-child {
    margin-right: auto;
}

.ui.button {
    min-width: 0;
    min-height: 0;
}

.ui.buttons .ui.button {
    padding: 0;
    min-width: 30px;
    min-height: 30px;
}

.ui.positive.button, .ui.negative.button, .ui.secondary.button, .ui.primary.button {
    width: auto;
    min-width: 100px;
    min-height: 36px;
    border-radius: 4px;
    font: 600 13px 'Rubik', sans-serif !important;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.positive.button {
    background: #29AD73 !important;
}

.ui.secondary.button {
    background: #969DB8 !important;
}

.ui.primary.button {
    background: #F15944 !important;
}

.ui.negative.button {
    background: #C73F3F!important;
}

.ui.basic.primary.button{
    background:none!important;
    border:solid 1px #F15944!important;
    box-shadow:none!important;
    color:#F15944!important;
}

.ui.button.buttonLink {
    margin: 0;
    padding: 0;
    background: none !important;
    color: #333 !important;
    font: 400 13px 'Rubik', sans-serif;
}

.ui.button.buttonLink:hover {
    text-decoration: underline;
}

.ui.closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    min-width: 0 !important;
    width: 17px;
    height: 17px !important;
    padding: 0 !important;
    background: none !important;
    opacity: 1;
    cursor: pointer;
    z-index: 10;
}

.ui.closeBtn:active {
    box-shadow: none !important;
}

.ui.closeBtn:before,
.ui.closeBtn:after {
    position: absolute;
    left: 7px;
    top: 0;
    content: '';
    height: 17px;
    width: 2px;
    background-color: #7a82ff;
}

.ui.closeBtn:before {
    transform: rotate(45deg);
}

.ui.closeBtn:after {
    transform: rotate(-45deg);
}

.ui.closeBtn:hover {
    opacity: 0.8;
}

.ui.buttonGroup {
    display: inline-flex;
    margin: 0 10px;
}

.ui.buttonGroup .ui.button {
    background: #fff;
    border: solid 1px #D3D2DE;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.buttonGroup .ui.button .icon {
    margin: 0 !important;
}

.ui.buttonGroup>.ui.button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: solid 1px #D3D2DE;
}

.ui.buttonGroup>.ui.button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.ui.buttonGroup>div .ui.button {
    border-radius: 0;
}

.ui.buttonGroup>div:first-child .ui.button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: solid 1px #D3D2DE;
}

.ui.buttonGroup>div:last-child .ui.button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.ui.vertical.buttonGroup {
    width: 100%;
}

.ui.vertical.buttonGroup .ui.button {
    border: solid 1px #D3D2DE;
    border-bottom: none;
    border-radius: 0;
    justify-content: flex-start;
}

.ui.vertical.buttonGroup .ui.button:first-child {
    border-radius: 4px 4px 0 0;
}

.ui.vertical.buttonGroup .ui.button:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: solid 1px #D3D2DE;
}

.flexible {
    display: flex;
    align-items: flex-start;
}

.p {
    font: 400 15px/24px 'Rubik', sans-serif;
    margin: 0 0 20px;
}

.ui.tooltipPopup {
    white-space: nowrap;
    padding: 8px 10px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, .2);
    font: 400 13px/18px 'Rubik', sans-serif;
}
.ui.popup.tooltipPopup.positionPopup{
    left:-15px!important;
}

.ui.tooltipPopup:before {
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, .1);
}

.ui.left.center.popup:before {
    box-shadow: 1px -1px 0 0 rgba(0, 0, 0, .1);
}

/*** default boxes ***/

.defaultBoxCover {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    margin-bottom: 30px;
}

.defaultBoxCoverIn,
.defaultBox {
    flex: 1;
    max-width: 632px;
    min-width:45%;
}

.defaultBoxCover:not(.defaultCoverAlternate) .defaultBox{
    margin:0 0 30px!important;
}

.defaultBoxCoverIn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.alignedStart {
    display: inline-block;
}

.alignedStart .defaultBox {
    float: left;
}

.defaultBox {
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 20px 25px;
    position: relative;
}

.defaultBoxCoverIn .defaultBox {
    max-width: 100%;
    margin-bottom: 30px;
}

.defaultBoxCoverIn .defaultBox:last-child {
    margin-bottom: 0;
}

.defaultBox>.ui.header {
    width: 100%;
    float: left;
    font: 500 15px 'Rubik', sans-serif;
    color: #000;
    border-bottom: solid 1px #D3D2DE;
    padding: 0 0 4px;
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    position: relative;
}

.heatmapWidget.defaultBox>.ui.header {
    padding: 0 20px 4px 0;
}

.defaultBox>.ui.header>span.subHeader {
    font-weight: 400;
    color: #667581;
    display: inline-flex;
    align-items: center;
}

.defaultBox>.ui.header>span.subHeader:first-child:before {
    content: "|";
    font-size: 12px;
    margin: 0 8px;
    color: #D3D2DE;
}

.defaultBox>.ui.header>span.subHeader:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 10px;
    background: url(/img/icon-arrow-right-grey.svg) no-repeat center;
    margin: 0 10px;
}

.defaultBox>.ui.header>span.subHeader:nth-last-child(2):after {
    display: none;
}

.tagLabel {
    background: #f3f6fb;
    font: 400 12px 'Rubik', sans-serif;
    border-radius: 2px;
    padding: 3px 5px;
    margin: 0 10px 0 0;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.tagLabel .ui.buttonIcon {
    padding: 0;
    margin: 0 0 0 10px !important;
}

.tagLabel .ui.buttonIcon img {
    width: 10px;
}

.defaultBox>.ui.header>span.subHeader .tagLabel:not(:last-child) {
    margin-right: 30px;
}

.defaultBox>.ui.header>span.subHeader .tagLabel:not(:last-child):after {
    content: '';
    background: url(/img/icon-arrow-right-grey.svg) no-repeat center;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -20px;
}
.defaultBoxInfoPopUp{
    margin-right: auto;
}
.HeatMapBoxContainer{
    height: 100vh;
    width: 100vw;
}

.ui.image.infoButton {
    width: 15px !important;
    height: 15px !important;
    margin: 0 0 0 1rem;
    margin-right: auto !important;
    min-width: 0;
}

.ui.image.infoButton img {
    margin: 2.5px 0 0 4.5px;
    width: 6px;
}

/* .ui.popup.headerInfoPopup {
    left: auto !important;
    right: 0 !important;
    top: 16px;
} */

.triggerAppTooltip {
    width: 12px !important;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    margin: 0 !important;
}
.triggerWebTooltip {
    width: 18px !important;
    position: absolute;
    right: 30px;
    top: 8px;
    cursor: pointer;
    margin: 0 !important;
}
.defaultBox .triggerWebTooltip{
    top: 5px;
    right: 5px;
}

.ui.popup.appOnlyTooltip {
    white-space: nowrap;
}
.ui.popup.webOnlyTooltip {
    white-space: initial;
}

.bodyDefaultBox {
    width: 100%;
    height: 100%;
    float: left;
}

.zoneBodyCover {
    width: 100%;
    float: left;
}

.para {
    width: 100%;
    float: left;
    margin: 0 0 15px;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
}

.para-under {
    width: 100%;
    float: left;
    margin: 20px 0 0;
    font: 400 13px 'Rubik', sans-serif;
    position: relative;
    color: #667581;
}

.flexible-under {
    display: flex;
    align-items: center;
}

.ui.button.resetButton {
    padding: 5px 10px;
    border-radius: 2px;
    min-width: 54px;
    margin: 0 10px 0 0;
    font: 500 12px 'Rubik', sans-serif;
    color: #727AFF;
    border: solid 1px #727AFF;
    background: none;
}

.para strong {
    font-weight: 500;
    color: #000;
}

.para.dark {
    color: #000;
}

.ui.chartDefault {
    width: 100%;
    float: left;
    text-align: center;
}

.ui.chartDefault img {
    display: inline-block;
}

/** Chart info color **/

.chartInfoCover {
    width: 100%;
    float: left;
}

.chartInfoColorItem {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
}

.chartInfoColorItem>small {
    width: 40px;
    height: 10px;
    background: #667581;
    margin: 0 12px 0 0;
}

.chartInfoColorItem>span {
    margin-left: auto;
    color: #969DB8;
}

/*** comparison ***/

.compareCover {
    width: 100%;
    float: left;
    display: flex;
}

.compareColumn {
    flex: 1;
}

.compareColumn:first-child {
    padding-right: 20px;
    border-right: solid 1px #D3D2DE;
}

.compareColumn:last-child {
    padding-left: 20px;
}

.compareColumn h3.ui.header {
    margin: 20px 0 0;
}

.compareColumn .defaultBox {
    float: left;
    width: 100%;
    margin: 0 0 30px;
}

.compareColumn .overviewDetail {
    flex-wrap: wrap;
    margin-bottom: 0;
}

.compareColumn .overviewDetailBox {
    min-width: 300px;
    margin: 0 0 30px;
}

.compareColumn .headingCover:before {
    content: '';
    width: 20px;
    height: 1px;
    background: #D3D2DE;
    position: absolute;
    bottom: -1px;
    right: -20px;
}

.compareColumn:last-child .headingCover:before {
    bottom: -1px;
    right: auto;
    left: -20px;
}

.boxScroll {
    width: 100%;
    float: left;
    overflow-x: auto;
}

.compareColumn .boxScroll,
.scrolledBox .boxScroll {
    max-width: 577px !important;
}

.compareColumn .boxScroll .ui.chartDefault img,
.scrolledBox .boxScroll .ui.image img {
    max-width: 10000px;
}

.compareColumn .defaultBox.independent,
.scrolledBox {
    padding-bottom: 5px;
}

/** set browser compatibility later **/

.boxScroll::-webkit-scrollbar-track {
    background: none;
}

.percentagePara {
    width: 100%;
    float: left;
    margin: 0 0 20px;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.percentagePara .icon {
    font-size: 12px;
    margin-left: 7px;
}

.percentagePara.arrowup {
    color: #29AD73;
}

.percentagePara.arrowdown {
    color: #F15944;
}

.bodyWrap {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
}

/*** direction ***/

.directionUpdate .bodyScroll {
    direction: ltr;
}

.directionUpdate .bodyScroll .containerFluid {
    direction: rtl;
}

.directionUpdate .userRetentionTable .retentionTableCover {
    direction: rtl;
}

.directionUpdate {
    direction: rtl;
}

.directionUpdate .onMoveActions {
    right: auto;
    left: 25px;
}

.directionUpdate .chartInfoCover.vanueCover {
    float: right;
}

@media (min-width: 1200px) {
    .directionUpdate .vanueCover {
        margin-right: 0 !important;
        margin-left: 40px !important;
    }
}

.directionUpdate .chartInfoColorItem>span {
    margin-left: 0;
    margin-right: auto;
}

.directionUpdate .peakHoursRow .peakHoursItem:first-child {
    padding-left: 0;
    padding-right: 15px;
}

.directionUpdate .boxProdiver>.ui.image {
    margin: 0 0 0 30px;
}

.directionUpdate .ui.dropdown.propertyDropdown {
    margin: 0 0 0 10px;
    padding: 0 10px 0 0;
}

.directionUpdate .ui.dropdown.propertyDropdown:first-child {
    padding: 0;
    margin: 0;
}

.directionUpdate .ui.dropdown.propertyDropdown>img {
    margin: 0 0 0 11px;
}

.directionUpdate .ui.dropdown .menu>.item {
    text-align: right;
}

.directionUpdate .propertyComparison .ui.button.buttonIcon {
    border-radius: 0 3px 3px 0;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox {
    border-radius: 3px 0 0 3px;
    border-right: none;
    border-left: solid 1px #D3D2DE;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox label:before {
    margin: 0 0 0 8px;
}

.directionUpdate .propertyComparison {
    margin: 0 20px;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox input~label:after,
.directionUpdate .propertyComparison .ui.toggle.checkbox input:checked~label:after {
    left: auto;
    transition: all 0.3s ease;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox input~label:after {
    right: -.05rem;
}

.directionUpdate .propertyComparison .ui.toggle.checkbox input:checked~label:after {
    right: 20px;
}

.directionUpdate .overviewMenu li {
    margin: 0 38px 0 0;
}

.directionUpdate .reportsMenu li {
    margin: 0 0 0 18px;
}

.directionUpdate .overviewHeadRight {
    padding: 0 15px 0 0;
}

.directionUpdate .ui.dropdownGroup.exportDropdown {
    margin: 0 30px -10px 0;
}

.directionUpdate .ui.dropdownGroup.exportDropdown .menu {
    right: auto;
    left: 0;
}

.directionUpdate .filterDetailItem {
    margin-right: 20px;
}

.directionUpdate .filterDetail .textGroup:first-child {
    margin-right: 0;
}

.directionUpdate .ui.dropdown.filterDropdown .menu>.item,
.directionUpdate .ui.button.dropdown .menu,
.directionUpdate .ui.dropdown.filterDropdown .menu .input>input {
    text-align: right;
}

.directionUpdate .ui.dropdownGroup>.icon {
    float: left !important;
}

.directionUpdate .ui.dropdown>.text {
    float: right;
}

.directionUpdate .headingLine>.ui.infoButton {
    margin-left: 0;
    margin-right: 15px;
}

.directionUpdate .headingCover>.ui.basic.buttonIcon {
    margin-left: 0;
    margin-right: auto;
}

.directionUpdate .headingLine .ui.basic.buttonIcon {
    margin: 0 0 0 3px !important;
}

.directionUpdate .headingLine .ui.basic.buttonIcon img {
    margin: 0 0 0 5px;
}

.directionUpdate .overviewDetailBox .ui.infoButton {
    right: auto;
    left: 9px;
}

.directionUpdate .overviewDetailBox>img.ui.image {
    margin: 5px 0 0 15px;
}

.directionUpdate .contentDetailBox b {
    display: flex;
    align-items: flex-end;
}

.directionUpdate .contentDetailBox b span {
    margin: 0 5px 4px 0;
}

.directionUpdate .percentageDetailBox .icon {
    margin: 0 3px 0 0;
}

.directionUpdate .chartInfoColorItem>small {
    margin: 0 0 0 12px;
}

.directionUpdate .userRetentionColumn {
    padding: 5px 8px 5px 0;
}

.directionUpdate .userRetentionColumn {
    border-right: none;
    border-left: solid 1px #D3D2DE;
}

.directionUpdate .userRetentionColumn:first-child {
    left: auto;
    right: 0;
    padding-right: 12px;
}

/* direction compare */

.directionUpdate .compareColumn:first-child {
    padding: 0 0 0 20px;
    border-right: none;
    border-left: solid 1px #D3D2DE;
}

.directionUpdate .compareColumn:last-child {
    padding: 0 20px 0 0;
}

.directionUpdate .compareColumn:last-child .headingCover:before {
    left: auto;
    right: -20px;
}

.directionUpdate .compareColumn .headingCover:before {
    right: auto;
    left: -20px;
}

/*** spacings ***/

.gap0 {
    margin: 0 !important;
}

.smallMessage {
    display: inline-block;
    margin: 15px 0;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.smallMessage.error {
    color: #F15944;
}

.height100{
    height: 100% !important;
}

/***/

.ui.vertical.buttonGroup.userflowGroup .ui.button {
    min-height: 50px;
    padding: 5px 10px;
    margin: 0 0 20px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    position: relative;
    text-align: left;
    background: #F3F6FB;
    border: solid 1px #C0CBDA;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.ui.vertical.buttonGroup.userflowGroup .ui.button.active {
    font-weight: 500;
    border-color: #F15944;
}

.ui.vertical.buttonGroup.userflowGroup .ui.button img {
    margin: 0 10px 0 0;
}

.overviewHead.dashboardOverviewHead{
    border-bottom:solid 1px #D3D2DE;
    padding-left:0;
    padding-right:0;
}
.dashboardOverviewHead .overviewHeadLeft,
.dashboardOverviewHead .overviewHeadRight{
    border:none;
    padding:0;
}

/*  user retention table */

.userRetentionTableCover {
    width: 100%;
    float: left;
    margin: 20px 0 0;
    padding: 20px 0 0;
    position: relative;
}

.userRetentionTableCover:before,
.userRetentionTableCover:after {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 1px #D3D2DE;
    position: absolute;
    top: 0;
}

.userRetentionTableCover:before {
    left: -25px;
}
.html2canvas-container { width: 1920px !important; height: 2300px !important; }

.userRetentionTableCover:after {
    right: -25px;
}

.userRetentionTable {
    width: 100%;
    float: left;
    border-radius: 2px;
    border: solid 1px #D3D2DE;
    border-top: none;
    background: none;
    position: relative;
}

.userRetentionTable:before,
.userRetentionTable:after {
    content: '';
    width: 1px;
    height: 25px;
    background: #fff;
    position: absolute;
    left: -1px;
    top: 0;
    z-index: 2;
}

.userRetentionTable:after {
    left: auto;
    right: -1px;
}

.userRetentionRow {
    min-width: 100%;
    float: left;
    border-top: solid 1px #D3D2DE;
    display: flex;
}

.userRetentionRow:first-child {
    border-top: none;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}

.userRetentionRow:last-child {
    border-radius: 0 0 2px 2px;
    border-bottom: none;
}

.userRetentionColumn {
    flex: 1;
    min-width: 70px;
    max-width: 70px;
    min-height: 50px;
    padding: 5px 0 5px 8px;
    border-right: solid 1px #D3D2DE;
    display: flex;
    align-items: center;
}

.userRetentionRow:last-child .userRetentionColumn {
    padding-bottom: 10px;
    min-height: 53px;
}

.userRetentionColumn:last-child {
    border: none !important;
}

.userRetentionColumn:first-child {
    min-width: 185px;
    max-width: 185px;
    width: 185px;
    position: sticky;
    left: 0;
    background: #fff;
}

.userRetentionRow:first-child .userRetentionColumn {
    font: 400 12px 'Rubik', sans-serif;
    color: #667581;
    min-height: 20px;
    border: none;
}

.userRetentionRow .userRetentionColumn:first-child b {
    font-weight: 500;
    letter-spacing: -.2px;
}

.userRetentionRow .userRetentionColumn.active {
    background: #EEEEEE;
}

.userRetentionColumn,
.userRetentionColumn p {
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.userRetentionColumn b span,
.userRetentionColumn p span {
    display: block;
    color: #667581;
    font-weight: 400;
    font-size: 13px;
}

.tableScroll>div:last-child {
    top: 47px !important;
}

.retentionTableCover {
    width: 100%;
    float: left;
    position: relative;
}


.alignedWidget {
    display: flex;
    flex-direction: column;
}

.alignedWidget .bodyDefaultBox {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.alignedWidget .percentagePara {
    flex: 1;
}
