.ui.placeholder.placeholderCover{
    max-width:100%;
    height:100%;
}

.placeholderImg{
    width:100%;
    height:100%; float:left;
    border-radius:100px;
}

.placeholderCircular,
.placeholderBox{
    width:100%;
    height:100%;
    float:left;
}
.placeholderBox.noSpace .placeholderCover .image:before{
    display:none!important;
}

.placeholderCircular .ui.placeholderCover{
    border-radius:500px;
}
.ui.placeholderCover{
    position:relative;
}
.ui.placeholderCover .children{
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:50%;
    transform:translate(0, -42%);
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    font:400 15px/24px 'Rubik',sans-serif;
    color:#333;
    text-shadow:1px 1px 1px rgba(255,255,255,.8);
}



/** remove below **/
.devideMargin{
    width:100%; float:left;
    margin:0 0 20px;
}