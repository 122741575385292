.ui.dropdownGroup {
    width: 100%;
    float: left;
    min-height: 0;
    height: 25px;
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: solid 1px #D3D2DE !important;
    font: 400 13px 'Rubik', sans-serif !important;
    color: #000;
    background: none !important;
}

.ui.dropdownGroup>.text {
    color: #000;
}

.ui.dropdownGroup>.icon {
    float: right !important;
    color: #939CA3 !important;
    width: auto;
    margin: 5px 5px 0 0 !important;
}

.ui.dropdownGroup>.menu {
    width: 100%;
    border-radius: 0 0 4px 4px;
}

.ui.dropdownGroup .menu .active.item {
    font-weight: 500;
}