@media (max-width:1350px){
    /* .defaultBoxCoverIn, .defaultBox{
        max-width:48%;
    } */
    .compareColumn .defaultBox{
        max-width:100%;
    }
    .peakHoursItem:last-child{
        min-width:0;
    }
    .compareColumn{
        max-width:50%;
    }
    .compareColumn .overviewDetailBox{
        min-width:48%;
        max-width:48%;
        padding:20px 10px 25px;
    }
}

@media (max-width:1300px){
    .overviewDetailBox{
        padding:20px 10px 25px;
        max-width:280px;
    }
}

@media (max-width:1200px){
    .overviewDetailBox{
        max-width:250px;
    }

    .overviewReport .overviewMenu li{
        margin:0 0 0 15px;
    }

    .reportsMenu li{
        margin:0 10px 0 0!important;
    }
    
    
}

@media (max-width:1100px){
    .overviewHead{
        display:inline-block;
    }
    .overviewHeadLeft,
    .overviewHeadRight{
        width:100%;
        float:left;
        padding:0;
        margin:0 0 10px;
    }
    .overviewHeadRight{
        text-align:right;
    }
    
    .overviewDetailBox{
        max-width:220px;
    }
    .propertyComparison{
        margin:0 6px;
    }

    .sliderTag{
        width:calc(26vw);
    }
    .filterDetail{
        flex-wrap:wrap;
        justify-content:space-between;
    }
    .filterDetail .textGroup{
        max-width:30%;
        margin:0 0 20px;
    }
    .filterDetail .ui.button.buttonLink{
        margin:0 10px 0 0;
        padding:10px 15px;
    }
    .filterDetail .ui.positive.button{
        padding:0 40px;
    }
}

@media (max-width:991px){
    .subheader .ui.container{
        margin:0!important;
    }
    .propertyComparison{
        width:155px;
    }
    .propertyComparison .ui.toggle.checkbox{
        font:500 12px/11px 'Rubik',sans-serif;
    }
    .ui.dropdown.propertyDropdown{
        max-width:20%;
    }


    .overviewMenu li{
        margin-left:15px;
    }

    .directionUpdate .overviewMenu li{
        margin:0 15px 0 0;
    }

    .overviewDetail{
        justify-content:space-evenly;
        flex-wrap:wrap;
    }
    .overviewDetailBox{
        min-width:45%;
        max-width:100%;
        margin:0 10px 20px;
    }
    
    .compareColumn .overviewDetailBox{
        min-width:100%;
        max-width:100%;
        margin:0 0 20px;
    }
}

@media (max-width:910px){
    .overviewReport .overviewHeadLeft{
        display:inline-block;
        border:none;
    }
    .overviewReport .overviewMenu{
        border-bottom:solid 1px #D3D2DE;
        flex-wrap:wrap;
        justify-content:center;
    }
    .overviewReport .overviewMenu li{
        margin:0 15px 0 0;
    }
    .overviewReport .overviewMenu li a{
        height:40px;
        padding:10px 0 0;
    }



    .defaultBoxCover{
        display:inline-block;
        margin:0;
    }
    .defaultBoxCoverIn, .defaultBox{
        max-width:100%;
        width:100%; float:left;
        margin:0 0 30px;
    }
    .scrolledBox .boxScroll{
        max-width:1000px!important;
    }
}

@media (max-width:768px){
    .overviewHead{
        display:inline-block;
    }
    .overviewHeadLeft,
    .overviewHeadRight{
        width:100%;
        float:left;
        padding:0;
        margin:0 0 10px;
    }
    .overviewHeadRight{
        text-align:right;
    }
    
    .directionUpdate .overviewHeadRight{
        padding-right:0;
    }

    .sliderTag{
        width:calc(80vw);
    }

    .filterDetail{
        justify-content:initial;
    }

    .filterDetail .textGroup{
        max-width:100%;
    }

    .filterDetailItem{
        justify-content:space-between;
        width:100%;
    }
    .filterDetailItem .ui.button{
        flex:1;
        max-width:46%;
        margin:0;
    }
    .filterDetail .ui.button.buttonLink{
        background:#ccc!important;
    }
    .filterDetail .ui.positive.button{
        padding:0;
    }
    .flexibleRow,
    .repeatCover{
        display:inline-block;
    }
    .gapBetween p,
    .repeatCover .chartInfoColorItem{
        max-width:100%;
        width:100%; float:left;
        margin:0 0 10px;
    }

    .headingLine .ui.basic.buttonIcon{
        text-align:left;
    }
    .directionUpdate .headingLine .ui.basic.buttonIcon{
        text-align:right;
    }
}

@media (max-width:568px){
    .overviewDetailBox{
        min-width:100%;
        margin:0 0 20px;
    }
    .sliderTag{
        width:calc(74vw);   
    }
}