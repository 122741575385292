.subheader {
    width: 100%;
    float: left;
    height: 47px !important;
    background: #fff;
    box-shadow: 0 2px 5px #ced3e2;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}

.subheader .ui.container {
    width: 100% !important;
    padding: 0 35px 0 12px;
    display: flex;
    align-items: center;
    height: 45px;
}

.ui.dropdown.propertyDropdown {
    float: left;
    width: auto;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    max-width: 300px;
}

.ui.dropdown.propertyDropdown>img {
    order: 1;
    margin-right: 11px;
}

.ui.dropdown.propertyDropdown .text {
    order: 2;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ui.dropdown.propertyDropdown .menu {
    width: 100%;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
    border: none;
}

.propertyComparison {
    width: 210px;
    margin: 0 35px 0 25px;
    display: flex;
    align-items: center;
}

.propertyComparison .ui.button.buttonIcon {
    width: 30px;
    height: 30px;
    border-radius: 3px 0 0 3px;
    border: solid 1px #D3D2DE !important;
    box-shadow: none !important;
    justify-content: center;
}

.propertyComparison .ui.toggle.checkbox {
    flex: 1;
    height: 30px;
    border-radius: 0 3px 3px 0;
    border: solid 1px #D3D2DE;
    border-left: none;
    display: flex;
    align-items: center;
    padding: 0 6px 0 6px;
    font: 400 13px 'Rubik', sans-serif;
}

.propertyComparison .ui.toggle.checkbox label {
    padding: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    color: #667581;
}

.propertyComparison .ui.toggle.checkbox label:before {
    min-width: 37px;
    width: 36px;
    height: 15px;
    top: 0;
    margin-right: 8px;
    background: #D3D2DE !important;
    transition: all 0.5s ease;
    position: relative;
}

.propertyComparison .ui.toggle.checkbox label:after {
    width: 18px;
    height: 18px;
    background: #969DB8;
    box-shadow: none !important;
    top: 2px;
}

.propertyComparison .ui.toggle.checkbox input:checked~label:before,
.propertyComparison .ui.toggle.checkbox input:focus:checked~label:before {
    background: #727AFF !important;
}

.propertyComparison .ui.toggle.checkbox input:checked~label:after {
    left: 20px;
}

.subheader .ui.basic.buttonPropertyToggle {
    font: 400 13px 'Rubik', sans-serif;
    color: #667581 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 10px 0 0;
}

@media (min-width:1700px){
    .subheader .ui.basic.buttonPropertyToggle,
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        max-width:500px;
    }
}

@media (max-width:1650px){
    .subheader .ui.basic.buttonPropertyToggle > span{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }
}

@media (max-width:1300px){
    .subheader .ui.basic.buttonPropertyToggle,
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        max-width:200px;
    }
}

@media (max-width:1100px){
    .subheader .ui.basic.buttonPropertyToggle,
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        min-width:50px;
        max-width:120px;
    }
    .userSessionDropdown{margin-left:0;}
    .dateRangeCover{margin-right:10px;}
}

@media (max-width:770px){
    .userSessionDropdown{
        flex-direction: column;
        align-items: flex-start;
    }
    .insightsBody .subheader > .ui.basic.buttonPropertyToggle{
        align-self:flex-end;
    }
    .userSessionDropdown .ui.dropdown.dropdownGroup{
        min-height:0;
    }
}

.ui.popup.subheaderPropertyPopup {
    width: 230px;
    border-radius: 0;
    border: none;
    padding: 0;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.ui.subheaderPropertyPopup:before {
    display: none !important;
}

.ui.subheaderPropertyPopup .ui.input {
    width: 100%;
    margin: 0;
    padding: 10px;
}

.ui.subheaderPropertyPopup .ui.input>input {
    height: 25px;
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: solid 1px #D3D2DE !important;
    padding: 0 20px 3px 0 !important;
    font: 400 13px 'Rubik', sans-serif !important;
}

.ui.subheaderPropertyPopup .ui.input .icon {
    width: 20px;
    font-size: 12px;
    right: 5px;
}

.searchDropdownCover {
    width: 100%;
    float: left;
    max-width: 290px;
    max-height: 240px;
    overflow-y: auto;
    border-top: solid 1px #D3D2DE;
}

.ui.subheaderPropertyPopup .listItem {
    width: 100%;
    float: left;
    position: relative;
}

.ui.subheaderPropertyPopup .listItem:after {
    content: '';
    border-bottom: solid 1px #D3D2DE;
    width: 95%;
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.listItem.selected {
    background-color: #e6fced;
}

.ui.subheaderPropertyPopup .listItem.selected:after {
    width: 100%;
}

.ui.subheaderPropertyPopup .listItem:last-child:after {
    display: none !important;
}

.ui.subheaderPropertyPopup .ui.basic.titleItem {
    width: 100%;
    float: left;
    padding: 7px 25px 7px 10px;
    font: 400 14px 'Rubik', sans-serif;
    color: #000 !important;
    text-decoration: none;
    text-align: left;
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.ui.subheaderPropertyPopup .ui.basic.listArrow {
    position: absolute;
    right: 0;
    text-decoration: none;
    padding: 9px 5px !important;
}

.ui.subheaderPropertyPopup .ui.basic.listArrow .icon {
    margin: 0 !important;
}

.subheaderBuildingPopup {
    width: 230px;
    background: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 5px;
}

.ui.popup.infoPopup.propertyPopupTooltip{
    width:max-content;
    max-width:400px;
    min-width:150px;
    padding:8px 10px;
    border-radius:4px;
    border:none;
    background:#333;
    color:#fff;
    box-shadow:none;
    pointer-events: none;
    opacity:.8;
}
.ui.bottom.left.infoPopup.propertyPopupTooltip:before{
    border:none;
    box-shadow:none;
    background:#333;

}