.ui.dimmer{
    background:rgba(0,0,0,0.3)!important;
}

.ui.modal.modalLayout{
    width:96%;
    max-width:820px;
    border-radius:0;
    padding:35px 0 38px;
}

.ui.modalLayout .ui.closeBtn:before,
.ui.modalLayout .ui.closeBtn:after{
    background:#000;
}


.ui.modal.modalLayout > .aligner > .header{
    width:100%; float:left;
    font:400 18px 'Rubik',sans-serif;
    color:#000;
    border-bottom:solid 1px #D3D2DE;
    padding:0 0 5px;
    margin:0 0 20px;
}

.ui.modal.modalLayout > .aligner{
    padding:0 38px;
}

.ui.modal.modalLayout > .content{
    padding:0 38px;
    width:100%; float:left;
}
.ui.modal.modalLayout > .actions{
    padding:35px 38px 0;
    margin:0;
    width:100%; float:left;
    border:none;
    background:none!important;
}

.ui.modal.regularModal{
    max-width:576px!important;
    padding-bottom:30px;
}
.ui.modal.regularModal .actions{
    padding-top:15px;
}

.ui.modal.modalLayout > .actions .ui.button.floated.left{
    margin:0;
}