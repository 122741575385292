@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

body {
    background:#F3F6FB !important;
}

.boost-bar{
    height:calc(100vh - 218px)!important;
    border-left:solid 1px #D3D2DE;
}
.bodyScroll > div:nth-last-child(2){ 
    display:none;
}

.tableViewBody .ui.placeholder .image:before{
    display:none!important;
}
.boost-bar > .placeholderBox{
    height:calc(100vh - 222px)!important;
}

.displayNone{
    display: none !important;
}
.durationBox{
    position: relative;
}
.durationBox .hhmmss_formated_text{
    /* position: absolute;
    left: 10rem; */
    font-size: 10px;
    font-weight: 600;
    
}
.ui.input.widgetSearch>input{
    height: 30px;
    padding: 0 10px!important;
    border: solid 1px #D3D2DE!important;
    border-radius: 3px!important;
    font: 400 13px 'Rubik',sans-serif!important;
    color: #000!important;
}
.ExportDropDown{
    width: auto;
    border-radius: 0;
    padding: 2px;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
    color: #727AFF !important;
    font: 400 15px 'Rubik', sans-serif;
    display: inline-flex;
    align-items: center;
}