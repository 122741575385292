.filterSubheader {
    width: 100%;
    background: #fff;
    border-top: solid 1px #bfcbd9;
    box-shadow: 0 2px 6px rgba(215, 215, 215, .8);
    position: fixed;
    top: 109px;
    left: 0;
    z-index: 4;
}

.filterSubheader .ui.container.fluid {
    padding: 0 20px;
    height: 53px;
    display: flex;
    align-items: center;
}

.filterSubheader .textGroup {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0;
}

.filterSubheader .textGroup>p {
    width: auto;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
}

.filterSubheader .ui.inputForm {
    width: 164px;
    margin: 0 20px 0 5px;
}

.filterSubheader .ui.inputForm>img {
    right: 8px;
    top: 8px;
    width: 14px;
}

.filterSubheader .ui.inputForm>input::placeholder {
    color: #000 !important;
}

.filterSubheader .ui.dropdownGroup.button {
    width: 208px;
    height: 30px;
    padding: 0 18px 0 10px !important;
    margin: 0 20px 0 5px;
    border: solid 1px #D3D2DE !important;
    border-radius: 3px !important;
    display: flex;
    align-items: center;
}

.filterSubheader .ui.dropdownGroup.button>.icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 11px;
    color: #8f8f8f;
}

.ui.button.buttonIcon.filtersTrigger {
    font: 500 12px 'Rubik', sans-serif !important;
    text-decoration: underline;
    color: #7279fe !important;
    margin-left: 10px;
}

/*** filter sidebar ***/

.ui.button.right.heatmapFilterSaveButton {
    margin: 10px 0;
    padding: 8px 10px;
    min-width: 70px;
    min-height: 30px;
    background: white;
    z-index: 90000;
    float:left;
    margin:9px 0 0 0;
}

.filterSidebar {
    width: 242px;
    height: calc(100vh - 109px);
    background: #f4f7fc;
    box-shadow: -3px 20px 15px rgba(0, 0, 0, .1);
    position: fixed;
    top: 110px;
    right: 0;
    z-index: 100;
}

.filterSidebarFit {
    width: 242px;
    height: calc(100vh - 165px);
    background: #fff;
    border-left: solid 1px #D3D2DE;
    box-shadow: -3px 20px 15px rgba(0, 0, 0, .1);
    position: fixed;
    top: 165px;
    right: 0;
    z-index: 5;
}

.filterSidebarHeatmap {
    height: calc(100vh - 109px);
    top: 109px;
}

.ui.closeBtn.closeBtnHeatmap {
    width: 15px;
    height: 15px !important;
    margin: 0 !important;
    top: 20px;
}

.ui.closeBtn.closeBtnHeatmap:before,
.ui.closeBtn.closeBtnHeatmap:after {
    background: #000;
    width: 1.5px;
    height: 15px;
}

.filterSidebar .ui.header {
    width: 100%;
    height: 56px;
    float: left;
    margin: 0;
    padding: 3px 0 0 22px;
    background: #fff;
    border-bottom: solid 3px #f5f7f6;
    font: 500 14px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
}

.ui.header.headerFilter {
    width: 100%;
    height: 50px;
    float: left;
    margin: 0;
    border-bottom: solid 11px #F4F7FC;
    font: 500 14px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
}

.accordionFilter {
    width: 100%;
    float: left;
}

.accordionFilter .title {
    width: 100%;
    float: left;
    min-height: 56px;
    padding: 8px 0 8px 22px !important;
    font: 400 14px 'Rubik', sans-serif !important;
    color: #607797 !important;
    display: flex;
    align-items: center;
    position: relative;
}

.accordionFilter .title:before,
.accordionFilter .content.active:before {
    content: '';
    width: 95%;
    height: 1px;
    border-bottom: solid 1px #c6d2e2;
    position: absolute;
    right: 0;
    bottom: 0;
}

.accordionFilter .title.active {
    background: #fff;
}

.accordionFilter .title.active:before {
    display: none;
}

.accordionFilter .title:after {
    content: '';
    width: 11px;
    height: 7px;
    background: url(/img/icon-chevron-down.svg) no-repeat center;
    background-size: 11px 6px;
    filter: saturate(0.3);
    position: absolute;
    right: 20px;
    transition: all .2s ease;
}

.accordionFilter .title.active:after {
    transform: rotate(180deg);
}

.accordionFilter .content {
    width: 100%;
    float: left;
    padding: 10px 0 !important;
    margin: 0 !important;
    background: #fff;
    position: relative;
}

.extraPaddingBottom {
    padding-bottom: 50px !important;
}

.filterAligner {
    width: 100%;
    float: left;
    padding: 0 20px 0 22px;
}

.filterAlignerBottom {
    width: 100%;
    float: left;
    padding: 0 20px 0 22px;
    bottom: 0;
    position: fixed;
    background-color: white;
    z-index: 9000;
    border-top:solid 1px #D3D2DE;
    box-shadow:0 -2px 8px rgba(0,0,0,.1);
}

.radioFilterCheck {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    font: 400 13px 'Rubik', sans-serif;
}

.radioFilterCheck .ui.checkboxGroup {
    width: auto;
    min-width: fit-content;
    margin: 0 10px 0 0 !important;
}

.radioFilterCheck .ui.input {
    width: auto;
    padding: 0 !important;
}

.radioFilterCheck .ui.checkboxGroup label {
    max-width: 105px;
    padding-left: 22px !important;
}

.radioFilterCheck .ui.labeled.input>input {
    min-width: 40px;
    max-width: 44px;
    height: 14px;
    border-radius: 0;
    padding: 0 !important;
    border: none;
    border-bottom: dashed 1px #000;
    margin: -6px ​4px 0 !important;
    text-align: center;
}

.radioFilterCheck .ui.input .label {
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    padding: 0 !important;
    margin: 0 0 0 10px !important;
    background: none;
    height: auto;
}

.ui.button.primary.filterSave {
    min-width: 62px;
    min-height: 22px;
    float: right;
    border-radius: 2px;
    font: 500 12px 'Rubik', sans-serif !important;
    margin: 10px 20px 5px 0;
}

.filterSection {
    width: 100%;
    float: left;
    border-bottom: solid 11px #F4F7FC;
    padding: 20px 0 10px;
}

.filterSection:last-child {
    border: none;
}

.h6 {
    width: 100%;
    float: left;
    margin: 0 0 15px;
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
    text-transform: uppercase;
}

.filterSection .ui.button.primary {
    min-width: 60px;
    min-height: 28px;
    font-size: 12px !important;
}