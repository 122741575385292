.overviewDetail {
    width: 100%;
    float: left;
    margin: 25px 0 22px;
    display: flex;
    justify-content: space-between;
}

.overviewDetailBox {
    flex: 1;
    max-width: 300px;
    background: #fff;
    border-radius: 2px;
    position: relative;
    padding: 20px 10px 25px 25px;
    display: flex;
    align-items: flex-start;
    box-shadow:0 0 15px rgba(0,0,0,.1);
    position:relative;
}
.overviewDetailBox .triggerAppTooltip{
    width:10px!important;
    top:5px;
    right:30px;
}

.overviewDetailBox.disabled {
    opacity: .7;
    filter: saturate(0);
}

.overviewDetailBox .ui.infoButton {
    position: absolute!important;
    top: 9px;
    right: 9px;
    cursor: pointer;
}

.overviewDetailBox>img.ui.image {
    margin-right: 15px;
    margin-top: 5px;
}

.contentDetailBox {
    flex: 1;
}

.contentDetailBox b {
    font: 500 30px 'Rubik', sans-serif;
    color: #000;
    margin-right: 10px;
}

.contentDetailBox b span {
    font: 400 12px 'Rubik', sans-serif;
    color: #969DB8;
}

.contentDetailBox p {
    font: 400 15px 'Rubik', sans-serif;
    color: #969DB8;
}

.contentDetailBox p {
    width: 100%;
    display: inline-block;
}

.contentDetailBox b span {
    margin-left: 4px;
}

.percentageDetailBox {
    align-self: center;
    font: 400 15px 'Rubik', sans-serif;
    margin: 0 5px 0 0;
}

.percentageDetailBox .icon {
    font-size: 12px;
    position: relative;
    margin: 0 0 0 3px;
}

.percentageDetailBox.up, .percentageDetailBox.up .icon {
    color: #29AD73;
}

.percentageDetailBox.down, .percentageDetailBox.down .icon {
    color: #F15944;
}

.ui.popup.infoPopupOverview {
    left: auto;
    right: 0!important;
}

.ui.infoPopupOverview:before {
    display: none!important;
}
.ui.popup.infoPopupOverview.appOnlyTooltip{
    width:170px;
}

/*** web engagement ***/

.engagementWidgetRow .overviewDetailBox{
    /* max-width:240px; */
    padding:20px;
}
.engagementWidgetRow .overviewDetailBox .percentageDetailBox{
    margin:0;
    position: absolute;
    top: 30px;
    right: 20px;
}
.engagementWidgetRow .overviewDetailBox .contentDetailBox b{
    margin:0;
}
.engagementWidgetRow .overviewDetailBox .contentDetailBox b span{margin:0;}














