/*** datepicker ***/

.fixedPosition {
    position: fixed !important;
}

.dateRangeCover {
    width: 206px;
    display: inline-block;
    position: sticky;
}

.dropdownHeatMap {
    margin: 0 0 0 15px !important;
}

.dropdownHeatMap .ui.dropdown {
    margin-right: 0 !important;
}


.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle {
    width: 100%;
    height: 30px;
    padding: 0 7px;
    background: #fff !important;
    border: solid 1px #D3D2DE !important;
    border-radius: 3px !important;
    font: 400 13px 'Rubik', sans-serif !important;
    color: #000 !important;
    transition: all 0.3s ease;
    white-space:nowrap;
}

.dateRangeCover .ui.buttonCalendarToggle img {
    order: 2;
    margin-left: auto;
    margin-right: 0;
    transition: all 0.3s ease;
}

.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle:hover {
    text-decoration: none !important;
    cursor: pointer;
}

.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle.inShow {
    border: solid 1px #727AFF !important;
}

.dateRangeCover .ui.button.buttonIcon.buttonCalendarToggle.inShow img {
    filter: saturate(8) brightness(0.8) contrast(0.9);
}

.dateCalendarHide {
    display: none !important;
}

.dateRangeCover>div {
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 100;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
}

.dateCalendarShow {
    display: block !important;
}

.popup {
    position: fixed !important;
}

.ui.dateRangePopup {
    width: 207px;
    box-shadow: none;
    border: solid 1px #D3D2DE;
    border-radius: 3px;
    padding: 0 !important;
    position: sticky !important;
    z-index: 10 !important;
}

.ui.dateRangePopup:before {
    display: none !important;
}

.ui.dateRangePopup .ui.button {
    width: 100%;
    float: left;
    background: none;
    border-radius: 0;
    font: 400 15px 'Rubik', sans-serif !important;
    padding: 9px 10px 10px 13px !important;
    display: flex;
    align-items: center;
    position: relative;
    color: #000;
    overflow: hidden;
}

.ui.dateRangePopup .ui.button:before {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: solid 1px #D3D2DE;
    position: absolute;
    bottom: 0;
    right: -13px;
}

.ui.dateRangePopup .ui.button:last-child:before {
    display: none;
}

.ui.dateRangePopup .ui.button:last-child {
    border: none;
}

.ui.dateRangePopup>div>div .ui.button:last-child {
    white-space: nowrap;
    padding-right: 20px !important;
}

.ui.dateRangePopup .ui.button:last-child:after {
    content: '';
    background: url(/img/icon-chevron-down.svg) no-repeat right;
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
    filter: saturate(.2) brightness(1.3);
    position: absolute;
    right: 5px;
}

.ui.dateRangePopup .ui.button:hover {
    background: #f0f1fe;
}

.buttonsRange{
    display: inline-flex;
}
.buttonsRange .ui.button{
    height:30px;
    margin:0;
    padding:5px;
    border-radius:0;
    background:#fff!important;
    border:solid 1px #D3D2DE;
    border-radius:0;
    border-right:none;
    display: inline-flex;
    align-items: center;
    font:400 13px 'Rubik',sans-serif;
    color:#969DB8;
    transition:all .2s ease;
}
.buttonsRange .ui.button:hover{
}
.buttonsRange .ui.button:first-child{
    border-radius:2px 0 0 2px;
}
.buttonsRange .ui.button:last-child{
    margin-right:-1px;
    border:solid 1px #D3D2DE;
    border-radius:0 2px 2px 0;
}
.buttonsRange .ui.button img{
    height:15px;
    margin:0 6px 0 0;
    transition:all .2s ease;
}
.buttonsRange .ui.button.active,
.buttonsRange .ui.button.active:hover{
    background:#727AFF!important;
    border-color:#727AFF;
    color:#fff;
}
.buttonsRange .ui.button.active img{
    filter:saturate(0) brightness(10);
}

.dateRangePopup-container{
    display: flex;
}

.dateRangePopup-container >div> .buttonsRange .ui.button:last-child{
    border-right:none;
    border-radius:0
}


