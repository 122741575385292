.ui.basic.buttonIcon:not(.circular) {
    width: auto;
    border-radius: 0;
    padding: 2px;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
    color: #727AFF !important;
    font: 400 15px 'Rubik', sans-serif;
    display: inline-flex;
    align-items: center;
}

.ui.basic.buttonIcon:not(.circular):hover,
.ui.basic.buttonIcon:not(.circular):focus {
    background: none !important;
    color: #727AFF !important;
}

.ui.basic.buttonIcon img {
    margin-right: 5px;
}

.ui.basic.buttonIcon.circular {
    height: 26px;
    padding: 0 10px 0 5px;
    box-shadow: none;
    border: solid 1px #BFBDD3;
    display: flex;
    align-items: center;
    font: 500 13px 'Rubik', sans-serif;
    color: #727AFF !important;
}

.ui.basic.buttonIcon.circular img {
    margin: 0 8px 0 0;
}

.ui.basic.buttonIcon.circular.small {
    font: 500 13px 'Rubik', sans-serif;
}

.ui.basic.buttonIcon:hover {
    text-decoration: underline;
    background: none !important;
    box-shadow: none;
}
.deprecatingIcon {
    height: 15px;
    width: 15px;
    color: white;
    background: rgb(253, 120, 87);
    position: absolute;
    right: -5px;
    top: -5px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 15px; /* Ensure vertical centering */
    text-indent: 0;
}