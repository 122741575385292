.topheadBoost {
    width: 100%;
    height: 47px;
    background: #fff;
    border-bottom: solid 1px #d3d2de;
    border-top: solid 1px #d3d2de;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.ui.header.boostHeader {
    flex: 1;
    font: 600 15px "Rubik", sans-serif;
    display: inline-flex;
    align-items: center;
    margin: 0;
}

.ui.header.boostHeader img {
    margin: 0 0 0 5px;
    width: 12px;
    cursor: pointer;
}

.topheadBoost .ui.header.boostHeader {
    flex: auto;
    display: inline-block;
}

.boostHeadTogglegroup {
    display: inline-flex;
    align-items: center;
    font: 400 14px "Rubik", sans-serif;
    margin: 0 20px 0 0;
}

.ui.checkbox.toggle.toggleBoostHead {
    width: 60px !important;
    height: 30px;
    margin: 0 10px !important;
}

.ui.checkbox.toggle.toggleBoostHead label {
    padding: 0;
    margin: 0;
    height: 29px;
    width: 59px;
    border-radius: 3px;
    border: solid 1px #d3d2de !important;

    background: rgb(114, 122, 255);
    background: linear-gradient(90deg, rgba(114, 122, 255, 1) 50%, rgba(255, 255, 255, 1) 50%);
}

.ui.toggle.checkbox input:checked~label {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(114, 122, 255, 1) 50%);
}

.ui.checkbox.toggle.toggleBoostHead label:before {
    /* background: url(/img/icon-chevron-down.svg) no-repeat center!important; */
    width: 30px;
    height: 30px;
    box-shadow: none !important;
    /* border-radius:3px 0 0 3px; */
    border: none;
    top: -1px;
    left: -1px;

    background: none !important;
}

.ui.checkbox.toggle.toggleBoostHead input:checked~label:before {
    background-color: none !important;
    /* filter:saturate(0) brightness(1); */
}

.ui.checkbox.toggle.toggleBoostHead label:after {
    background-color: none !important;
    width: 30px;
    height: 30px;
    /* border-radius:0 3px 3px 0; */
    box-shadow: none !important;
    left: auto;
    right: -1px;
    top: -1px;
}

.ui.checkbox.toggle.toggleBoostHead input:checked~label:after {
    right: -1px !important;
}

.ui.button.buttonIcon.boostHeadButton {
    width: 30px;
    height: 30px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    border-radius: 3px;
    margin: 0 0 0 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.toggleButtonGroup {
    display: inline-flex;
    margin: 0 10px;
}

.toggleButtonGroup .ui.button.buttonIcon {
    min-width: 30px;
    width: auto;
    height: 30px;
    padding: 0 5px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    border-right: none !important;
    font: 400 14px "Rubik", sans-serif;
    color: #333 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
}

.toggleButtonGroup .ui.button.buttonIcon:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.toggleButtonGroup .ui.button.buttonIcon:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: solid 1px #d3d2de !important;
}

.toggleButtonGroup .ui.button.buttonIcon.active {
    background: #727aff !important;
    border-color: #727aff !important;
    color: #fff !important;
}

.toggleButtonGroup .ui.button.buttonIcon.active img {
    filter: saturate(0) brightness(100);
}

.boostHeadActions {
    display: inline-flex;
    margin: 0 0 0 20px;
}

.boostHeadActions .ui.button.buttonIcon {
    width: 30px;
    height: 30px;
    margin: 0 0 0 10px;
    border-radius: 3px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    font: 400 14px "Rubik", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.playslidebox {
    min-width: 200px;
    display: inline-flex;
    align-items: center;
    border: solid 1px #d3d2de;
    border-radius: 3px;
    background: #fff;
}

.playslidebody {
    flex: 1;
}

.playslidetrigger {
    width: 130px;
    height: 2px;
    margin: 0 10px;
    border-radius: 4px;
    background: #d3d2de;
    position: relative;
}

.playslidetrigger span {
    min-width: 9px;
    width: 9px;
    height: 9px;
    border-radius: 10px;
    background: #727aff;
    position: absolute;
    left: 0;
    top: -4px;
}

.playbuttonsGroup {
    display: inline-flex;
}

.playbuttonsGroup .ui.button {
    width: 30px;
    height: 30px;
    background: #fff !important;
    border: none;
    border-left: solid 1px #d3d2de;
    border-radius: 0;
    padding: 0;
    margin: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.playbuttonsGroup .ui.button img {
    margin: 0;
}

.playbuttonsGroup .ui.button:first-child img {
    transform: rotate(180deg);
}

.playbuttonsGroup .ui.button:last-child {
    border-radius: 0 3px 3px 0;
}

.playbuttonsGroup .ui.button.disabled {
    background: #f1f1ff !important;
    opacity: 1 !important;
}

.playbuttonsGroup .ui.button.disabled img {
    opacity: 0.5;
}

.ui.button:not(.icon)>.icon:not(.button):not(.dropdown),
.ui.button:not(.icon)>.icons:not(.button):not(.dropdown) {
    margin-right: 0px !important;
}

.ui.button.heatmapButton {
    filter: saturate(1) !important;
}

.ui.popup.notificationPopover {
    border: none;
    border-radius: 3px;
    font: 400 14px "Rubik", sans-serif;
    white-space: nowrap;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.ui.popup.notificationPopover:before {
    box-shadow: 0 0 0 0 #bababc !important;
}

.notipopupDateTime {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    padding: 5px 10px;
    position: fixed;
    right: 100px;
    top: 235px;
    z-index: 10;
}

.notipopupDateTime p {
    float: left;
    font: 400 14px "Rubik", sans-serif;
    white-space: nowrap;
    margin: 0;
}

.ui.dropdown.dropdownDayGroup {
    width: auto;
    height: 30px;
    padding: 0 7px;
    margin: 0 15px;
    background: #fff !important;
    border: solid 1px #d3d2de !important;
    border-radius: 3px !important;
    font: 400 13px "Rubik", sans-serif !important;
    color: #000 !important;
    display: inline-flex;
    align-items: center;
}

.ui.dropdown.dropdownDayGroup>.text {
    white-space: nowrap;
}

.ui.dropdown.dropdownDayGroup .dropdown.icon {
    color: #667581 !important;
    font-size: 13px !important;
    margin: 0 0 0 5px !important;
}

.overviewBoost {
    border-bottom: solid 1px #d3d2de;
}

.overviewBoost .reportsMenu {
    padding: 0 0 0 15px;
    justify-content: flex-start;
}

.overviewBoost .overviewHeadLeft {
    border: none;
}

.boostAnalyticsBody .mapContainer .toggleSliderBox {
    top: 70px;
    right: 20px;
}

.boostAnalyticsBody .mapContainer .toggleSliderBox input:checked~label {
    background: none !important;
}

.boostAnalyticsBody .mapContainer .floorButtonsCover {
    top: 110px;
    right: 20px;
}

.userflowGroupBoost a.active .ui.button {
    border-color: #f15944 !important;
}

.boostAnalyticsBody .mapContainer .ol-control {
    width: 100%;
    position: absolute;
    bottom: 50px;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 10px 0;
}

.boostAnalyticsBody .mapContainer .ol-control button {
    cursor: pointer;
    position: relative;
    top: 50px;
    left: 5px;
}

.boostAnalyticsBody .mapContainer .ol-control ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

.boostAnalyticsBody .mapContainer .ol-control ul li {
    display: inline-flex !important;
}

.boostAnalyticsBody .mapContainer .ol-control ul p {
    margin: 0;
    font: 400 13px "Rubik", sans-serif;
}

.boostAnalyticsBody .mapContainer .ol-control.ol-collapsed ul {
    display: none;
}

.userflowGroup.userflowGroupBoost {}

.userflowGroup.userflowGroupBoost>a:last-child .ui.button {
    margin: 0 !important;
}

/*** explainer video modal ***/

.explainerVideoCover {
    width: 100%;
    float: left;
    position: relative;
}

.ui.button.basic.buttonIcon.fullScreenToggle {
    position: absolute;
    right: 18px;
    top: 12px;
    z-index: 9;
    background: #fff !important;
    padding: 4px !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
}

.ui.button.fullScreenToggle img {
    width: 16px;
    transition: all 0.3s ease;
}

.fullScreenWide .ui.button.fullScreenToggle {
    top: 19px;
}

.fullScreenWide .ui.button.fullScreenToggle img {
    filter: invert(1);
}

/*** boost map ***/

.boostMapContainer {
    width: 100%;
    float: left;
    display: flex;
}

.boostMapWrap {
    flex: 1;
    background: #fff;
    border-right: solid 1px #d3d2de;
}

.boostMapCover {
    float: left;
}

.boostMapCover img {
    max-width: 100%;
}

.boostZoneTablebox {
    width: 100%;
    min-height: calc(50vh - 220px);
    padding: 0;
    border-left: solid 1px #d3d2de;
    background: #fff;
    position: relative;
}

.scrollAlignerboost {
    width: 100%;
    float: left;
    padding: 0 15px;
}

.tableSubRow {
    width: 100%;
    float: left;
    flex-direction: column;
}

.tableSubCover {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding: 7px 0;
}

.tableSubCover:not(:first-child) .peakHoursItem {
    font-size: 12px;
    color: #667581;
}

.tableSubCover:nth-child(even) {
    background: #fafafa;
}

.boostNetworkConnectionTablebox .peakHoursItem:first-child {
    min-width: 55% !important;
    flex: 1;
}

.boostNetworkConnectionTablebox .peakHoursRow .peakHoursItem {
    min-width: 35%;
    text-align: left !important;
}

.boostNetworkConnectionTablebox .peakHoursRow .peakHoursItem:last-child {
    min-width: 10%;
}

.boostInfoTablebox .peakHoursTable {
    padding: 0 20px;
    margin-top: 15px;
}

.boostInfoTablebox .peakHoursRow:nth-child(odd):not(:first-child) {
    background: #f3f6fb;
}

.boostInfoTablebox .peakHoursRow .peakHoursItem {
    text-align: left !important;
}

.boostInfoTablebox .peakHoursRow .peakHoursItem:first-child {
    min-width: 37%;
}

.boostInfoTablebox .peakHoursRow:not(:first-child) {
    padding: 0;
    cursor: pointer;
}

.boostInfoTablebox .peakHoursRow .peakHoursItem:nth-child(2) {
    min-width: 10%;
    max-width: 10%;
}

.boostTagTablebox .peakHoursRow .peakHoursItem:first-child {
    min-width: 28%;
}

.boostTagTablebox .peakHoursRow .peakHoursItem:nth-last-child(2) {
    min-width: 22%;
}

.boostInfoTablebox .peakHoursRow.active {
    color: #000 !important;
    border-color: #727aff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.boostInfoDevicesBox {
    margin-top: 10px;
    overflow: scroll;
    padding: 0 20px;
}

.addRemoveDeviceToggle label::before {
    border: solid 1px red !important
}

.addRemoveDeviceToggle label::after {
    background: red !important;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:first-child {
    flex: 1;
    min-width: 22%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(2) {
    min-width: 10%;
    max-width: 10%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(3) {
    min-width: 25%;
    max-width: 25%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(4) {
    min-width: 22%;
    max-width: 22%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:last-child {
    min-width: 18%;
    max-width: 18%;
}

.boostInfoZoneTablebox .peakHoursRow:first-child .peakHoursItem:last-child .peakHoursColumn {
    text-align: center;
}

.boostTabMenus .ui.secondary.pointing.menu .active.item {
    border-color: #f15944;
    color: #f15944;
}

.inputDevicesFilters {
    justify-content: space-evenly;
}

.inputDevicesFilters .ui.primary.button {
    min-width: 70px;
    min-height: 28px;
}


.trippleTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 33.3% !important;
    width: 33.3% !important;
}


.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(3) {
    min-width: 25%;
    max-width: 25%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:nth-child(4) {
    min-width: 22%;
    max-width: 22%;
}

.boostPathTablebox .peakHoursRow .peakHoursItem:last-child {
    min-width: 18%;
    max-width: 18%;
}

.boostInfoZoneTablebox .peakHoursRow:first-child .peakHoursItem:last-child .peakHoursColumn {
    text-align: center;
}

.trippleTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 33.3% !important;
    width: 33.3% !important;
}

.quarterTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 25% !important;
    width: 25% !important;
}

.fiveTablebox .peakHoursRow .peakHoursItem {
    min-width: 15% !important;
    max-width: 20% !important;
    width: 20% !important;
}

/* Boost Zone  */

.boostZoneBoxContainer {
    width: 100%;
    float: left;
    height: calc(100vh - 160px);
    overflow: hidden;
}

.boostZoneMapContainer {
    width: 100%;
    float: left;
    height: calc(100vh - 160px);
}

.boostZoneMapContainer .errorWidgetWrap {
    background: #fff;
}

.boostAnalyticsBody .errorWidgetWrap {
    background: #fff;
}

.bzmPlaceholderCover .ui.placeholderCover .children {
    transform: translate(0, 0);
    top: -35px;
}

.noDiaplay {
    display: none;
}

.boostZoneTablebox .errorWidgetWrap {
    width: 100%;
    height: calc(100vh - 240px);
    justify-content: center;
    align-items: center;
    padding-bottom: 9px;
}

.widgetCoverFlexible {
    float: left;
    width: 100%;
    height: calc(55vh) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widgetCoverFlexible.boostInfo {
    height: calc(25vh) !important
}

.placeholderCover .image {
    height: calc(46vh - 185px) !important;
}

.boost-bar>.OccupancyChartContainer {
    height: calc(100vh - 200px);
    padding: 40px 20px;
    position: relative;
}

.boost-bar>.OccupancyChartContainer>.legendsPopbox {
    height: auto;
    max-height: calc(100vh - 283px);
    width: 157px;
    cursor: pointer;
    overflow-x: scroll;
    bottom: 41px;
}

.boost-bar>.OccupancyChartContainer>div:first-child {
    border: solid 1px #d3d2de;
    padding: 0 0 10px;
}

.OccupancyChartContainer {
    width: 100%;
    float: left;
    height: calc(48vh - 150px);
    background: #fff;
}


.rowBoost {
    width: 100%;
    float: left;
    display: flex;
}

.columnBoost {
    flex: 1;
    width: 50%;
    position: relative;
}

.columnBoost.fullScreenWide {
    height: calc(100vh - 194px);
}

/*** top path ***/

.ui.header.boostWidgetHeading {
    width: 100%;
    float: left;
    font: 600 14px 'Rubik', sans-serif;
}

.ui.dropdown.dropdownTopPath {
    width: 200px;
    min-height: 0 !important;
    height: 25px;
    border-radius: 0;
    margin: 0;
}

.chartPathCover {
    width: 100%;
    float: left;
    padding: 20px;
}

.chartPathRow {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 20px;
}

.chartPathRow2 {
    min-height: 180px;
}

.chartPathDropdown i.icon {
    margin: 2px 5px 0 !important;
}

.chartPathDropdown>.menu {
    width: 200px;
}

.chartPathColumn {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 50px 0 0;
}

.chartPathColumn:last-child {
    padding: 0 0 0 50px;
}

.chartPathCenter {
    width: max-content;
    min-height: 52px;
    background: #667581;
    padding: 15px 20px;
    margin: 10px 0 0;
    font: 400 13px 'Rubik', sans-serif;
    color: #fff;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    align-self: center;

    position: relative;
    z-index: 2;
    margin: 0 -10px;
}

.chartPathItem {
    width: 100%;
    height: 34px;
    background: #2B1A1A;
    font: 400 13px/10px 'Rubik', sans-serif;
    color: #fff;
    padding: 0 10px 0 25px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: all 0.3s ease;
}

.chartPathItem:hover {
    transform: scale(1.01)
}

.chartPathItem:nth-child(2) {
    margin: 41px 0 40px;
}

.chartPathColumn:nth-child(3) .chartPathItem {
    padding: 0 25px 0 10px;
}

.chartPathItem span {
    font-weight: 500;
    border-left: solid 1px #FFFFFF;
    margin: 0 0 0 8px;
    padding: 0 0 0 8px;
}

.chartPathColumn:last-child .chartPathItem {
    flex-direction: row-reverse;
}

.chartPathColumn:last-child .chartPathItem span {
    padding: 0 8px 0 0;
    margin: 0 8px 0 0;
    border-left: none;
    border-right: solid 1px #fff;
}

.chartPathItem:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 0 17px 14px;
    border-color: transparent transparent transparent #fff;

    position: absolute;
    left: 0;
    top: 0;
    transition: all 1s ease;
}

.chartPathItem:hover:before {
    left: -1px
}

.chartPathColumn:last-child .chartPathItem:before {
    transition: all .1s ease;
    transform: scale(1.2)
}

.chartPathColumn:last-child .chartPathItem:hover:before {
    right: -1px;
    top: -3px;
    border-width: 21px 16px 21px 0px;
}

.chartPathColumn:last-child .chartPathItem:before {
    left: auto;
    right: -1px;
    transform: rotate(180deg);
    border-width: 18px 15px 17px 0px;
    border-color: #fff transparent #fff transparent;
}

.chartPathColumn:first-child .chartPathItem .tail,
.chartPathColumn:last-child .chartPathItem .tail {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34px 0 0px 70px;
    border-color: transparent transparent transparent #2b1a1a;
    position: absolute;
    top: 0;
}

.chartPathColumn:first-child .chartPathItem .tail {
    right: -60px;
}

.chartPathColumn:first-child .chartPathItem:first-child .tail {
    transform: skewY(36deg);
    top: 25px;
    right: -70px;
}

.chartPathColumn:first-child .chartPathItem:nth-child(2) .tail {
    transform: skewY(-16.5deg);
    top: -8.5px;
    border-width: 33.5px 0 0px 60px;
}

.chartPathColumn:first-child .chartPathItem:nth-child(3) .tail {
    transform: skewY(-50.5deg);
    top: auto;
    bottom: 42.5px;
    right: -70px;
}

.chartPathColumn:last-child .chartPathItem .tail {
    left: -60px;
}

.chartPathColumn:last-child .chartPathItem:first-child .tail {
    transform: skewY(-36deg) scaleX(-1);
    top: 25px;
    left: -70px;
}

.chartPathColumn:last-child .chartPathItem:nth-child(2) .tail {
    transform: skewY(16.5deg) scaleX(-1);
    top: -8.5px;
    border-width: 33.5px 0 0px 60px;
}

.chartPathColumn:last-child .chartPathItem:nth-child(3) .tail {
    transform: skewY(50.5deg) scaleX(-1);
    top: -42.5px;
    left: -70px;
}

.dropdownPathGroup {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.dropdownPathGroup .textGroup {
    margin: 0;
}

.dropdownPathGroup .textGroup>b {
    margin: -2.5px 10px 0 0;
    align-self: flex-start;
}

.dropdownPathGroup .ui.dropdown.dropdownTopPath {
    width: 100%;
}

.ui.button.swapButton {
    margin: 0 25px !important;
    padding: 5px !important;
}

.boostTrafficFlowRow {
    background: #fff;
    height: calc(100vh - 220px);
}

.boostTrafficFlowRow .columnBoost:last-child {
    border-left: solid 1px #D3D2DE;
    min-width: 580px;
}

@media (max-width:1200px) {
    .chartPathItem {
        font-size: 11px;
        padding: 0 0 0 20px;
    }

    .chartPathColumn:last-child .chartPathItem {
        padding: 0 20px 0 0;
    }
}

.alignerBoostCover {
    height: 75vh;
    width: 100%;
    float: left;
}

/***/

.trafficFlowTablebox {
    max-height: calc(100vh - 256px);
    padding: 0 20px 10px 0;
    margin: 35px 0 0 0;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
}

.trafficFlowTablebox::-webkit-scrollbar {
    width: 10px;
    background-color: #F3F6FB;
}

.trafficFlowTablebox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #BABBCB;
}

.dataTablebox {
    width: 100%;
    float: left;
}

.tableboxItemRow {
    width: 100%;
    float: left;
    min-width: max-content;
    display: flex;
    margin: 0 0 5px;
    background: #fff;
    border-right: solid 1px #D3D2DE;
    border-radius: 0 2px 2px 0;
    position: relative;
}

.tableboxItemColumn {
    flex: 1;
    width: 11%;
    min-width: 120px;
    max-width: 120px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    display: inline-flex;
}

.tableboxItemRow .tableboxItemColumn {
    border-top: solid 1px #D3D2DE;
    border-bottom: solid 1px #D3D2DE;
    align-items: center;
    min-height: 40px;
    padding: 0 5px;
    cursor: pointer;
}

.tableboxItemRowHead {
    width: 100%;
    float: left;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
}

.tableboxItemRowHead .tableboxItemColumn {
    font-size: 12px;
    color: #667581;
    background: #fff;
    padding: 10px 0 5px 6px;
}

.trafficFlowTablebox .tableboxItemRow .tableboxItemColumn:first-child {
    font-size: 12px;
    color: #667581;
    background: #fff;
    border: none;
    padding: 0;
    padding-left: 10px;
}

.trafficFlowTablebox .tableboxItemColumn:first-child {
    position: sticky;
    left: 0;
}

.trafficFlowTablebox .tableboxItemRowHead .tableboxItemColumn:first-child {
    left: 0;
    top: 0;
    display: inline-flex;
    flex-direction: column;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
    padding: 0 10px;
    overflow: hidden;
}

.trafficFlowTablebox .tableboxItemRowHead .tableboxItemColumn:first-child:before {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: solid 1px rgba(0, 0, 0, .2);
    position: absolute;
    top: 22px;
    left: 0;
    transform: rotate(20deg);
}

.tagFrom {
    align-self: flex-end;
    margin: 8px 0 0;
}

.trafficFlowTablebox .tableboxItemRow .tableboxItemColumn:first-child:after {
    content: '';
    width: 2px;
    height: 100%;
    border: solid 1px #D3D2DE;
    border-right: none;
    border-radius: 2px 0 0 2px;
    margin: 0 0 0 auto;
}

.trafficFlowTablebox .tableboxItemColumn:first-child {
    max-width: 100px;
    padding-left: 10px;
}

.tableboxItemRow:nth-child(even) {
    background: #F3F6FB;
}

.tableboxItemRow:nth-child(even) .tableboxItemColumn:first-child:after {
    background: #F3F6FB;
}

.bs-active {
    color: #000 !important;
    box-shadow: 0 0 8px rgb(0 0 0 / 10%);
}

.trafficFlowTablebox .tableboxItemColumn {
    min-width: 110px;
    width: 110px;
    max-width: 110px;
    background: #fff;
}

.trafficFlowTablebox .tableboxItemRow:nth-child(even) .tableboxItemColumn {
    background: #F3F6FB;
}

.trafficFlowTablebox .tableboxItemRow {
    border-right: none !important;
}

.trafficFlowTablebox .bs-active .tableboxItemColumn {
    border-top: 1px solid #727AFF;
    border-bottom: 1px solid #727AFF;
}

.trafficFlowTablebox .tableboxItemRow.bs-active .tableboxItemColumn:first-child {
    border-top: 1px solid #727AFF !important;
    border-bottom: 1px solid #727AFF !important;
}

.trafficFlowTablebox .tableboxItemRow .tableboxItemColumn:last-child {
    border-right: solid 1px #D3D2DE !important;
    flex: 1;
    max-width: 100% !important;
}

.trafficFlowTablebox .tableboxItemRow.bs-active .tableboxItemColumn:last-child {
    border-right: 1px solid #727AFF !important;
}

.fullScreenWide .trafficFlowTablebox .tableboxItemColumn {
    min-width: 130px;
}

/** quick snapshot **/

.quickSnapshotRow {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    background: #f3f6fb;
}

.quickSnapshotColumn {
    width: 50%;
    background: #fff;
    padding: 15px 10px 10px 15px;
    margin: 0 0 10px;
    min-height: 300px;
}

.quickSnapshotRow:last-child .quickSnapshotColumn {
    margin: 0;
}

.quickSnapshotColumn:first-child {
    border-right: solid 10px #f3f6fb;
}

.quickSnapshotRow .widgetCoverFlexible {
    height: 300px !important;
}

.quickSnapshotLiveSpace {
    padding: 15px 2px 10px 15px;
}

.defaultQuickSnapshot {
    width: 100%;
    float: left;
    min-height: 300px;
}

.defaultQuickSnapshot svg>g {
    transform: translate(0, 0) !important
}

.defaultQuickSnapshot svg>g>g {
    display: flex;
    align-items: center;
}

.defaultQuickSnapshot svg>g>g text {
    transform: translate(0, 0) !important;
}


.defaultQuickSnapshot>div:last-child,
.pieChartFloorCover {
    width: 100%;
    float: left;
    height: 300px !important;
    position: relative;
}

.defaultQuickLiveSpace {
    width: 100%;
    float: left;
    padding: 0 10px 0 0;
}

.liveSpaceDefaultCover {
    width: 100%;
    float: left;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    margin: 0 0 5px;
}

.liveSpaceTitle {
    width: 100%;
    float: left;
    font: 400 15px 'Rubik', sans-serif;
    padding: 10px 15px;
    color: #000;
}

.quicksnapshotItem {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 2px;
    border: solid 1px transparent;
}

.quicksnapshotItem.active {
    background: #fafafa;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
    border-color: #6d7c87;
    position: relative;
    z-index: 2;
}

.quicksnapshotItem:nth-child(even) {
    background: #fafafa;
}

.ui.selection.dropdown.dropdownZoneHistory {
    display: flex;
    align-items: center;
    padding: 0 0 0 10px !important;
    z-index: 1;
}

.ui.selection.dropdown.dropdownZoneHistory>.text {
    flex: 1;
}

.ui.selection.dropdown.dropdownZoneHistory>.icon {
    margin: 2px 10px 0 0 !important;
}

.hourlyvisitZoneGeofence {
    width: 100%;
    float: left;
    margin: 0 0 -20px;
}

.hourlyvisitZoneGeofence .OccupancyChartContainer {
    height: 460px;
}

.deviceInfoIconPaddingL {
    padding-left: 18
}

.boost-zone-peek-wrapper {
    padding: 2% 8% 0% 8% !important;
}

/*** netowrk connection ***/

.networkConnectionCover {
    width: 100%;
    float: left;
    border-bottom: solid 11px #f3f6fb;
    padding: 0 10px 20px;
}

.networkConnectionRow {
    background: #fff;
}

.networkConnectionRow>.columnBoost:nth-child(2) {
    border-left: solid 1px #D3D2DE;
}

.networkConnectionRow .alignerBoostCover {
    padding: 10px 0;
}

.networkConnectionRow .tableboxItemRow {
    border-right: none !important;
}

.networkConnectionRow .tableboxItemRowHead .tableboxItemColumn {
    /* border:none!important;
    padding:0;
    align-items:center;
    min-width:16%;
    max-width:16%;
    width:16%;
    flex:1;
    text-align:center; */
}

.networkConnectionRow .tableboxItemColumn {
    border: none !important;
    min-width: 16%;
    max-width: 16%;
    text-align: center;
    justify-content: center;
}

.networkTableRowbox {
    width: 100%;
    float: left;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    margin: 0 0 5px;
    min-width: fit-content;
}

.networkTableRowbox .tableboxItemRow:first-child .tableboxItemColumn {
    font-size: 15px;
    color: #000;
    min-height: 40px;
}

.networkTableRowbox .tableboxItemRow .tableboxItemColumn {
    font: 400 12px 'Rubik', sans-serif;
    color: #667581;
    padding: 5px 15px;
    text-align: center;
    min-height: 20px;
    /* min-width:16%;
    max-width:16%;
    width:16%;
    flex:1; */
    justify-content: center;
}

.networkTableRowbox .tableboxItemRow:nth-child(even) {
    background: #FAFAFA;
}

.networkTableRowbox.bs-active {
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    border-color: #667581;
}

.comfortTablebox .tableboxItemRow.bs-active .tableboxItemColumn {
    border-color: #667581;
}

.networkTableRowbox .tableboxItemRow .tableboxItemColumn:first-child,
.networkConnectionRow .tableboxItemRowHead .tableboxItemColumn:first-child {
    /* flex:1;
    min-width:20%;
    max-width:20%;
    width:16%;
    flex:1;
    text-align:left;
    justify-content:flex-start; */
}

.networkConnectionRow .tableboxItemColumn:first-child {
    min-width: 20%;
    text-align: left;
    justify-content: flex-start;
}

.networkTablebox {
    padding: 0 10px 0 0;
    position: relative;
}



.networkTablebox .tableboxItemRow {
    min-width: 800px;
}

.networkTablebox .tableboxItemRow.tableboxItemRowHead {
    border: solid 1px #fff !important;
}


.networkTableRowbox .tableboxItemRowHead {
    border-right: none !important;
}

.networkConnectionRow .tableboxItemRowHead .tableboxItemColumn {
    padding: 5px 6px !important;
}

.networkConnectionCover {
    width: 100%;
    float: left;
    max-height: 500px;
    overflow: auto;
}

.networkConnectionCover::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
}

.networkConnectionCover::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #BABBCB;
}

.alignerNetworkCover {
    width: 100%;
    float: left;
    padding: 0 0 10px 10px;
}

.pieChartFloorCover {
    overflow-y: inherit;
}

.chartVendorCover .pieChartFloorCover {
    height: calc(50vh - 5px) !important;

}

/*** boost comfort ***/

.boostComfortRow {
    background: #fff;
}

.boostComfortRow .columnBoost:nth-child(2) {
    border-left: solid 1px #D3D2DE;
}

.comfortTablebox {
    padding: 10px;
    max-width: 100%;
    overflow: auto;
    margin-top: 40px;
    padding-right: 30px;
}

.comfortTablebox .tableboxItemRowHead {
    border: solid 1px #fff !important;
    margin: 0;
}

.comfortTablebox .tableboxItemColumn {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
    padding: 5px 10px;
    text-align: center;
    justify-content: center;
}

.comfortTablebox .tableboxItemColumn:first-child,
.comfortTablebox .tableboxItemColumn:nth-child(2),
.comfortTablebox .tableboxItemColumn:nth-child(3) {
    width: 22% !important;
    min-width: 22% !important;
    max-width: 22% !important;
}

.comfortTablebox .tableboxItemColumn:first-child {
    border-left: solid 1px #D3D2DE;
    border-radius: 2px 0 0 2px;
    text-align: left;
    justify-content: flex-start;
}

.comfortTablebox .tableboxItemColumn:last-child {
    border-right: solid 1px #D3D2DE;
    border-radius: 0 2px 2px 0;
}

.comfortTablebox .tableboxItemRowHead .tableboxItemColumn {
    border: none !important;
    padding: 0 0 5px !important;
}

.comfortTablebox .tableboxItemRowHead .tableboxItemColumn span {
    max-width: 87px;
}

.comfortTablebox .tableboxItemRow {
    min-width: 700px;
}

.columnBoost.fullScreenWide .comfortTablebox .tableboxItemColumn {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
}

.columnBoost.fullScreenWide .comfortTablebox .tableboxItemColumn:last-child {
    max-width: 1000px !important;
    justify-content: flex-start;
}

.columnBoost.fullScreenWide .comfortTablebox .tableboxItemColumn:last-child img {
    margin-left: 26px;
}


.boost-comfort-icon {
    height: 25px !important;
}

.traffic-flow-table-title {
    overflow: hidden;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.chartPathDropdown {
    width: 170px !important;
}

.chartPathDropdown .ui.selection.dropdown .menu>.item {
    padding: 8px 10px !important;
    min-height: 0 !important;
    font-size: 13px;
}

.no-display {
    display: none;
}

.controlBtn {
    cursor: pointer;
}

.listOfInputDevices {
    margin-top: 10px;
    height: 30vh;
    width: 100%;
    overflow: scroll;
}

/* Add these styles in your CSS file or component */
.custom-file-input-label {
    cursor: pointer;
    color: #737BFF;
    border-radius: 5px;
    display: inline-block;
}

.custom-file-input {
    display: none;
    /* Hide the default file input */
}

.selected-file-info {
    margin-top: 10px;
    color: #333;
}

.selected-file-info-error {
    margin-top: 10px;
    color: red;
}



.ui.input.inputClientDeviceCtl>input {
    border: none !important;
    border-bottom: 1px solid #D3D2DE !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

.csvText {
    color: black;
}

.ui.modal.permissionModalTag {
    max-width: 500px;
    text-align: center;
}

.ui.modal.permissionModalTag .ui.header {
    width: 100%;
    float: left;
    margin: 0 0 30px;
    text-align: center;
    font: 500 20px 'Rubik', sans-serif;
}

.ui.modal.permissionModalTag>.content {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.boost-wrapper {
    display: flex;
    flex: auto;
}

.boost-wrapper .tableViewBody .mapContainer {
    height: 95%;
}

.boost-body {
    width: 100vw;
    height: 80vh;
    border: solid 1px #d3d2de;
}

.tableViewBody {
    width: 100vw;
    max-height: 90vh;
    overflow-y: hidden;
}
